import { Icon } from '@humanitec/ui-components';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import styled from 'styled-components';

import { useWalhallContext } from '@src/context/walhallContext';
import useGetOrgsQuery from '@src/hooks/react-query/organisations/queries/useGetOrgsQuery';
import useGetSpecificOrgQuery from '@src/hooks/react-query/organisations/queries/useGetSpecificOrgQuery';
import { useGetNavigationPermissions } from '@src/hooks/useGetNavigationPermissions';
import { units } from '@src/styles/variables';
import {
  getNavigationMenuMinimized,
  getSelectedOrganization,
  setNavigationMenuMinimized,
} from '@src/utilities/local-storage';

import NewHumanitecSVG from '../svg/NewHumanitecSVG';
import NavigationListItem from './components/NavigationListItem/NavigationListItem';
import OrgMenu from './components/OrgMenu/OrgMenu';

const Wrapper = styled.div<{ currentTheme?: 'light' | 'dark' }>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.navBgColor};
  background-image: ${({ currentTheme }) =>
    `url(${currentTheme === 'light' ? '/images/nav-bg-light.svg' : '/images/nav-bg-dark.svg'})`};
  background-blend-mode: color;
  background-position: right bottom;
  background-size: cover;
  padding: ${units.padding.xl} 0;
  height: 100vh;
  position: sticky;
  top: 0;
  z-index: 9;
`;

const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  font-size: ${units.fontSize.sm};
  overflow: auto;
`;

const NavigationList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const LogoWrapper = styled.div`
  display: flex;
  font-size: ${units.fontSize.base};
  padding: ${units.padding.sm} ${units.padding.xl} 0;
  margin-bottom: ${units.margin.xl};
`;

const OrgLinks = ({ orgId, minimized }: { orgId: string; minimized: boolean }) => {
  const orgsPrefix = useMemo(() => `/orgs/${orgId}`, [orgId]);
  const items = useGetNavigationPermissions(orgId);

  if (!orgsPrefix) {
    return <></>;
  }

  return (
    <NavigationList>
      {items.map((item) => (
        <NavigationListItem
          minimized={minimized}
          key={item.to}
          to={item.to}
          label={item.label}
          icon={item.icon}
          endOfGroup={item.endOfGroup}
        />
      ))}
    </NavigationList>
  );
};

const NavigationBar = () => {
  // i18n
  const { t } = useTranslation();
  const translations = t('NAVIGATION');

  const [minimized, setMinimized] = useState<boolean>(getNavigationMenuMinimized);

  const { theme } = useWalhallContext();

  // Router hooks
  const { orgId: routerOrgId } = useParams();

  // Selectors
  const { data: orgs = [] } = useGetOrgsQuery();

  const orgId = useMemo(() => {
    return routerOrgId || getSelectedOrganization() || orgs[0]?.id;
  }, [routerOrgId, orgs]);

  const { data: currentOrg } = useGetSpecificOrgQuery({ orgId });

  const customLogo = currentOrg?.logo;
  const renderMinimizedLogo = () =>
    customLogo ? <h5>{currentOrg?.name.slice(0, 2)}</h5> : <Icon name={'humanitec'} size={20} />; // when menu is minimized, render first two letters of org name if logo exists, else render default humanitec icon.

  const renderFullLogo = () =>
    customLogo ? (
      theme === 'dark' ? (
        customLogo.dark_url ? (
          <img src={customLogo.dark_url} height={20} alt={'logo'} />
        ) : (
          <NewHumanitecSVG />
        )
      ) : customLogo.light_url ? (
        <img src={customLogo.light_url} height={20} alt={'logo'} />
      ) : (
        <NewHumanitecSVG />
      )
    ) : (
      <NewHumanitecSVG />
    ); // render custom org logo if it exists and default humanitec logo if it doesn't.

  return (
    <Wrapper currentTheme={theme}>
      <LogoWrapper>{minimized ? renderMinimizedLogo() : renderFullLogo()}</LogoWrapper>

      <Nav aria-label={translations.PRIMARY_NAVIGATION}>
        {orgId && <OrgLinks orgId={orgId} minimized={minimized} />}
        <NavigationList>
          <NavigationListItem
            minimized={minimized}
            to={'https://developer.humanitec.com/'}
            target={'_blank'}
            label={translations.DOCUMENTATION}
            icon={'docs'}
          />
          <NavigationListItem
            minimized={minimized}
            to={'https://humanitec.com/support'}
            target={'_blank'}
            label={translations.SUPPORT}
            icon={'comments'}
          />
          {orgId && (
            <OrgMenu
              minimized={minimized}
              orgs={orgs}
              orgId={orgId}
              placement={'right'}
              disableMenu={orgs.length <= 1}
            />
          )}
          <NavigationListItem
            minimized={minimized}
            to={'/profile-settings'}
            label={translations.ACCOUNT}
            icon={'account'}
            endOfGroup
          />
          <NavigationListItem
            minimized={minimized}
            label={translations.HIDE_MENU}
            labelMinimized={translations.SHOW_MENU}
            icon={'hide-menu'}
            onClick={() => {
              setMinimized((prevState) => {
                const newValue = !prevState;
                setNavigationMenuMinimized(newValue);
                return newValue;
              });
            }}
          />
        </NavigationList>
      </Nav>
    </Wrapper>
  );
};

export default NavigationBar;
