import { TabsSwitcher } from '@humanitec/ui-components';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import styled, { css } from 'styled-components';

import SelectImageModal from '@src/components/shared/AddContainerImageModal/AddContainerImageModal';
import SectionHeader from '@src/components/shared/SectionHeader';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { WorkloadContainer } from '@src/models/deployment-set';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import { generateContainerURL } from '@src/utilities/navigation';

import ContainerTab from './ContainerTab';

const ContainerTabsWrapper = styled.div<{ $noMarginBottom?: boolean }>`
  display: inline-flex;
  align-items: center;
  ${({ $noMarginBottom }) =>
    !$noMarginBottom &&
    css`
      margin-bottom: ${units.padding.xl};
    `}
`;

const Wrapper = styled.div<{
  openAddContainerModal: boolean;
  $transparentBackground?: boolean;
  $readonly?: boolean;
}>`
  top: 0;
  z-index: 10;
  ${($transparentBackground) =>
    !$transparentBackground &&
    css`
      background-color: ${({ theme }) => theme.color.baseBrighter};
    `};
  ${({ openAddContainerModal }) =>
    !openAddContainerModal &&
    css`
      position: sticky;
    `}
  ${({ $readonly, $transparentBackground }) =>
    $readonly &&
    !$transparentBackground &&
    css`
      background-color: ${({ theme }) => theme.color.base};
    `}
`;

interface ContainerSwitherProps {
  readonly?: boolean;
  currentContainerIdState?: [string | undefined, Dispatch<SetStateAction<string | undefined>>];
  hideTitle?: boolean;
  transparentBackground?: boolean;
  noMarginBottom?: boolean;
}

const ContainerSwitcher = ({
  readonly,
  currentContainerIdState,
  hideTitle,
  transparentBackground,
  noMarginBottom,
}: ContainerSwitherProps) => {
  // i18n
  const { t } = useTranslation();
  const sectionsTranslations = t('VIEW_MODULE').SECTIONS;

  // Component state
  const [openAddContainerModal, setOpenAddContainerModal] = useState<boolean>(false);
  const [containerToNavigateTo, setContainerToNavigateTo] = useState<string>();

  // Context
  const state = useState<string>();
  const [currentContainerId, setCurrentContainerId] = currentContainerIdState || state;

  // Router hooks
  const params = useParams<keyof MatchParams>() as MatchParams;
  const { moduleId } = params;
  const navigate = useNavigate();

  const { data, deployedData } =
    useDeltaUtils<Record<string, WorkloadContainer>>('spec/containers');

  // Merge all containers, as we want to displayed the removed ones also.
  const allContainers = useMemo(() => ({ ...deployedData, ...data }), [data, deployedData]);

  const containerIds = Object.keys(allContainers);

  useEffect(() => {
    if (containerToNavigateTo && containerIds.includes(containerToNavigateTo)) {
      navigate(generateContainerURL(params, moduleId, containerToNavigateTo));
      setContainerToNavigateTo(undefined);
    }
  }, [containerToNavigateTo, moduleId, containerIds, navigate, params]);

  const navigateToContainer = useCallback((id: string) => {
    setContainerToNavigateTo(id);
  }, []);

  return (
    <Wrapper
      $readonly={readonly}
      $transparentBackground={transparentBackground}
      openAddContainerModal={openAddContainerModal}>
      {containerIds.length > 0 && !hideTitle && (
        <SectionHeader backgroundColor={'transparent'} sticky={false}>
          {sectionsTranslations.CONTAINERS}
        </SectionHeader>
      )}
      <ContainerTabsWrapper $noMarginBottom={noMarginBottom}>
        <TabsSwitcher
          setOpenCreateModal={setOpenAddContainerModal}
          tabs={Object.keys(allContainers || {}).map((containerId) => ({
            id: containerId,
            component: (
              <ContainerTab
                key={containerId}
                container={{
                  id: containerId,
                  removed: !Boolean(data?.[containerId]),
                }}
                containers={Object.keys(allContainers || {}).map((cId) => ({
                  id: cId,
                  removed: !Boolean(data?.[cId]),
                }))}
                currentContainerId={currentContainerId}
                readonly={readonly}
                onClick={() => setCurrentContainerId(containerId)}
                onKeyDown={() => setCurrentContainerId(containerId)}
              />
            ),
          }))}
          onDropdownItemClick={(_, tabId) => setCurrentContainerId(tabId)}
          maxTabs={4}
          createNewTabText={sectionsTranslations.CREATE_NEW_CONTAINER}
          dropdownTitle={sectionsTranslations.MORE_CONTAINERS}
          defaultTabId={currentContainerId}
          readonly={readonly}
        />
      </ContainerTabsWrapper>
      {openAddContainerModal && (
        <SelectImageModal
          openState={[openAddContainerModal, setOpenAddContainerModal]}
          containerCreated={navigateToContainer}
          deltaPath={'spec/containers'}
          currentContainerIds={containerIds}
        />
      )}
    </Wrapper>
  );
};

export default ContainerSwitcher;
