import { Button } from '@humanitec/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import styled from 'styled-components';

import DeploymentOverviewModal from '@src/components/shared/DeploymentOverviewModal/DeploymentOverviewModal';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import useDeploymentDeltaQuery from '@src/hooks/react-query/deployment-delta/queries/useDeploymentDeltaQuery';
import useEnvironmentPauseStatusQuery from '@src/hooks/react-query/environments/queries/useEnvironmentPauseStatusQuery';
import useEnvironmentQuery from '@src/hooks/react-query/environments/queries/useEnvironmentQuery';
import { useRBAC } from '@src/hooks/useRBAC';
import { MatchParams } from '@src/models/routing';

const FlexBox = styled.div`
  display: flex;
`;

const DeployButtonElement = styled(Button)`
  &:disabled {
    background-color: ${({ theme }) => theme.color.baseDeployButton};
    color: ${({ theme }) => theme.color.textTranslucent};
    .inner-button-text {
      opacity: 1;
    }
  }
`;

const DeployButton = () => {
  // Router hooks
  const { envId } = useParams<keyof MatchParams>() as MatchParams;
  // i18n
  const { t } = useTranslation();
  const { t: tDeployment } = useTranslation('viewDeployment');
  const viewAppTranslations = t('VIEW_APPLICATION');

  const canDeployEnv = useRBAC('deployEnvironment');
  const { draftModeActive } = useDeploymentOrDeltaContext();

  // React Query
  const { data: activeDelta } = useDeploymentDeltaQuery();
  const { data: environment } = useEnvironmentQuery();
  const { data: envPauseStatusMap } = useEnvironmentPauseStatusQuery();

  const envPauseStatus = envPauseStatusMap?.[envId];

  const [deploymentOverviewModalOpen, setDeploymentOverviewModalOpen] = useState(false);

  return (
    <>
      <DeployButtonElement
        id={'deploy-button'}
        disabled={
          environment?.last_deploy?.status === 'in progress' ||
          !canDeployEnv ||
          envPauseStatus !== false
        }
        size={'medium'}
        infoPopup={{
          text:
            environment?.last_deploy?.status === 'in progress'
              ? viewAppTranslations.CURRENTLY_DEPLOYING_INFO
              : (!canDeployEnv && viewAppTranslations.NO_DEPLOY_PERMISSION) || '',
          position: 'right',
        }}
        onClick={() => setDeploymentOverviewModalOpen(true)}>
        {environment?.last_deploy?.status === 'in progress' ? (
          <FlexBox>{viewAppTranslations.CURRENTLY_DEPLOYING}</FlexBox>
        ) : (
          <FlexBox>
            {!draftModeActive && `${viewAppTranslations.REDEPLOY}`}
            {draftModeActive && tDeployment('REVIEW_AND_DEPLOY')}
          </FlexBox>
        )}
      </DeployButtonElement>
      {activeDelta && deploymentOverviewModalOpen && environment && (
        <DeploymentOverviewModal
          environment={environment}
          openState={[deploymentOverviewModalOpen, setDeploymentOverviewModalOpen]}
          deploymentType={{ type: 'deploy', data: activeDelta }}
        />
      )}
    </>
  );
};

export default DeployButton;
