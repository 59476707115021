import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router';

import { ApiErrorResponse } from '@src/models/ApiErrorResponse';
import { ResourceDependencyGraphNode } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import runtimeQueryKeys from './runtimeActionsQueryKeys';

export interface TestConnectivity {
  success: boolean;
  resource_summaries: ResourceDependencyGraphNode[];
  conditions: {
    type: string;
    status: TestConnectivityConditionStatus;
    message: string;
  }[];
}

export type TestConnectivityConditionStatus = 'True' | 'False' | 'Unknown';

const useCheckConnectivityMutation = () => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  return useMutation<
    AxiosResponse<TestConnectivity>,
    AxiosError<ApiErrorResponse>,
    { app_id: string; env_id: string; env_type: string }
  >({
    mutationKey: runtimeQueryKeys.checkConnectivity(orgId),
    mutationFn: (variables) =>
      makeRequest<TestConnectivity>(
        'POST',
        `/orgs/${orgId}/runtime/actions/check-connectivity`,
        variables
      ),
  });
};

export default useCheckConnectivityMutation;
