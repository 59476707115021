import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { OrgRoles, Role } from '@src/models/role';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { groupsQueryKeys } from '../groupsQueryKeys';

export const useUsersInGroupQuery = (userIdParam?: string) => {
  const { orgId, userId: userIdRouteParam } = useParams<keyof MatchParams>() as MatchParams;

  const userId = userIdRouteParam || userIdParam;

  return useQuery({
    queryKey: groupsQueryKeys.detail(orgId, userId),
    queryFn: () => makeRequest<Role<OrgRoles>[]>('GET', `/orgs/${orgId}/groups/${userId}/users`),
    select: (data) => data.data,
    enabled: Boolean(orgId && userId),
  });
};
