import { datadogRum } from '@datadog/browser-rum';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate, useParams } from 'react-router';

import { pipelineRunsQueryKeys } from '@src/hooks/react-query/pipeline-runs/queries/pipelineRunsQueryKeys';
import { ApiErrorResponse } from '@src/models/ApiErrorResponse';
import { Pipeline } from '@src/models/pipeline';
import makeRequest from '@src/utilities/make-request';
import { generatePipelinesURL } from '@src/utilities/navigation';

const usePipelineRunRestartMutation = (
  pipelineId: string,
  pipelineRunId?: string,
  customParams?: { appId?: string; onSuccess?: (res: AxiosResponse) => void }
) => {
  const { orgId, appId: routerAppId } = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const appId = customParams?.appId || routerAppId || '';

  const { data, ...results } = useMutation<AxiosResponse, AxiosError<ApiErrorResponse>>({
    mutationFn: () => {
      return makeRequest<Pipeline>(
        'POST',
        `/orgs/${orgId}/apps/${appId}/pipelines/${pipelineId}/runs/${pipelineRunId}/restart`
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: pipelineRunsQueryKeys.pipelineRuns(orgId, appId, pipelineId),
      });
      navigate(generatePipelinesURL(orgId, appId, pipelineId));
    },
    onError: (error) => {
      datadogRum.addError(error);
    },
  });

  return { ...results, data: data?.data };
};

export default usePipelineRunRestartMutation;
