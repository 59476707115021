import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { ResourceAccount } from '@src/models/resource-account';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { resourceAccountsQueryKeys } from '../resourceAccountsQueryKeys';

const useResourceAccountsQuery = () => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery({
    queryKey: resourceAccountsQueryKeys.listResourceAccounts(orgId),
    queryFn: () => makeRequest<ResourceAccount[]>('GET', `/orgs/${orgId}/resources/accounts`),
    enabled: Boolean(orgId),
  });

  return { ...queryResult, data: data?.data };
};

export default useResourceAccountsQuery;
