import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router';

import { ApiErrorResponse } from '@src/models/ApiErrorResponse';
import { ResourceClass } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { resourcesQueryKeys } from '../resourcesQueryKeys';

const useResourceClassDeleteMutation = () => {
  const queryClient = useQueryClient();
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useMutation<
    AxiosResponse<ResourceClass>,
    AxiosError<ApiErrorResponse>,
    { resourceClass: ResourceClass }
  >({
    mutationFn: ({ resourceClass }) =>
      makeRequest(
        'DELETE',
        `/orgs/${orgId}/resources/types/${resourceClass.resource_type}/classes/${resourceClass.id}`
      ),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: resourcesQueryKeys.listClasses(orgId) });
    },
  });

  return { ...queryResult, data: data?.data };
};

export default useResourceClassDeleteMutation;
