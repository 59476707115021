import { Button, ExpandableCard, Icon, Spinner, WalCard } from '@humanitec/ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import styled from 'styled-components';

import DeploymentOverviewModal from '@src/components/shared/DeploymentOverviewModal/DeploymentOverviewModal';
import Status from '@src/components/shared/Status';
import { Environment } from '@src/models/environment';
import { PipelineRun } from '@src/models/pipeline';
import { units } from '@src/styles/variables';
import { getDeploymentStatus } from '@src/utilities/deployment-status';
import {
  generateAppURL,
  generateDeploymentURL,
  generatePipelinesURL,
} from '@src/utilities/navigation';

const InnerCardGrid = styled.div`
  display: grid;
  grid-template-columns: 40% 1fr 130px 160px;
  column-gap: ${units.margin.md};
  justify-content: space-between;
  align-items: center;
`;

const CustomLink = styled(Link)`
  font-size: ${units.fontSize.base};
  text-decoration: none;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

interface OutdatedEnvValuesWarningProps {
  environments: Environment[];
  orgId: string;
  appId: string;
}
const OutdatedEnvValuesWarning = ({
  environments,
  orgId,
  appId,
}: OutdatedEnvValuesWarningProps) => {
  // i18n
  const { t } = useTranslation();
  const settingsTranslations = t('APP_SETTINGS');
  const [deploymentOverviewModalOpen, setDeploymentOverviewModalOpen] = useState(false);
  const [environmentToDeploy, setEnvironmentToDeploy] = useState<Environment>();
  const [environmentPipelineRun, setEnvironmentPipelineRun] =
    useState<Record<string, PipelineRun>>();

  const onDeployEnvClick = (environment: Environment) => {
    setEnvironmentToDeploy(environment);
    setDeploymentOverviewModalOpen(true);
  };

  const onDeployment = (pipelineRun: PipelineRun) => {
    setEnvironmentPipelineRun((prevState) => {
      if (environmentToDeploy) {
        const newState: Record<string, PipelineRun> = { ...prevState };
        newState[environmentToDeploy.id] = pipelineRun;
        return newState;
      }
    });
  };

  return (
    <>
      <ExpandableCard
        id={'outdated-envs'}
        cardStyle={'transparent'}
        className={'mb-md'}
        headerContent={
          <div className={'flex-row flex-centered'}>
            <Icon name={'warning'} overrideColor={'yellow'} className={'mr-md'} />
            {settingsTranslations.VALUES_NOT_DEPLOYED}
          </div>
        }
        content={
          <>
            <p className={'mb-md txt-translucent txt-sm'}>
              {settingsTranslations.VALUES_NOT_DEPLOYED_TITLE}
            </p>
            <p className={'txt-translucent txt-sm'}>
              {settingsTranslations.VALUES_NOT_DEPLOYED_SUBTITLE}
            </p>
            <div className={'my-md'}>
              {environments.map((environment) => (
                <WalCard key={environment.id} className={'mb-md'}>
                  <InnerCardGrid>
                    <CustomLink to={generateAppURL(orgId, appId, environment.id)}>
                      {environment.id}
                    </CustomLink>
                    <span className={'txt-sm'}>
                      <span className={'txt-translucent'}>
                        {settingsTranslations.ENVIRONMENT_TYPE}{' '}
                      </span>{' '}
                      {environment.type}
                    </span>
                    {Boolean(environmentPipelineRun?.[environment.id]) ? (
                      <div className={'flex-row flex-centered'}>
                        <Spinner size={'small'} className={'mr-md'} />{' '}
                        <span className={'txt-sm txt-translucent'}>
                          {settingsTranslations.DEPLOY_TRIGGERED}
                        </span>
                      </div>
                    ) : environment.last_deploy && environment.last_deploy?.status ? (
                      <Status
                        color={environment.last_deploy?.status === 'failed' ? 'red' : 'green'}
                        link={generateDeploymentURL(
                          orgId,
                          appId,
                          environment.id,
                          environment.last_deploy?.id,
                          'workloads'
                        )}
                        text={getDeploymentStatus(environment.last_deploy?.status)}
                      />
                    ) : (
                      <span className={'txt-sm'}>{settingsTranslations.NO_DEPLOY}</span>
                    )}
                    <ButtonWrapper>
                      {Boolean(environmentPipelineRun?.[environment.id]) ? (
                        <Link
                          to={generatePipelinesURL(
                            orgId,
                            appId,
                            environmentPipelineRun?.[environment.id]?.pipeline_id,
                            environmentPipelineRun?.[environment.id]?.id
                          )}>
                          {settingsTranslations.VIEW_PIPELINE_RUN}
                        </Link>
                      ) : (
                        environment?.last_deploy && (
                          <Button
                            size={'small'}
                            variant={'secondary'}
                            onClick={() => onDeployEnvClick(environment)}>
                            {settingsTranslations.DEPLOY_UPDATED_VALUES}
                          </Button>
                        )
                      )}
                    </ButtonWrapper>
                  </InnerCardGrid>
                </WalCard>
              ))}
            </div>
          </>
        }
      />
      {deploymentOverviewModalOpen && environmentToDeploy && environmentToDeploy.last_deploy && (
        <DeploymentOverviewModal
          openState={[deploymentOverviewModalOpen, setDeploymentOverviewModalOpen]}
          environment={environmentToDeploy}
          deploymentType={{
            type: 'redeploy',
            data: environmentToDeploy.last_deploy,
            deployLatestValueSet: true,
          }}
          customOnDeploymentSuccess={onDeployment}
        />
      )}
    </>
  );
};

export default OutdatedEnvValuesWarning;
