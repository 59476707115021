import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { ResourceDefinitionVersion } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { resourcesQueryKeys } from '../resourcesQueryKeys';

export const useResourceDefinitionVersionQuery = (customParams?: {
  defId?: string;
  defVersionId: string | undefined;
}) => {
  const {
    orgId,
    defId: routerDefId,
    defVersionId: routerDefVersionId,
  } = useParams<keyof MatchParams>() as MatchParams;

  const defId = customParams?.defId || routerDefId;
  const defVersionId = customParams?.defVersionId || routerDefVersionId;

  return useQuery({
    queryKey: resourcesQueryKeys.definitionVersion(orgId, defId, defVersionId),
    queryFn: () =>
      makeRequest<ResourceDefinitionVersion>(
        'GET',
        `/orgs/${orgId}/resources/defs/${defId}/versions/${defVersionId}`
      ),
    enabled: Boolean(orgId && defId && defVersionId),
    select: (data) => data?.data,
  });
};
