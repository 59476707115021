import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import type { QueryResponse } from '@src/hooks/react-query/types';
import { MatchParams } from '@src/models/routing';
import { SecretStore } from '@src/models/secret-store';
import makeRequest from '@src/utilities/make-request';

import { secretStoresQueryKeys } from '../secretStoresQueryKeys';

const useSecretStoresQuery = (): QueryResponse<SecretStore[]> => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery({
    queryKey: secretStoresQueryKeys.listSecretStores(orgId),
    queryFn: () => makeRequest<SecretStore[]>('GET', `/orgs/${orgId}/secretstores`),
    enabled: Boolean(orgId),
  });

  return { ...queryResult, data: data?.data };
};

export default useSecretStoresQuery;
