import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import type { QueryResponse } from '@src/hooks/react-query/types';
import { ActiveResource } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { resourcesQueryKeys } from '../resourcesQueryKeys';

export const activeResourcesQueryOptions = (orgId: string, defId?: string) => ({
  queryKey: resourcesQueryKeys.activeResources(orgId, defId),
  queryFn: () =>
    makeRequest<ActiveResource[]>('GET', `/orgs/${orgId}/resources/defs/${defId}/resources`),
  enabled: Boolean(orgId && defId),
});

const useActiveResourcesQuery = (customParams?: {
  defId?: string;
}): QueryResponse<ActiveResource[]> => {
  const { orgId, defId: routerDefId } = useParams<keyof MatchParams>() as MatchParams;

  const defId = customParams?.defId || routerDefId;

  const { data, ...queryResult } = useQuery(activeResourcesQueryOptions(orgId, defId));

  return { ...queryResult, data: data?.data };
};

export default useActiveResourcesQuery;
