import { TabGroup } from '@humanitec/ui-components';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useParams } from 'react-router';
import styled from 'styled-components';

import ErrorPage from '@src/components/shared/ErrorPage/ErrorPage';
import PageHeader from '@src/components/shared/PageHeader/PageHeader';
import { NotificationsSocketContext } from '@src/components/socket/contexts';
import { WebSocketEventTypes } from '@src/components/socket/event-types';
import { useHandleSocketMessage } from '@src/components/socket/useHandleSocketMessage';
import { applicationsQueryKeys } from '@src/hooks/react-query/applications/applicationsQueryKeys';
import useApplicationQuery from '@src/hooks/react-query/applications/queries/useApplicationQuery';
import { environmentQueryKeys } from '@src/hooks/react-query/environments/environmentQueryKeys';
import { useRBAC } from '@src/hooks/useRBAC';
import { MatchParams } from '@src/models/routing';
import { PageContainer } from '@src/styles/layout/PageContainer';
import { setLastVisitedApp } from '@src/utilities/local-storage';
import { generateAppURL } from '@src/utilities/navigation';

const Container = styled(PageContainer)`
  flex-direction: column;
  flex: 1;
`;

const App = () => {
  // i18n
  const { t } = useTranslation('viewApplication');
  const tabsTranslations = t('TABS');

  const canDeleteApplication = useRBAC('deleteApplication');

  // React Query
  const queryClient = useQueryClient();

  const { isError: applicationLoadingError } = useApplicationQuery();

  // Router hooks
  const { orgId, appId } = useParams<keyof MatchParams>() as MatchParams;
  const navigate = useNavigate();

  useHandleSocketMessage(appId, NotificationsSocketContext, (message) => {
    // someone deleted the application you are viewing
    if (message.type === WebSocketEventTypes.APP_DELETED) {
      navigate(generateAppURL(orgId));
    }
    if (
      message.type === WebSocketEventTypes.ENV_CREATED ||
      message.type === WebSocketEventTypes.ENV_UPDATED ||
      message.type === WebSocketEventTypes.WORKLOAD_REMOVED
    ) {
      queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.detail(orgId, appId),
      });
      queryClient.invalidateQueries({
        queryKey: environmentQueryKeys.applicationEnvironments(orgId, message.data.application_id),
      });
    }
    if (message.type === WebSocketEventTypes.ENV_PAUSE_STATUS_UPDATED) {
      // load info about whether environments on the App are paused
      queryClient.invalidateQueries({
        queryKey: environmentQueryKeys.environmentPauseStatus(orgId, appId),
      });
    }
  });

  useEffect(() => {
    setLastVisitedApp(appId);
  }, [appId]);

  useEffect(() => {
    // load info about whether environments on the App are paused
    queryClient.invalidateQueries({
      queryKey: environmentQueryKeys.environmentPauseStatus(orgId, appId),
    });
  }, [orgId, appId, queryClient]);

  return (
    <Container>
      {applicationLoadingError ? (
        <ErrorPage title={t('APP_NOT_FOUND')} />
      ) : (
        <>
          <PageHeader showPageContext />
          <TabGroup
            options={[
              { label: tabsTranslations.ENVIRONMENTS, value: 'envs', link: { to: 'envs' } },
              {
                label: tabsTranslations.PIPELINES,
                value: 'pipelines',
                link: { to: 'pipelines' },
              },
              {
                label: tabsTranslations.VALUES_AND_SECRETS,
                value: 'values-and-secrets',
                link: { to: 'values-and-secrets' },
              },
              {
                label: tabsTranslations.WEBHOOKS,
                value: 'webhooks',
                link: { to: 'webhooks' },
              },
              {
                label: tabsTranslations.PEOPLE,
                value: 'people',
                link: { to: 'people' },
              },
              {
                label: tabsTranslations.DELETE,
                value: 'delete',
                hide: !canDeleteApplication,
                link: { to: 'delete' },
              },
            ]}
          />
          <Outlet />
        </>
      )}
    </Container>
  );
};

export default App;
