import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import type { QueryResponse } from '@src/hooks/react-query/types';
import { NewApiToken } from '@src/models/api-token';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { apiTokensQueryKeys } from '../apiTokensQueryKeys';

/* Query for api tokens related to a particular service user  */
const useApiTokensByUserIdQuery = (userId?: string): QueryResponse<NewApiToken[]> => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery({
    queryKey: apiTokensQueryKeys.listApiTokensByUserId(userId),
    queryFn: () => makeRequest<NewApiToken[]>('GET', `/users/${userId}/tokens`),
    enabled: Boolean(orgId && userId),
  });

  return { ...queryResult, data: data?.data };
};

export default useApiTokensByUserIdQuery;
