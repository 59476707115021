import { useQueries, UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useParams } from 'react-router';

import { EnvironmentType } from '@src/models/environment-type';
import { EnvTypeRoles, Role } from '@src/models/role';
import { MatchParams } from '@src/models/routing';

import { envTypeRolesQueryOptions } from './useEnvTypeRolesQuery';

const transformQueryResponse = (
  queries: UseQueryResult<AxiosResponse<Role<EnvTypeRoles>[], any>, unknown>[],
  environmentTypes: EnvironmentType[]
) => {
  return queries.reduce((acc: Record<string, Role<EnvTypeRoles>[]>, query, i) => {
    const data = query?.data?.data;
    const envId = environmentTypes[i]?.id;

    if (data && envId) acc[envId] = data;
    return acc;
  }, {});
};

/*
 * Use this hook in scenarios where you need to fetch a variable number of environment-type roles based on the length of environmentTypes prop.
 * See https://tanstack.com/query/v4/docs/react/reference/useQueries
 */
const useEnvTypeRolesQueries = (environmentTypes: EnvironmentType[]) => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const userQueries = useQueries({
    queries: environmentTypes.map((environmentType) =>
      envTypeRolesQueryOptions(orgId, environmentType.id)
    ),
  });

  return transformQueryResponse(userQueries, environmentTypes);
};

export default useEnvTypeRolesQueries;
