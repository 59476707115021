import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { DeploymentObject } from '@src/models/deployment-object';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { QueryResponse } from '../../types';
import { environmentQueryKeys } from '../environmentQueryKeys';

const useDeploymentsListQuery = (
  customParams?: {
    orgId?: string;
    appId?: string;
    envId?: string;
  },
  pipelineRunFilter?: {
    pipelineId: string;
    pipelineRunId: string;
  }
): QueryResponse<DeploymentObject[]> => {
  const {
    orgId: routerOrgId,
    appId: routerAppId,
    envId: routerEnvId,
  } = useParams<keyof MatchParams>() as MatchParams;

  const orgId = customParams?.orgId || routerOrgId;
  const appId = customParams?.appId || routerAppId;
  const envId = customParams?.envId || routerEnvId;

  const { data, ...queryResult } = useQuery({
    queryKey: environmentQueryKeys.listEnvironmentDeployments(
      orgId,
      appId,
      envId,
      pipelineRunFilter
    ),
    queryFn: () =>
      makeRequest<DeploymentObject[]>(
        'GET',
        `/orgs/${orgId}/apps/${appId}/envs/${envId}/deploys${pipelineRunFilter ? `?pipelineRunId=${pipelineRunFilter.pipelineId},${pipelineRunFilter.pipelineRunId}` : ''}`
      ),
    enabled: Boolean(orgId && appId && envId),
  });
  return { ...queryResult, data: data?.data };
};

export default useDeploymentsListQuery;
