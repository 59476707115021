import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router';

import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { artefactQueryKeys } from '../artefactsQueryKeys';

interface ArtefactDeleteMutationVariables {
  artefactId: string;
}
const useArtefactDeleteMutation = () => {
  const queryClient = useQueryClient();
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useMutation<
    AxiosResponse,
    AxiosError,
    ArtefactDeleteMutationVariables
  >({
    mutationFn: ({ artefactId }) => makeRequest('delete', `/orgs/${orgId}/artefacts/${artefactId}`),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: artefactQueryKeys.listArtefacts(orgId),
      });
    },
  });
  return { ...queryResult, data: data?.data };
};

export default useArtefactDeleteMutation;
