import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router';

import { ApiErrorResponse } from '@src/models/ApiErrorResponse';
import { ResourceCriteria } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { resourcesQueryKeys } from '../resourcesQueryKeys';

interface MatchingCriteriaMutationVariables {
  defId: string;
  criteria: Partial<ResourceCriteria>;
}

const useMatchingCriteriaCreateMutation = (errorCallback?: (error: AxiosError) => void) => {
  const queryClient = useQueryClient();
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useMutation<
    AxiosResponse<ResourceCriteria>,
    AxiosError<ApiErrorResponse>,
    MatchingCriteriaMutationVariables
  >({
    mutationFn: ({ defId, criteria }) =>
      makeRequest('POST', `/orgs/${orgId}/resources/defs/${defId}/criteria`, criteria),
    onSuccess: (_, variables) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: resourcesQueryKeys.listDefinitions(orgId) });
      queryClient.invalidateQueries({
        queryKey: resourcesQueryKeys.definitionDetail(orgId, variables.defId),
      });
    },
    onError: errorCallback,
  });
  return { ...queryResult, data: data?.data };
};

export default useMatchingCriteriaCreateMutation;
