import { Spinner, TabGroup, TabGroupOptionProps } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';
import styled from 'styled-components';

import PageHeader from '@src/components/shared/PageHeader/PageHeader';
import useResourceDefinitionByIdQuery from '@src/hooks/react-query/resources/queries/useResourceDefinitionByIdQuery';
import { useDecision } from '@src/hooks/useDecision';
import { useRBAC } from '@src/hooks/useRBAC';
import { PageContainer } from '@src/styles/layout/PageContainer';
import { units } from '@src/styles/variables';

import { ResourceDefinitionBasicInfo } from './components/ResourceDefinitionBasicInfo';

const Container = styled(PageContainer)`
  flex-direction: column;
  overflow: auto;
  margin-bottom: ${units.margin.lg};
`;

const SpinnerWrapper = styled.div`
  height: 100%;
`;

type ResourceTabs =
  | 'usage'
  | 'matching-criteria'
  | 'configuration'
  | 'test'
  | 'versions'
  | 'delete';

const ResourceDefinition = () => {
  // i18n
  const { t } = useTranslation();
  const resourcesTranslations = t('ACCOUNT_SETTINGS').RESOURCES;

  // Optimizely
  const [clusterTestDecision] = useDecision('cluster-test-res-def');

  // React Query
  const { data: resourceDefinition, isPending: isResourceDefinitionLoading } =
    useResourceDefinitionByIdQuery();

  // RBAC
  const canDeleteResourceDefinition = useRBAC('manageResources');

  const tabOptions: TabGroupOptionProps<ResourceTabs>[] = [
    {
      label: resourcesTranslations.USAGE,
      value: 'usage',
      link: {
        to: `usage`,
      },
    },
    {
      label: resourcesTranslations.MATCHING_CRITERIA,
      value: 'matching-criteria',
      link: {
        to: `matching-criteria`,
      },
    },
    {
      label: resourcesTranslations.CONFIGURATION,
      value: 'configuration',
      link: {
        to: `configuration`,
      },
    },
    {
      label: resourcesTranslations.TEST,
      value: 'test',
      hide: resourceDefinition?.type !== 'k8s-cluster' || !clusterTestDecision.enabled,
      link: {
        to: `test`,
      },
    },
    {
      label: resourcesTranslations.VERSIONS,
      value: 'versions',
      link: {
        to: `versions`,
      },
    },
    {
      label: resourcesTranslations.DELETE,
      disabled: resourceDefinition?.is_default,
      hide: !canDeleteResourceDefinition,
      disabledInfo: resourcesTranslations.DELETE_DISABLED,
      value: 'delete',
      link: {
        to: `delete`,
      },
    },
  ];

  if (isResourceDefinitionLoading) {
    return (
      <SpinnerWrapper className={'flex justify-center align-center'}>
        <Spinner size={'large'} />
      </SpinnerWrapper>
    );
  }

  return (
    <Container>
      <PageHeader />
      <ResourceDefinitionBasicInfo />
      <TabGroup options={tabOptions} size={'large'} />
      <Outlet />
    </Container>
  );
};

export default ResourceDefinition;
