import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import appValuesQueryKeys from '../queries/appValuesQueryKeys';
import { sharedValuesQueryKeys } from '../sharedValuesQueryKeys';

const useAppValuesDeleteMutation = () => {
  // Router hooks
  const { orgId, appId } = useParams<keyof MatchParams>() as MatchParams;

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ appValueKey }: { appValueKey: string }) =>
      makeRequest('DELETE', `/orgs/${orgId}/apps/${appId}/values/${appValueKey}`),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: appValuesQueryKeys.list(orgId, appId),
      });
      queryClient.invalidateQueries({
        queryKey: sharedValuesQueryKeys.appValuesList(orgId, appId),
      });
    },
  });
};

export default useAppValuesDeleteMutation;
