import { WalLabel } from '@humanitec/ui-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router';
import styled from 'styled-components';

import DeploymentControls from '@src/containers/Orgs/Apps/containers/App/containers/ViewEnvironment/components/Deploys/components/DeploymentControls';
import { isStatusMessage } from '@src/containers/Orgs/PipelineRuns/utils';
import useDeploymentsListQuery from '@src/hooks/react-query/environments/queries/useDeploymentsListQuery';
import useEnvironmentQuery from '@src/hooks/react-query/environments/queries/useEnvironmentQuery';
import usePipelineRunJobStepDetailsQuery from '@src/hooks/react-query/pipeline-runs/queries/usePipelineRunJobStepDetailsQuery';
import usePipelineRunsApprovalRequestsQuery from '@src/hooks/react-query/pipeline-runs/queries/usePipelineRunsApprovalRequestsQuery';
import useGetUserByIdQuery from '@src/hooks/react-query/user/queries/useGetUserByIdQuery';
import { DeploymentObject } from '@src/models/deployment-object';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';
import { generateDeploymentURL } from '@src/utilities/navigation';

interface StepDetailsProps {
  jobId: string;
  stepIndex: number;
  deployment?: DeploymentObject;
}

const Log = styled.div`
  white-space: pre-wrap;
  font-family: monospace;
  align-items: start;

  color: ${({ theme }) => theme.color.text};

  &.ERROR {
    color: ${({ theme }) => theme.color.alertBrighter};
  }
  &.WARN {
    color: ${({ theme }) => theme.color.yellow};
  }
  &.DEBUG {
    color: ${({ theme }) => theme.color.textTranslucent};
  }

  & > span:last-child {
    flex: 1;
  }
`;

const LogsWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.base};
  padding: ${units.padding.xl};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.color.baseOutline};
`;

const TimeStamp = styled.span`
  font-weight: bold;
  font-size: ${units.fontSize.sm};
`;

export const StepDetails = ({ jobId, stepIndex, deployment }: StepDetailsProps) => {
  const { orgId, appId, pipelineRunId } = useParams<keyof MatchParams>() as MatchParams;
  // i18n
  const { t: tViewEnvironment } = useTranslation('viewEnvironment');
  const { t } = useTranslation();
  const deploysTranslations = tViewEnvironment('DEPLOYS').DEPLOYMENT_CARD;
  const pipelineRuns = t('PIPELINE_RUNS');
  const translations = t('UI');

  // react query
  const { data: environment } = useEnvironmentQuery({ orgId, appId, envId: deployment?.env_id });
  const { data: environmentDeployments = [] } = useDeploymentsListQuery({
    envId: deployment?.env_id,
  });

  const { data: logs = [] } = usePipelineRunJobStepDetailsQuery({
    jobId,
    index: stepIndex,
  });

  const { data: items = [] } = usePipelineRunsApprovalRequestsQuery(appId, {
    run: pipelineRunId,
  });

  const jobApproval = items.find(({ job_id }) => job_id === jobId);

  const { data: approver } = useGetUserByIdQuery(jobApproval?.approved_by || '');

  const pastDeployments = useMemo(
    () => environmentDeployments.filter((deploy) => deploy.id !== environment?.last_deploy?.id),
    [environment?.last_deploy?.id, environmentDeployments]
  );

  return (
    <div className={'full-height py-md'}>
      {deployment && (
        <div>
          <div className={'flex-row flex-centered mb-md'}>
            <div className={'flex-column'}>
              <WalLabel>{deploysTranslations.COMMENT}</WalLabel>
              <Link
                to={generateDeploymentURL(
                  orgId,
                  appId,
                  deployment.env_id || '',
                  deployment.id,
                  'workloads'
                )}>
                {deployment.comment || translations.NO_COMMENT}
              </Link>
            </div>
          </div>
          <div className={'flex-row flex-centered mb-md'}>
            <div className={'flex-column mr-md'}>
              <WalLabel>{deploysTranslations.DEPLOYMENT_ID}</WalLabel>
              <span>{deployment.id}</span>
            </div>
            <div className={'flex-column'}>
              <WalLabel>{deploysTranslations.DEPLOYED_AT}</WalLabel>
              <span>
                {formatDate(deployment?.created_at, DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE)}
              </span>
            </div>
          </div>
          <div className={'flex-row flex-centered mb-md'}>
            {environment && (
              <DeploymentControls
                deployment={deployment}
                environment={environment}
                lastPastDeploymentId={pastDeployments?.[0]?.id}
              />
            )}
          </div>
        </div>
      )}
      <WalLabel>{pipelineRuns.LOGS}</WalLabel>
      <LogsWrapper>
        {logs.map(({ level, message, at }) => (
          <Log
            key={`${at} ${message}`}
            className={`${level} mb-md flex-row source-code`}
            data-testid={'step-log'}>
            <TimeStamp>{formatDate(at, DATE_FORMATS_TYPES.RFC_WITH_TIMEZONE)} </TimeStamp>
            <span>{level ? `[${level}]: ` : ' '}</span>
            <span>
              {message}{' '}
              {isStatusMessage(message) && approver && `${translations.BY} ${approver?.name}`}
            </span>
          </Log>
        ))}
      </LogsWrapper>
    </div>
  );
};
