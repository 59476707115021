import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { MatchParams } from '@src/models/routing';
import { Rule } from '@src/models/rule';
import makeRequest from '@src/utilities/make-request';

import { rulesQueryKeys } from '../rulesQueryKeys';

const useEnvironmentRuleDeleteMutation = () => {
  // Router hooks
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ ruleId }: { ruleId: string }) =>
      makeRequest<Rule[]>('DELETE', `/orgs/${orgId}/apps/${appId}/envs/${envId}/rules/${ruleId}`),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: rulesQueryKeys.list(orgId, appId, envId),
      });
    },
  });
};

export default useEnvironmentRuleDeleteMutation;
