import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { ResourceDefinition } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { resourcesQueryKeys } from '../resourcesQueryKeys';

const useResourceDefinitionByIdQuery = (defIdParam?: string) => {
  const { orgId, defId: routerDefId } = useParams<keyof MatchParams>() as MatchParams;

  const defId = defIdParam || routerDefId;

  return useQuery({
    queryKey: resourcesQueryKeys.definitionDetail(orgId, defId),
    queryFn: () => makeRequest<ResourceDefinition>('GET', `/orgs/${orgId}/resources/defs/${defId}`),
    enabled: Boolean(orgId && defId),
    select: (data) => data?.data,
  });
};

export default useResourceDefinitionByIdQuery;
