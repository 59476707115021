import {
  Button,
  DiffViewer,
  EmptyStateCard,
  ExpandableCard,
  WalModal,
  WalTable,
} from '@humanitec/ui-components';
import { dump } from 'js-yaml';
import React, { Dispatch, SetStateAction } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import { baseActiveResourcesColumns } from '@src/containers/Orgs/Resources/components/ResourceDefinition/components/ResourceDefinitionUsage/ResourceDefinitionUsage';
import { getPartialResourceDefinitionObjectToCompare } from '@src/containers/Orgs/Resources/components/ResourceDefinition/components/ResourceDefinitionVersions/resource-definition-versions.utils';
import useActiveResourcesQuery from '@src/hooks/react-query/resources/queries/useActiveResourcesQuery';
import { useResourceDefinitionVersionsQuery } from '@src/hooks/react-query/resources/queries/useResourceDefinitionVersions';
import { ResourceDefinitionVersion } from '@src/models/resources';

interface UpdateResourceDefinitionConfirmationModalProps {
  state: [boolean, Dispatch<SetStateAction<boolean>>];
  draftResourceDefinitionVersion: ResourceDefinitionVersion;
  onSaveAsActiveClick: () => void;
  onSaveAsProposedClick: () => void;
}
const UpdateResourceDefinitionConfirmationModal = ({
  state,
  draftResourceDefinitionVersion,
  onSaveAsActiveClick,
  onSaveAsProposedClick,
}: UpdateResourceDefinitionConfirmationModalProps) => {
  // i18n
  const { t } = useTranslation();
  const versionsTranslations = t('ACCOUNT_SETTINGS').RESOURCES.RESOURCE_VERSIONS;
  const uiTranslations = t('UI');

  // state
  const [_, setOpenModal] = state;

  // react query
  const { data: activeResources } = useActiveResourcesQuery();
  const { data: resourceDefinitionVersions } = useResourceDefinitionVersionsQuery();

  const activeResourceDefinitionVersion: ResourceDefinitionVersion | undefined =
    resourceDefinitionVersions?.find((defVersion) => defVersion.active);

  const saveAsProposed = () => {
    if (onSaveAsProposedClick) {
      onSaveAsProposedClick();
    }
    setOpenModal(false);
  };

  const setAsActive = () => {
    if (onSaveAsActiveClick) {
      onSaveAsActiveClick();
    }
    setOpenModal(false);
  };

  return (
    <WalModal
      openState={state}
      title={versionsTranslations.UPDATE_RESOURCE_DEFINITION}
      size={'large'}
      showClose
      content={
        <>
          <span>{versionsTranslations.ACTIVATE_RESOURCE_DEFINITION_MODAL_HINT}</span>
          {activeResourceDefinitionVersion && (
            <ExpandableCard
              id={'diff-card'}
              className={'my-lg'}
              cardStyle={'base'}
              headerContent={<span>{versionsTranslations.DIFF}</span>}
              content={
                <DiffViewer
                  oldValue={dump(
                    getPartialResourceDefinitionObjectToCompare(activeResourceDefinitionVersion)
                  )}
                  newValue={dump(
                    getPartialResourceDefinitionObjectToCompare(draftResourceDefinitionVersion)
                  )}
                  showDiffOnly
                />
              }
            />
          )}
          {activeResources && activeResources?.length > 0 ? (
            <WalTable
              tableRowStyle={'base'}
              rows={activeResources?.map((activeResource) => ({ data: activeResource }))}
              columns={baseActiveResourcesColumns}
              caption={versionsTranslations.ACTIVE_RESOURCES_TABLE}
            />
          ) : (
            <EmptyStateCard>{versionsTranslations.NO_AFFECTED_RESOURCES}</EmptyStateCard>
          )}
          <div className={'mt-md'}>
            <Trans defaults={versionsTranslations.SAVE_AS_PROPOSED_HINT}>
              <Link
                target={'_blank'}
                rel={'noopener noreferrer'}
                to={
                  'https://developer.humanitec.com/platform-orchestrator/resources/resource-definition-versions/#proposed-versions/'
                }
              />
            </Trans>
          </div>
          <div className={'flex-row mt-lg'}>
            <Button className={'mr-md'} size={'small'} onClick={setAsActive}>
              {versionsTranslations.SAVE_AS_ACTIVE}
            </Button>
            <Button
              className={'mr-md'}
              variant={'secondary'}
              size={'small'}
              onClick={saveAsProposed}>
              {versionsTranslations.SAVE_AS_PROPOSED}
            </Button>
            <Button variant={'secondary'} size={'small'} onClick={() => setOpenModal(false)}>
              {uiTranslations.CANCEL}
            </Button>
          </div>
        </>
      }
    />
  );
};

export default UpdateResourceDefinitionConfirmationModal;
