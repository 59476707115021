import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router';

import { DeploymentSet } from '@src/models/deployment-set';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { deploymentDeltaQueryKeys } from '../deploymentDeltaQueryKeys';

const useDeploymentDeltaMetadataMutation = () => {
  // Router hooks
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  const queryClient = useQueryClient();

  const { data, ...queryResult } = useMutation<
    AxiosResponse<DeploymentSet>,
    AxiosError,
    {
      deltaId: string;
      metadata: 'archived' | 'name';
      value: string | boolean;
    }
  >({
    mutationFn: ({ deltaId, metadata, value }) => {
      return makeRequest(
        'PUT',
        `/orgs/${orgId}/apps/${appId}/deltas/${deltaId}/metadata/${metadata}`,
        value
      );
    },
    onSuccess: (_, { deltaId }) => {
      // Invalidate individual delta
      queryClient.invalidateQueries({
        queryKey: deploymentDeltaQueryKeys.detail(orgId, appId, deltaId),
      });
      // Invalidate all deltas
      queryClient.invalidateQueries({
        queryKey: deploymentDeltaQueryKeys.list(orgId, appId, { env: envId }),
      });
    },
  });
  return { ...queryResult, data: data?.data };
};

export default useDeploymentDeltaMetadataMutation;
