import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import envValuesQueryKeys from '../envValuesQueryKeys';
import { sharedValuesQueryKeys } from '../sharedValuesQueryKeys';

const useEnvValuesDeleteMutation = () => {
  // Router hooks
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ envValueKey }: { envValueKey: string }) =>
      makeRequest('DELETE', `/orgs/${orgId}/apps/${appId}/envs/${envId}/values/${envValueKey}`),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: envValuesQueryKeys.list(orgId, appId, envId),
      });
      queryClient.invalidateQueries({
        queryKey: sharedValuesQueryKeys.envValuesList(orgId, appId, envId),
      });
    },
  });
};

export default useEnvValuesDeleteMutation;
