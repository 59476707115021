import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router';

import { ApiErrorResponse } from '@src/models/ApiErrorResponse';
import { ResourceClass } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { resourcesQueryKeys } from '../resourcesQueryKeys';

interface ResourceDefinitionUpdateMutationVariables {
  resourceTypeId: string;
  classId: string;
  description: string;
}

export const useResourceClassUpdateMutation = () => {
  const queryClient = useQueryClient();
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useMutation<
    AxiosResponse<ResourceClass>,
    AxiosError<ApiErrorResponse>,
    ResourceDefinitionUpdateMutationVariables
  >({
    mutationFn: ({ resourceTypeId, description, classId }) =>
      makeRequest('PATCH', `/orgs/${orgId}/resources/types/${resourceTypeId}/classes/${classId}`, {
        description,
      }),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: resourcesQueryKeys.listClasses(orgId) });
    },
  });

  return { ...queryResult, data: data?.data };
};
