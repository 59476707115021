import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useNavigate, useParams } from 'react-router';

import { Environment } from '@src/models/environment';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';
import { generateAppURL } from '@src/utilities/navigation';

import { applicationsQueryKeys } from '../../applications/applicationsQueryKeys';
import { environmentQueryKeys } from '../environmentQueryKeys';

const useEnvironmentDeleteMutation = () => {
  // Router hooks
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { data, ...queryResult } = useMutation<AxiosResponse<Environment>, AxiosError>({
    mutationFn: () => makeRequest('DELETE', `/orgs/${orgId}/apps/${appId}/envs/${envId}`),
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: environmentQueryKeys.environmentDetail(orgId, appId, envId),
      });

      queryClient.invalidateQueries({
        queryKey: environmentQueryKeys.applicationEnvironments(orgId, appId),
      });

      queryClient.invalidateQueries({
        queryKey: applicationsQueryKeys.detail(orgId, appId),
      });

      navigate(generateAppURL(orgId, appId));
    },
  });
  return { ...queryResult, data: data?.data };
};

export default useEnvironmentDeleteMutation;
