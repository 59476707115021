import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';

import { LogsSocketContext } from '@src/components/socket/contexts';
import SocketProvider from '@src/components/socket/socketProvider';
import { WebSocketMessageEvent } from '@src/components/socket/web-socket-message';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import { windowEnv } from '@src/environment';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import DisplayLogs from './DisplayLogs';

export interface Log {
  level: 'INFO' | '';
  payload: string;
  timestamp: string;
}

const LOG_REQUEST_LIMIT = 100;
const LOG_RENDER_LIMIT = 500;

/**
 * Limit the amount of logs.
 *
 * @param logs
 */
const filterlogs = (logs: Log[], beforeOrAfter: 'before' | 'after'): Log[] => {
  if (logs.length < LOG_RENDER_LIMIT) {
    return logs;
  }
  return beforeOrAfter === 'after'
    ? logs.splice(logs.length - LOG_RENDER_LIMIT, logs.length - 1)
    : logs.splice(0, LOG_RENDER_LIMIT);
};

interface ContainerLogProps {
  containerId: string;
}

const ContainerLog = ({ containerId }: ContainerLogProps) => {
  // i18n
  const { t } = useTranslation();
  const sectionsTranslations = t('VIEW_MODULE').SECTIONS;
  // Router hooks
  const { orgId, appId, envId, moduleId, deployId } = useParams<keyof MatchParams>() as MatchParams;
  const location = useLocation();

  // Component state
  const [historicalLogs, setHistoricalLogs] = useState<Record<string, Log[]>>({});
  const [liveLogs, setLiveLogs] = useState<Record<string, Log[]>>({});
  const [logs, setLogs] = useState<Log[]>([]);
  const [moreLogsAfter, setMoreLogsAfter] = useState<boolean>(false);
  const [moreLogsBefore, setMoreLogsBefore] = useState<boolean>(false);
  const [loadingBefore, setLoadingBefore] = useState<boolean>(false);
  const [loadingAfter, setLoadingAfter] = useState<boolean>(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [initialLoad, setInitialLoad] = useState<Record<string, boolean>>({});

  const [sendPayload, setSendPayload] = useState<{
    workload_id: string;
    container_id: string;
    deployment_id: string;
    timestamp_from: string;
  }>();

  // Context
  const { onRunningDeployment, draftModeActive } = useDeploymentOrDeltaContext();

  const onMessage = (message: WebSocketMessageEvent) => {
    const parsedMessage: Log[] = JSON.parse(message.data);

    if (!parsedMessage) return;

    setLiveLogs((prevState) => {
      if (!parsedMessage.length) return prevState;
      const currentLogs = prevState[containerId] || [];

      const newLogs = [...currentLogs, ...parsedMessage];

      return {
        ...prevState,
        [containerId]: newLogs,
      };
    });
  };

  const loadLogs = useCallback(
    (beforeOrAfter: 'before' | 'after', filters?: string) => {
      if (
        loadingMore ||
        (beforeOrAfter === 'after' && !moreLogsAfter && !initialLoad) ||
        (beforeOrAfter === 'before' && !moreLogsBefore && !initialLoad)
      )
        return;
      setLoadingMore(true);
      setLoadingBefore(beforeOrAfter === 'before');
      setLoadingAfter(beforeOrAfter === 'after');

      let URL = `/orgs/${orgId}/apps/${appId}/envs/${envId}/logs?workload_id=${moduleId}&container_id=${containerId}&deployment_id=${deployId}&limit=${LOG_REQUEST_LIMIT}&invert=true`;
      if (filters) URL += filters;
      makeRequest<Log[]>('GET', URL)
        .then((response) => {
          const logResponse = response.data;
          if (logResponse.length) {
            if (beforeOrAfter === 'before') {
              setMoreLogsBefore(true);
            } else if (beforeOrAfter === 'after') {
              setMoreLogsAfter(true);
            }
          } else {
            if (beforeOrAfter === 'before') {
              setMoreLogsBefore(false);
            } else if (beforeOrAfter === 'after') {
              setMoreLogsAfter(false);
            }
          }
          setHistoricalLogs((prevState) => {
            const currentLogs = prevState[containerId] || [];
            const logsArray = logResponse;

            const newLogs =
              beforeOrAfter === 'before'
                ? [...logsArray, ...currentLogs]
                : [...currentLogs, ...logsArray];

            return {
              ...prevState,
              [containerId]: filterlogs(newLogs, beforeOrAfter),
            };
          });
        })
        .catch(() => {
          setLoadingMore(false);
          setLoadingBefore(false);
          setLoadingAfter(false);
        })
        .finally(() => {
          setLoadingMore(false);
          setLoadingBefore(false);
          setLoadingAfter(false);
        });
    },
    [
      containerId,
      deployId,
      moduleId,
      loadingMore,
      appId,
      envId,
      orgId,
      moreLogsAfter,
      moreLogsBefore,
      initialLoad,
    ]
  );

  const getLogsAfter = useCallback(() => {
    let filters = '';
    let lastLogTime = '';
    const lastHistoricalTimestamp =
      historicalLogs[containerId]?.[historicalLogs[containerId].length - 1]?.timestamp;
    if (lastHistoricalTimestamp) {
      lastLogTime = lastHistoricalTimestamp;
    }
    filters += lastLogTime
      ? `&timestamp_from=${lastLogTime}`
      : `&timestamp_from=${new Date().toISOString()}`;
    loadLogs('after', filters);
  }, [loadLogs, containerId, historicalLogs]);

  const getLogsBefore = useCallback(() => {
    let filters = '';
    let lastLogTime = '';
    if (containerId && historicalLogs[containerId] && historicalLogs[containerId][0]) {
      lastLogTime = historicalLogs[containerId][0].timestamp;
    }
    filters += lastLogTime
      ? `&timestamp_to=${lastLogTime}`
      : `&timestamp_to=${new Date().toISOString()}`;
    loadLogs('before', filters);
  }, [loadLogs, containerId, historicalLogs]);

  useEffect(() => {
    if (!initialLoad[containerId]) {
      setInitialLoad((prevState) => {
        return {
          ...prevState,
          [containerId]: true,
        };
      });
      getLogsBefore();
    }
  }, [initialLoad, getLogsBefore, containerId]);

  useEffect(() => {
    return () => {
      setHistoricalLogs({});
      setInitialLoad({});
      setMoreLogsAfter(true);
      setMoreLogsBefore(true);
    };
  }, []);

  useEffect(() => {
    const hLogs = historicalLogs[containerId] || [];
    const lLogs = liveLogs[containerId] || [];

    if (onRunningDeployment) {
      setLogs([...hLogs, ...lLogs]);
    } else {
      setLogs(hLogs);
    }
  }, [historicalLogs, liveLogs, containerId, onRunningDeployment]);

  useEffect(() => {
    if (!initialLoad[containerId]) return;
    let lastLogTime = '';
    const lastLogTimestamp = logs[logs.length - 1]?.timestamp;
    if (lastLogTimestamp) {
      lastLogTime = lastLogTimestamp;
    }
    setSendPayload({
      workload_id: moduleId,
      container_id: containerId,
      deployment_id: deployId,
      timestamp_from: lastLogTime || new Date().toISOString(),
    });
  }, [logs, initialLoad, containerId, moduleId, deployId]);
  return (
    <SocketProvider
      context={LogsSocketContext}
      url={`${windowEnv.BASE_WEBSOCKET_URL}/orgs/${orgId}/apps/${appId}/envs/${envId}/logs/watch`}
      scope={location.pathname}
      sendPayload={sendPayload?.container_id === containerId ? sendPayload : undefined}
      onlyOpenWhenSendPayloadExists
      onMessage={onMessage}>
      {logs?.length === 0 && !draftModeActive ? (
        <span className={'txt-md'}>{sectionsTranslations.NO_CONTAINER_LOGS}</span>
      ) : (
        <DisplayLogs
          moreLogsBefore={moreLogsBefore}
          moreLogsAfter={moreLogsAfter}
          loadingAfter={loadingAfter}
          loadingBefore={loadingBefore}
          logs={logs}
          getLogsAfter={getLogsAfter}
          getLogsBefore={getLogsBefore}
        />
      )}
    </SocketProvider>
  );
};

export default ContainerLog;
