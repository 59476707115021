import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import type { QueryResponse } from '@src/hooks/react-query/types';
import { ResourceDependencyGraph } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { resourcesQueryKeys } from '../resourcesQueryKeys';

const useResourceDependencyGraphQuery = (
  graphId?: string,
  enabled = true
): QueryResponse<ResourceDependencyGraph> => {
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery({
    queryKey: resourcesQueryKeys.dependencyGraph(orgId, appId, envId, graphId),
    queryFn: () =>
      makeRequest<ResourceDependencyGraph>(
        'GET',
        `/orgs/${orgId}/apps/${appId}/envs/${envId}/resources/graphs/${graphId}`
      ),
    enabled: enabled && Boolean(orgId && appId && envId && graphId),
  });

  return { ...queryResult, data: data?.data };
};

export default useResourceDependencyGraphQuery;
