import { useMutation, useQueryClient } from '@tanstack/react-query';
import { omit } from 'lodash';
import { useParams } from 'react-router';

import { KeyValue } from '@src/models/general';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import appValuesQueryKeys from '../queries/appValuesQueryKeys';
import { sharedValuesQueryKeys } from '../sharedValuesQueryKeys';

const useAppValuesUpdateMutation = () => {
  // Router hooks
  const { orgId, appId } = useParams<keyof MatchParams>() as MatchParams;

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ appValue }: { appValue: KeyValue }) =>
      makeRequest(
        'PATCH',
        `/orgs/${orgId}/apps/${appId}/values/${appValue.key}`,
        omit(appValue, ['key', 'is_secret'])
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: appValuesQueryKeys.list(orgId, appId),
      });
      queryClient.invalidateQueries({
        queryKey: sharedValuesQueryKeys.appValuesList(orgId, appId),
      });
    },
  });
};

export default useAppValuesUpdateMutation;
