import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { AllRoles } from '@src/models/role';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { userQueryKeys } from '../userQueryKeys';

const useUserRolesQuery = (customParams?: { userId?: string }) => {
  const { orgId, userId: routerUserId } = useParams<keyof MatchParams>() as MatchParams;
  const userId = customParams?.userId || routerUserId;

  return useQuery({
    queryKey: userQueryKeys.userRoles(orgId, userId),
    queryFn: () =>
      makeRequest<Record<string, AllRoles>>('GET', `/orgs/${orgId}/users/${userId}/roles`),
    select: (data) => data.data,
    enabled: Boolean(orgId && userId),
  });
};

export default useUserRolesQuery;
