import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { QueryResponse } from '@src/hooks/react-query/types';
import { ResourceDriver } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { resourcesQueryKeys } from '../resourcesQueryKeys';

const useResourceDriversQuery = (): QueryResponse<ResourceDriver[]> => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery({
    queryKey: resourcesQueryKeys.listDrivers(orgId),
    queryFn: () => makeRequest<ResourceDriver[]>('GET', `/orgs/${orgId}/resources/drivers`),
    enabled: Boolean(orgId),
  });

  return { ...queryResult, data: data?.data };
};

export default useResourceDriversQuery;
