import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import type { QueryResponse } from '@src/hooks/react-query/types';
import { Artefact } from '@src/models/artefact';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { artefactQueryKeys } from '../artefactsQueryKeys';

const useArtefactsQuery = (): QueryResponse<Artefact[]> => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery({
    queryKey: artefactQueryKeys.listArtefacts(orgId),
    queryFn: () => makeRequest<Artefact[]>('GET', `/orgs/${orgId}/artefacts`),
    enabled: Boolean(orgId),
  });

  return { ...queryResult, data: data?.data };
};

export default useArtefactsQuery;
