import { TabGroup } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';

import { PrivateRouteForOrgRole } from '@src/components/shared/PrivateRouteForOrgRole';
import { useDecision } from '@src/hooks/useDecision';

import SectionsSettingWrapper from '../../components/styles';

export const OrgMembersRoot = () => {
  // Optimizely
  const [userGroupsDecision] = useDecision('user-groups');

  // i18n
  const { t } = useTranslation('orgMembers');

  const tabTranslations = t('TABS');

  return (
    <PrivateRouteForOrgRole permissionName={'viewOrgMembersPage'} redirectUrl={'apps'}>
      {userGroupsDecision.enabled ? (
        <SectionsSettingWrapper>
          <TabGroup
            options={[
              { label: tabTranslations.USERS, value: 'users', link: { to: 'users' } },
              { label: tabTranslations.GROUPS, value: 'groups', link: { to: 'groups' } },
            ]}
          />
          <Outlet />
        </SectionsSettingWrapper>
      ) : (
        <Outlet />
      )}
    </PrivateRouteForOrgRole>
  );
};
