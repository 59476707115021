import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router';

import { ApiErrorResponse } from '@src/models/ApiErrorResponse';
import { ResourceDefinition } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { resourcesQueryKeys } from '../resourcesQueryKeys';

interface ResourceDefinitionUpdateMutationVariables {
  resourceDefinition: Partial<ResourceDefinition>;
  force?: boolean;
}
const useResourceDefinitionUpdateMutation = (
  successCallback?: () => void,
  errorCallback?: (error: AxiosError) => void
) => {
  const queryClient = useQueryClient();
  const { orgId, defId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useMutation<
    AxiosResponse<ResourceDefinition>,
    AxiosError<ApiErrorResponse>,
    ResourceDefinitionUpdateMutationVariables
  >({
    mutationFn: ({ resourceDefinition }) =>
      makeRequest(
        'PATCH',
        `/orgs/${orgId}/resources/defs/${resourceDefinition.id || defId}`,
        resourceDefinition
      ),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: resourcesQueryKeys.listDefinitions(orgId) });
      queryClient.invalidateQueries({
        queryKey: resourcesQueryKeys.definitionDetail(orgId, defId),
      });
      queryClient.invalidateQueries({
        queryKey: resourcesQueryKeys.definitionVersionsList(orgId, defId),
      });
      if (successCallback) {
        successCallback();
      }
    },
    onError: errorCallback,
  });
  return { ...queryResult, data: data?.data };
};

export default useResourceDefinitionUpdateMutation;
