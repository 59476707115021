import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { pipelinesQueryKeys } from '@src/hooks/react-query/pipelines/pipelinesQueryKeys';
import { QueryResponse } from '@src/hooks/react-query/types';
import { PipelineVersion } from '@src/models/pipeline';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

const usePipelinesVersions = (): QueryResponse<PipelineVersion[]> => {
  const { orgId, appId, pipelineId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery({
    queryKey: pipelinesQueryKeys.versions(orgId, appId, pipelineId),
    queryFn: () => {
      return makeRequest<PipelineVersion[]>(
        'GET',
        `/orgs/${orgId}/apps/${appId}/pipelines/${pipelineId}/versions`
      );
    },
    // Don't make request if any of the params don't exist
    enabled: Boolean(orgId && appId && pipelineId),
  });

  return { ...queryResult, data: data?.data };
};

export default usePipelinesVersions;
