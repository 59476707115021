import { cloneDeep, get, isEqual, unset } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';

import { DeploymentDelta, DeploymentDeltaChange } from '@src/models/deployment-delta';
import { Workload } from '@src/models/deployment-set';
import { MatchParams } from '@src/models/routing';
import { convertPathToArraySegments, encodePathKey } from '@src/utilities/deployment-delta-utils';
import { mapResourceEntry } from '@src/utilities/resource-utils';

import useDeploymentDeltaUpdateMutation, {
  DeltaCurrentActions,
} from '../react-query/deployment-delta/mutations/useDeploymentDeltaUpdateMutation';
import useDeploymentDeltaQuery from '../react-query/deployment-delta/queries/useDeploymentDeltaQuery';
import useDeploymentSetQuery from '../react-query/deployment-set/useDeploymentSetQuery';
import useResourceTypesQuery from '../react-query/resources/queries/useResourceTypesQuery';
import { useGetDeploymentSetWithDeltaUpdates } from '../useGetDeploymentSetWithDeltaUpdates';
import { useGetWorkloadResourceData } from './useGetWorkloadResourceDependencies';

export type UpdateWorkloadChanges = (Omit<DeploymentDeltaChange, 'path'> & { key?: string })[];

interface UpdateWorkloadOptions {
  deltaAction?: DeltaCurrentActions;
  /**
   * If this is enabled, any paths which do not have a child property will be removed.
   * This will happen recursively until a path with at least one child prop has been found.
   *
   * @example
   * ```ts
   * // With deployment set
   * {
   *   spec: {
   *     service: {
   *       ports: {
   *         myport: {
   *           container_port: 123,
   *           protocol: 'tcp'
   *         }
   *       }
   *     },
   *     variables: {
   *       VAR1: 'VAL1'
   *     }
   *   }
   * }
   *
   * // Sending the following delta operation
   * {
   *   op: 'remove',
   *   path: '/spec/service/ports/myport'
   * }
   *
   * // Will result in the following being sent to the backend:
   * {
   *   op: 'remove',
   *   path: '/spec/service'
   * }
   * ```
   */
  deleteParentPaths?: boolean;
  customPath?: string;
}

/**
 * @example Posting a change to a the delta
 *
 * ```ts
 *
 * const { updateWorkload } = useDeltaUtils(`spec/path/to/variables`);
 *
 * const addItem = (keyValueItem) => {
 *  updateWorkload([
 *    {
 *      // e.g. if the key is `myvariable`, the resolved path will be `/spec/path/to/variables/myvariable`
 *      path: keyValueItem.key,
 *      op: 'add',
 *      value: keyValueItem.value
 *    }
 *  ]);
 * }
 *
 * ```
 * @param basePath The path to the data in the deployment set. e.g. if we're working with variables, the path will be `spec/containers/{containerId}/variables`. Don't use leading or trailing slashes. They will be added in the hook as required.
 * Note: basePath is only used for `updateWorkload`, `data`, `deployedData`. The other functions deal with add/delete workloads, so basePath is not required.
 */
export const useDeltaUtils = <T>(basePath: string = '', onSuccess?: () => void) => {
  // Router hooks
  const { orgId, appId, envId, moduleId, deltaId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const { data: deploymentSet } = useDeploymentSetQuery();
  const { data: activeDelta } = useDeploymentDeltaQuery();
  const {
    mutate: updateDeploymentDelta,
    isSuccess: isDeploymentDeltaUpdated,
    reset: resetMutation,
  } = useDeploymentDeltaUpdateMutation();
  const { data: resourceTypes } = useResourceTypesQuery();

  // Hooks
  const deploymentSetWithDeltaUpdates = useGetDeploymentSetWithDeltaUpdates();

  const workloadPath = `modules/${moduleId}`;

  // Automatically trim leading slash if it's present.
  basePath = basePath.startsWith('/') ? basePath.substring(1, basePath.length) : basePath;

  const pathToData = basePath ? `${workloadPath}/${basePath}` : workloadPath;

  const data = get(deploymentSetWithDeltaUpdates, convertPathToArraySegments(pathToData)) as
    | T
    | undefined;
  const deployedData = get(deploymentSet, convertPathToArraySegments(pathToData)) as T | undefined;

  const workloadResourceData = useGetWorkloadResourceData(workloadPath);

  /**
   * Function to update the workload in the delta. This function will also make the request to the backend.
   *
   * @param changes The changes to post to the delta. The `path` is appended to what was passed as `basePath`. Don't use leading or trailing slashes. If `path` is omitted, the basePath will be used.
   */
  const updateWorkload = (changes: UpdateWorkloadChanges, options?: UpdateWorkloadOptions) => {
    const newChanges: DeploymentDeltaChange[] = [];

    const { deltaAction, deleteParentPaths = false } = options || {};

    const existingDeltaChanges = activeDelta?.modules?.update?.[moduleId];

    for (const change of changes) {
      const { op, value } = change;
      let { key } = change;

      let newValue = value;
      let newOp = op;

      key = key && encodePathKey(key);

      let deltaChangePath = key ? `/${options?.customPath ?? basePath}/${key}` : `/${basePath}`;

      if (op === 'remove') {
        if (deleteParentPaths) {
          const tempWorkload: Workload = get(
            cloneDeep(deploymentSetWithDeltaUpdates),
            convertPathToArraySegments(workloadPath)
          );

          const split = convertPathToArraySegments(deltaChangePath);

          for (let i = split.length - 1; i >= 0; i--) {
            const pathToRemove = split.slice(0, i + 1);
            const pathToCheck = split.slice(0, i);

            unset(tempWorkload, pathToRemove);

            const hasValue = get(tempWorkload, pathToCheck);

            // If updating spec, we don't want to remove 'spec'. Always take the path above it e.g. /spec/service
            const isUpdatingSpec = pathToRemove.length === 2 && pathToRemove[0] === 'spec';

            // If we find a value with keys, it means it has at least 1 child property.
            // In this case, we break the loop and set the deltaChangePath to the new path.
            if (Object.keys(hasValue || {}).length || isUpdatingSpec) {
              deltaChangePath = `/${pathToRemove.join('/')}`;
              break;
            }
          }
        }

        const valueWasAddedInDelta = existingDeltaChanges?.find(
          (update) => update.op === 'add' && update.path === deltaChangePath
        );

        // Pass `{ scope: delta }` to remove the change from the delta.
        newValue =
          valueWasAddedInDelta ||
          // If { scope: 'delta' } is passed as value, that means that we're restoring a value that was deleted in the delta
          isEqual(value, { scope: 'delta' })
            ? { scope: 'delta' }
            : null;
      } else if (op === 'add') {
        const arrayPath = convertPathToArraySegments(`${workloadPath}/${deltaChangePath}`);

        const valueInDeploymentSet = get(deploymentSet, arrayPath) as T | undefined;
        const valueWasRemovedInDelta = existingDeltaChanges?.find(
          (update) =>
            update.op === 'remove' &&
            update.path === deltaChangePath &&
            isEqual(value, valueInDeploymentSet)
        );

        if (valueWasRemovedInDelta) {
          newValue = { scope: 'delta' };
          newOp = 'remove';
        }
      }

      newChanges.push({
        path: deltaChangePath,
        op: newOp,
        value: newValue,
      });
    }

    updateDeploymentDelta({
      orgId,
      appId,
      envId,
      deploymentDelta: {
        modules: {
          update: {
            [moduleId]: newChanges,
          },
        },
      },
      deltaId,
      currentAction: deltaAction,
    });
  };

  useEffect(() => {
    if (isDeploymentDeltaUpdated) {
      onSuccess?.();
      resetMutation();
    }
  }, [isDeploymentDeltaUpdated, onSuccess, resetMutation]);

  return {
    /**
     * Adds a workload to the delta.
     *
     * @param workloadId The user provided workloadId
     * @param workload The workload payload
     */
    addWorkload: (workloadId: string, workload: Workload) => {
      updateDeploymentDelta({
        orgId,
        appId,
        envId,
        deploymentDelta: {
          modules: {
            add: {
              [workloadId]: workload,
            },
          },
        },
        deltaId,
        currentAction: 'add-workload',
      });
    },
    /**
     * Remove a workload from the set or deployment delta.
     *
     * @param workloadId The workload to delete
     */
    deleteWorkload: (workloadId: string) => {
      let deltaToPatch: Partial<DeploymentDelta>[] = [];

      // Initially, assume that the workload already exists in the set...
      deltaToPatch = [
        {
          modules: {
            remove: [workloadId],
          },
        },
      ];
      /**
       * ... but if the workload was added in draft, remove the workload and then remove it from the remove array.
       *
       * @example
       * Assume we have the following delta:
       * ```
       * {
       *   "modules": {
       *     "add": {
       *       "addedindelta": {
       *         "externals": null,
       *         "profile": "humanitec/default-module",
       *         "spec": {}
       *       }
       *     },
       *     "remove": [],
       *     "update": null
       *   }
       * }
       * ```
       * Posting the contents of the if statement will result in a delta with an empty add property:
       * ```
       * {
       *   "modules": {
       *     "add": null,
       *     "remove": [],
       *     "update": null
       *   }
       * }
       * ```
       */
      if (activeDelta?.modules?.add && activeDelta?.modules?.add[workloadId]) {
        deltaToPatch = [
          {
            modules: {
              remove: [workloadId],
            },
          },
          {
            modules: {
              add: {
                [workloadId]: null,
              },
            },
          },
        ];
      }

      updateDeploymentDelta({ orgId, appId, envId, deploymentDelta: deltaToPatch, deltaId });
    },
    /**
     * Restore a workload. This assumes that the workload already exists in the set, and you have a delta which deletes this workload.
     * This functions removes the delete from the delta, essentially "undeleting" the workload.
     *
     * @param workloadId The workload ID to restore.
     */
    restoreDeletedWorkload: (workloadId: string) => {
      if (activeDelta?.modules?.remove?.includes(workloadId)) {
        updateDeploymentDelta({
          orgId,
          appId,
          envId,
          deploymentDelta: {
            modules: {
              add: {
                [workloadId]: null,
              },
            },
          },
          deltaId,
        });
      }
    },
    updateWorkload,
    /**
     * The same as `updateWorkload`, except it only deals with the volumes path.
     * Since the volumes path is determined by where it is located in the workload profile schema.
     */
    updateWorkloadVolumes: (changes: UpdateWorkloadChanges, options?: UpdateWorkloadOptions) => {
      updateWorkload(changes, {
        ...options,
        customPath: workloadResourceData.volumesFeaturePath,
      });
    },
    /**
     * Combination of deployment set & delta updates for the current deltaPath.
     */
    data,
    /**
     * Deployment set for the current deltaPath.
     */
    deployedData,
    /**
     * All the workload ids that exist when the delta is applied to the deployment set.
     */
    workloadIds: Object.keys(deploymentSetWithDeltaUpdates?.modules || {}),
    /**
     * The complete profile id of the current workload e.g. `humanitec/default-module`
     */
    workloadProfileId: deploymentSetWithDeltaUpdates?.modules?.[moduleId]?.profile,
    /**
     * Shared resources under DeploymentSet.shared
     */
    sharedResources: deploymentSetWithDeltaUpdates?.shared,
    /**
     * Shared resources in array format with full paths.
     */
    sharedResourcesList: useMemo(
      () =>
        Object.entries(deploymentSetWithDeltaUpdates?.shared ?? {}).map((entry) =>
          mapResourceEntry(entry, 'shared', resourceTypes || [])
        ),
      [deploymentSetWithDeltaUpdates?.shared, resourceTypes]
    ),
    /**
     * All workloads
     */
    workloads: deploymentSetWithDeltaUpdates?.modules,
    /**
     * All the resource dependencies for the workload.
     */
    workloadResourceDependencies: workloadResourceData.resourceDependencies,
  };
};
