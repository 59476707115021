import { useQueryClient } from '@tanstack/react-query';
import { ReactNode, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import InfoModalWithDismiss from '@src/components/shared/modals/InfoModalWithDismiss';
import { NotificationsSocketContext, RuntimeSocketContext } from '@src/components/socket/contexts';
import { WebSocketEventTypes } from '@src/components/socket/event-types';
import SocketProvider from '@src/components/socket/socketProvider';
import { useHandleSocketMessage } from '@src/components/socket/useHandleSocketMessage';
import { WebSocketMessageEvent } from '@src/components/socket/web-socket-message';
import { windowEnv } from '@src/environment';
import { applicationsQueryKeys } from '@src/hooks/react-query/applications/applicationsQueryKeys';
import useApplicationQuery from '@src/hooks/react-query/applications/queries/useApplicationQuery';
import { environmentQueryKeys } from '@src/hooks/react-query/environments/environmentQueryKeys';
import useGetCurrentUserQuery from '@src/hooks/react-query/user/queries/useGetCurrentUserQuery';
import { MatchParams } from '@src/models/routing';
import { generateAppURL } from '@src/utilities/navigation';

const SocketWrapper = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();
  const deleteEnvInfoModalTranslations = t('INFO_MODAL');

  const [infoModalOpen, setInfoModalOpen] = useState(false);

  // Router hooks
  const navigate = useNavigate();
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const queryClient = useQueryClient();
  const { data: application } = useApplicationQuery();
  const { data: user } = useGetCurrentUserQuery();

  useHandleSocketMessage(appId, NotificationsSocketContext, (message) => {
    // deployment event received
    if (message.type === WebSocketEventTypes.DEPLOYMENT) {
      // if the deployment started.
      if (message.data.status === 'started') {
        // load the environment deployments if a deployment has been started
        queryClient.invalidateQueries({
          queryKey: environmentQueryKeys.listEnvironmentDeployments(
            orgId,
            message.data.application_id,
            message.data.environment_id
          ),
        });
        queryClient.invalidateQueries({
          queryKey: environmentQueryKeys.environmentDetail(
            orgId,
            message.data.application_id,
            message.data.environment_id
          ),
        });
      }
      // if the deployment is finished
      if (message.data.status === 'succeeded' || message.data.status === 'failed') {
        queryClient.invalidateQueries({
          queryKey: applicationsQueryKeys.detail(orgId, appId),
        });
        queryClient.invalidateQueries({
          queryKey: environmentQueryKeys.applicationEnvironments(
            orgId,
            message.data.application_id
          ),
        });
        queryClient.invalidateQueries({
          queryKey: environmentQueryKeys.environmentBase(
            orgId,
            message.data.application_id,
            message.data.environment_id
          ),
        });
      }
    }
    // when exporting deployment message is received
    if (message?.type === WebSocketEventTypes.DEPLOYMENT_EXPORTED && message.data?.environment_id) {
      queryClient.invalidateQueries({
        queryKey: environmentQueryKeys.listEnvironmentDeployments(
          orgId,
          message.data.application_id,
          message.data.environment_id
        ),
      });
    }
    // when environment is deleted message is received
    if (message.type === WebSocketEventTypes.ENV_DELETED) {
      // a different user deleted the current environment
      if (message.data.environment_id === envId && message.data.triggered_by !== user?.id) {
        setInfoModalOpen(true);
      } else {
        queryClient.invalidateQueries({
          queryKey: applicationsQueryKeys.detail(orgId, message.data.application_id),
        });
        queryClient.removeQueries({
          queryKey: environmentQueryKeys.environmentDetail(
            orgId,
            message.data.application_id,
            message.data.environment_id
          ),
        });
        navigate(generateAppURL(orgId));

        // Remove env query. If we create an env with the same name again, the previous env query would be reused.
        queryClient.removeQueries({
          queryKey: environmentQueryKeys.environmentDetail(
            orgId,
            message.data.application_id,
            message.data.environment_id
          ),
        });
      }
    }
  });

  const handleRuntimeReceiveSocket = useCallback(
    (message: WebSocketMessageEvent) => {
      if (message.data) {
        queryClient.setQueryData(
          environmentQueryKeys.environmentRuntimeDetail(orgId, appId, envId),
          {
            data: JSON.parse(message.data),
          }
        );
      }
    },
    [orgId, appId, envId, queryClient]
  );

  return (
    <SocketProvider
      context={RuntimeSocketContext}
      url={`${windowEnv.BASE_WEBSOCKET_URL}/orgs/${orgId}/apps/${appId}/envs/${envId}/runtime/watch?version=2`}
      scope={`${orgId}-${appId}-${envId}`}
      onMessage={handleRuntimeReceiveSocket}>
      {children}
      <InfoModalWithDismiss
        title={deleteEnvInfoModalTranslations.ENV_DELETED}
        confirmMessage={deleteEnvInfoModalTranslations.ENV_DELETED_INFO}
        state={[infoModalOpen, setInfoModalOpen]}
        navigateFunction={() => {
          queryClient.invalidateQueries({
            queryKey: applicationsQueryKeys.detail(orgId, appId),
          });
          navigate(generateAppURL(orgId, appId, application?.envs[0]?.id));
        }}
      />
    </SocketProvider>
  );
};

export default SocketWrapper;
