import { rem, rgba } from 'polished';
import { KeyboardEvent, MouseEvent, ReactNode, useMemo } from 'react';
import { Link, LinkProps } from 'react-router';
import styled, { css } from 'styled-components';

import { ellipsisStyle } from '@src/styles/mixins';
import { units } from '@src/styles/variables';

import { Spinner } from '..//Spinner/Spinner';
import { Icon, IconNames, IconProps, IconSizes } from '../Icon/Icon';
import InfoPopup from '../InfoPopup/InfoPopup';

export type ButtonType = 'button' | 'reset' | 'submit';
export type ButtonSize = 'small' | 'medium' | 'large';
export type ButtonVariant =
  | 'primary'
  | 'border'
  | 'danger'
  | 'secondary'
  | 'input'
  | 'borderless-blue'
  | 'borderless-red';
export type ButtonIcon = IconNames | IconProps;
type ReactRouterButtonLink = Pick<LinkProps, 'to' | 'state' | 'reloadDocument'>;
type PlainLink = { href: string };

export const isPlainLink = (value?: ReactRouterButtonLink | PlainLink): value is PlainLink => {
  return value ? Boolean((value as PlainLink).href) : false;
};

export const isReactRouterLink = (
  value?: ReactRouterButtonLink | PlainLink
): value is { href: string } => {
  return value ? Boolean((value as ReactRouterButtonLink).to) : false;
};

export interface ButtonProps {
  /** The inner content of the button */
  children?: ReactNode;
  /** The size of the button */
  size?: ButtonSize;
  /** The theme variant of the button */
  variant?: ButtonVariant;
  /** Information about the action to display under the main content */
  info?: string;
  /** If the button is disabled */
  disabled?: boolean;
  /** The type of button */
  type?: ButtonType;
  /** The link attached to the button. Use 'href' for external links(react-router prepends the existing route to the supplied link when 'to' is used) */
  link?: ReactRouterButtonLink | PlainLink;
  /** The link target */
  target?: string;
  /** The relationship between current page and linked item */
  rel?: string;
  /** The click handler for the button */
  onClick?: (e: MouseEvent) => void;
  /** mousedown handler for the button */
  onMouseDown?: (e: MouseEvent) => void;
  /** mouseenter handler for the button */
  onMouseEnter?: (e: MouseEvent) => void;
  /** keydown handler for the button */
  onKeyDown?: (e: KeyboardEvent) => void;
  /** Custom class */
  className?: string;
  /** Custom testid for QA */
  dataTestid?: string;
  /** if the dropdown should not have a border of the given side */
  noBorder?: 'right' | 'left';
  /** if the button has loading state */
  loading?: boolean;
  buttonRef?: any;
  /** html id for testing */
  id?: string;
  iconLeft?: ButtonIcon;
  iconRight?: ButtonIcon;
  infoPopup?: {
    text: string;
    position?: 'left' | 'right';
    moreInformationLink?: string;
  };
  tabIndex?: number;
  ariaLabel?: string;
  download?: string;
  /** if the button color should be solid and not transparent */
  solidColor?: boolean;
  /** the width of of the inner wrapper should be set to auto instead of full width (100%) */
  innerWrapperNotFullWidth?: boolean;
  /** max width for the button text after which ellipsis will be triggered */
  maxWidthEllipsis?: number;
}

interface ButtonVariantProps {
  /** Background color */
  backgroundColor?: string;
  /** Background color on hover */
  backgroundHoverColor?: string;
  /** Text color */
  color?: string;
  /** Text color on hover */
  hoverColor?: string;
  /** Border color */
  borderColor?: string;
  /** Border color on hover */
  borderHoverColor?: string;
  /** The button font weight */
  fontWeight?: number;
  /** if the button should have an inset shadow (input variant) */
  boxShadow?: boolean;
  hoverBoxShadow?: string;
}

// Interface for the actual HTML button.
interface ButtonElementProps {
  /** The size of the button */
  size?: string;
  /** if the button color should be solid and not transparent */
  $solidColor?: boolean;
  /** The theme variant of the button */
  variant?: ButtonVariant;
  /** If the button is disabled */
  disabled?: boolean;
  /** Custom testid for QA */
  dataTestid?: string;
  /** if the dropdown should not have a border of the given side */
  noBorder?: 'right' | 'left';
  $iconOnly: boolean;
}

/**
 * Mixin for the button variant
 */
const buttonVariantStyle = ({
  backgroundColor,
  backgroundHoverColor,
  color = '#FFFFFF',
  hoverColor,
  borderColor = 'transparent',
  borderHoverColor = 'transparent',
  fontWeight = 400,
  boxShadow = false,
  hoverBoxShadow,
}: ButtonVariantProps) => {
  return css`
    background-color: ${backgroundColor};
    color: ${color};
    border: ${rem(1)} solid ${borderColor};
    font-weight: ${fontWeight};
    box-shadow: ${boxShadow &&
    `inset 1px 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(255, 255, 255, 0.05);`};

    &:not(:disabled):hover {
      background-color: ${backgroundHoverColor || backgroundColor};
      color: ${hoverColor || color};
      border-color: ${borderHoverColor || borderColor};
      ${hoverBoxShadow &&
      css`
        box-shadow: ${hoverBoxShadow};
      `}
    }
    &:disabled {
      background-color: ${({ theme }) => rgba(theme.color.baseOutline, 0.48)};
      border-color: transparent;
      pointer-events: none;
    }
  `;
};

const borderLessVariantStyle = (borderlessStyle: 'main' | 'alert') => {
  return css`
    background-color: transparent;
    color: ${({ theme }) => theme.color[borderlessStyle]};
    padding-left: 0;
    border: 0;

    &:not(:disabled):hover {
      border: 0;
      background-color: transparent;
      color: ${({ theme }) => theme.color[borderlessStyle]};
      text-decoration: underline;
    }
    &:not(:disabled):focus {
      outline: none;
      text-decoration: underline;
    }
  `;
};

/**
 * Mixin for the button size
 */
const buttonSizeStyle = (
  fontSize: string,
  lineHeight: string,
  paddingVertical: string,
  paddingHorizontal: string
) => {
  return css`
    font-size: ${fontSize};
    line-height: ${lineHeight};
    padding: ${paddingVertical} ${paddingHorizontal};
  `;
};

const ButtonElement = styled.button<ButtonElementProps>`
  ${(props) => buttonCss(props)}
`;

const LinkButton = styled(Link)<ButtonElementProps>`
  text-decoration: none;
  ${(props) => buttonCss(props)}
`;

const PlainLinkButton = styled.a<ButtonElementProps>`
  text-decoration: none;
  ${(props) => buttonCss(props)}
`;

/**
 * Styling for the button component
 */
const buttonCss = ({
  size = 'medium',
  variant = 'primary',
  $solidColor,
  noBorder,
  $iconOnly,
  disabled,
}: ButtonElementProps) => {
  return css`
    cursor: pointer;
    -webkit-appearance: unset;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: ${rem(4)};
    font-weight: 500;
    &:focus {
      outline: -webkit-focus-ring-color auto;
    }

    ${() => {
      if (noBorder) {
        switch (noBorder) {
          case 'left':
            return css`
              border-left: 0;
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
            `;
          case 'right':
            return css`
              border-right: 0;
              border-bottom-right-radius: 0;
              border-top-right-radius: 0;
            `;
          // no default
        }
      }
    }}

    ${() => {
      switch (size) {
        case 'large':
          return buttonSizeStyle(units.fontSize.base, rem(20), rem(9), rem(15));
        case 'small':
          return buttonSizeStyle(units.fontSize.sm, rem(16), rem($iconOnly ? 4 : 3), rem(7));
        case 'medium':
        default:
          return buttonSizeStyle(units.fontSize.sm, rem(18), rem($iconOnly ? 7 : 6), rem(11));
      }
    }}

    ${({ theme }) => {
      switch (variant) {
        case 'borderless-blue':
          return borderLessVariantStyle('main');
        case 'borderless-red':
          return borderLessVariantStyle('alert');
        case 'secondary':
          return buttonVariantStyle({
            backgroundColor: theme.color.baseSecondaryButton,
            color: theme.color.text,
            borderColor: theme.color.baseOutline,
            borderHoverColor: theme.color.main,
          });
        case 'input':
          return buttonVariantStyle({
            backgroundColor: theme.color.baseLayer,
            color: theme.color.text,
            borderColor: theme.color.baseOutline,
            borderHoverColor: theme.color.main,
            boxShadow: true,
          });
        case 'border':
          return buttonVariantStyle({
            backgroundColor: $solidColor
              ? theme.color.mainTransparentSolid
              : theme.color.mainTransparent,
            color: theme.color.text,
            borderColor: theme.color.main,
            borderHoverColor: theme.color.mainDarker,
          });
        case 'danger':
          return buttonVariantStyle({
            backgroundColor: theme.color.alert,
            backgroundHoverColor: theme.color.alertDarker,
          });
        case 'primary':
        default:
          return buttonVariantStyle({
            backgroundColor: theme.color.main,
            backgroundHoverColor: theme.color.mainDarker,
          });
      }
    }}

    ${({ theme }) =>
      disabled &&
      css`
        background-color: ${rgba(theme.color.baseOutline, 0.48)};
        border-color: transparent;
        pointer-events: none;
      `}
  `;
};

interface ButtonTextProps {
  info?: any;
  marginLeft?: boolean;
  marginRight?: boolean;
  size?: ButtonSize;
  disabled?: boolean;
  maxWidthEllipsis?: number;
}

const ButtonText = styled.div<ButtonTextProps>`
  white-space: nowrap;
  display: flex;
  align-items: center;
  width: 100%;

  ${({ maxWidthEllipsis }) => maxWidthEllipsis && ellipsisStyle(maxWidthEllipsis)}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.48;
    `}

  ${({ info }) =>
    info &&
    css`
      flex-direction: column;
    `}

  margin-left: ${({ marginLeft, size }) =>
    marginLeft && (size === 'large' ? rem(8) : size === 'medium' ? rem(6) : rem(5))};
  margin-right: ${({ marginRight, size }) =>
    marginRight && (size === 'large' ? rem(8) : size === 'medium' ? rem(6) : rem(5))};
`;

const ButtonSubtext = styled.div`
  font-size: ${units.fontSize.sm};
`;

const InnerButtonWrapper = styled.div<{ iconOnly: boolean; $innerWrapperNotFullWidth?: boolean }>`
  ${({ iconOnly }) =>
    !iconOnly &&
    css`
      display: flex;
      align-items: center;
      width: 100%;
    `};
  ${({ $innerWrapperNotFullWidth }) =>
    $innerWrapperNotFullWidth &&
    css`
      width: auto;
    `}
`;

const LoadingSpinner = styled(Spinner)`
  margin-right: ${units.margin.sm};
`;

/**
 * Returns a button component.
 */
export const Button = ({
  children,
  size = 'medium',
  variant = 'primary',
  disabled = false,
  type = 'button',
  info,
  link,
  target,
  rel,
  onClick,
  onMouseDown,
  onMouseEnter,
  onKeyDown,
  className,
  noBorder,
  loading,
  buttonRef,
  id,
  dataTestid,
  iconLeft,
  iconRight,
  infoPopup,
  tabIndex,
  ariaLabel,
  download,
  solidColor,
  innerWrapperNotFullWidth,
  maxWidthEllipsis,
}: ButtonProps) => {
  const iconOnly = Boolean(!children);

  const iconSize = useMemo(() => {
    let theSize: IconSizes = 16;
    if (size === 'small') {
      theSize = 14;
    } else if (size === 'medium' && iconOnly) {
      theSize = 16;
    } else if (size === 'large' && iconOnly) {
      theSize = 20;
    } else {
      theSize = 16;
    }
    return theSize;
  }, [iconOnly, size]);

  const overrideIconColor = () => {
    if (variant === 'borderless-blue') {
      return 'main';
    }
    if (variant === 'borderless-red') {
      return 'alert';
    }
  };

  const ButtonIcon = ({ icon }: { icon: ButtonIcon }) => (
    <Icon
      disabled={disabled}
      size={iconSize}
      disableInvert={variant === 'primary' || variant === 'danger'}
      overrideColor={overrideIconColor()}
      {...(typeof icon === 'string' ? { name: icon } : icon)}
    />
  );

  const innerButton = (
    <InnerButtonWrapper
      iconOnly={iconOnly}
      $innerWrapperNotFullWidth={innerWrapperNotFullWidth}
      className={'inner-button'}>
      {loading && <LoadingSpinner diameter={14} />}
      {iconLeft && !loading && <ButtonIcon icon={iconLeft} />}
      <ButtonText
        className={'inner-button-text'}
        info={info}
        disabled={disabled}
        marginLeft={Boolean(iconLeft)}
        marginRight={Boolean(iconRight)}
        maxWidthEllipsis={maxWidthEllipsis}
        size={size}>
        {children}
        {info && <ButtonSubtext>{info}</ButtonSubtext>}
      </ButtonText>
      {iconRight && !loading && <ButtonIcon icon={iconRight} />}
      {infoPopup?.text && (
        <InfoPopup
          text={infoPopup.text}
          position={infoPopup.position || 'left'}
          moreInformationLink={infoPopup.moreInformationLink}
        />
      )}
    </InnerButtonWrapper>
  );

  const commonProps = {
    tabIndex,
    'data-testid': dataTestid,
    className,
    size,
    $solidColor: solidColor,
    variant,
    ref: buttonRef,
    $iconOnly: iconOnly,
    'aria-label': ariaLabel,
    disabled: disabled || loading,
  };

  const commonLinkProps = {
    ...commonProps,
    className: className
      ? disabled
        ? `${className} disabled`
        : className
      : disabled
        ? 'disabled'
        : className,

    target: download ? '_blank' : target,
    download,
    rel,
  };

  const LinkElement = () =>
    isPlainLink(link) ? (
      <PlainLinkButton {...commonLinkProps} href={link.href}>
        {innerButton}
      </PlainLinkButton>
    ) : isReactRouterLink(link) ? (
      <LinkButton {...commonLinkProps} {...link}>
        {innerButton}
      </LinkButton>
    ) : (
      <div />
    );

  return link ? (
    <LinkElement />
  ) : (
    <ButtonElement
      noBorder={noBorder}
      type={type}
      id={id}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseDown={onMouseDown}
      onKeyDown={onKeyDown}
      {...commonProps}>
      {innerButton}
    </ButtonElement>
  );
};
