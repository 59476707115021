import { Button, EmptyStateCard } from '@humanitec/ui-components';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import styled from 'styled-components';

import SharedValueExpandableContent from '@src/components/shared/SharedValuesTable/SharedValueExpandableContent';
import SharedValuesTable from '@src/components/shared/SharedValuesTable/SharedValuesTable';
import OutdatedEnvValuesWarning from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/SharedAppValues/components/OutdatedEnvValuesWarning';
import { getOutdatedValues } from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/SharedAppValues/shared-app-values-utils';
import useApplicationEnvironmentsQuery from '@src/hooks/react-query/environments/queries/useApplicationEnvironmentsQuery';
import useEnvironmentPauseStatusQuery from '@src/hooks/react-query/environments/queries/useEnvironmentPauseStatusQuery';
import useSecretStoresQuery from '@src/hooks/react-query/secret-stores/queries/useSecretStoresQuery';
import useAppValuesCreateMutation from '@src/hooks/react-query/shared-values/mutations/useAppValuesCreateMutation';
import useAppValuesDeleteMutation from '@src/hooks/react-query/shared-values/mutations/useAppValuesDeleteMutation';
import useAppValuesUpdateMutation from '@src/hooks/react-query/shared-values/mutations/useAppValuesUpdateMutation';
import useAppValuesQuery from '@src/hooks/react-query/shared-values/queries/useAppValuesQuery';
import { getEnvValuesQueryOptions } from '@src/hooks/react-query/shared-values/queries/useEnvValuesQuery';
import { getSharedAppValuesHistoryQueryOptions } from '@src/hooks/react-query/shared-values/queries/useSharedAppValuesHistoryQuery';
import { useDecision } from '@src/hooks/useDecision';
import { useRBAC } from '@src/hooks/useRBAC';
import { AppValue } from '@src/models/app-value';
import { Environment } from '@src/models/environment';
import { KeyValue } from '@src/models/general';
import { MatchParams } from '@src/models/routing';
import { placeholderStyle } from '@src/styles/global-styles';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';

const ReferenceText = styled.span`
  font-size: ${units.fontSize.sm};
  margin-bottom: ${units.margin.md};
`;

const ReferencePlaceholder = styled.span`
  ${placeholderStyle()}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const AddValueWrapper = styled.div`
  margin-bottom: ${units.margin.md};
  align-items: center;
`;

export const StyledDash = styled.span`
  color: ${({ theme }) => theme.color.textTranslucent};
`;

export interface OutdatedEnvs {
  [envId: string]: Environment & {
    outdatedValues: string[];
  };
}

export interface OverridenValue extends AppValue {
  envId: string;
}

const AppValues = () => {
  // router
  const { orgId, appId } = useParams<keyof MatchParams>() as MatchParams;

  // react-query
  const { data: appValues = [], isFetched: appValuesFetched } = useAppValuesQuery();
  const { data: applicationEnvironments = [] } = useApplicationEnvironmentsQuery();
  const queryClient = useQueryClient();
  const {
    mutate: createAppValue,
    isPending: appValuesCreationInProgress,
    isError: isCreateError,
    error: createError,
    reset: resetCreateAppValue,
  } = useAppValuesCreateMutation();
  const { mutate: updateAppValue } = useAppValuesUpdateMutation();
  const { mutate: deleteAppValue } = useAppValuesDeleteMutation();
  const { data: envPausedStatusMap } = useEnvironmentPauseStatusQuery({ appId, orgId });
  const { data: secretStores } = useSecretStoresQuery();

  // State
  const [showAddModeFormContent, setShowAddModeFormContent] = useState<boolean | undefined>();
  const [overridenValues, setOverridenValues] = useState<OverridenValue[]>();
  const [isAddingSecretReference, setIsAddingSecretReference] = useState<boolean>();

  // Form
  const createFormMethods = useWalhallForm();

  // i18n
  const { t } = useTranslation();
  const settingsTranslations = t('APP_SETTINGS');

  // Context
  const canEditApp = useRBAC('editApplication');

  // optimizely
  const [secretReferenceDecision] = useDecision('secret_references');

  const isHumanitecPrimarySecretStore = secretStores?.find(
    (secretStore) => secretStore.id === 'humanitec'
  )?.primary;

  const outdatedEnvs = useMemo(() => {
    if (applicationEnvironments.length > 0 && appValues) {
      return applicationEnvironments.reduce((acc: OutdatedEnvs, environment: Environment) => {
        queryClient
          .fetchQuery(getSharedAppValuesHistoryQueryOptions(orgId, appId, environment.id, true))
          .then((response) => {
            const outdatedValues = getOutdatedValues(environment, response.data);
            if (outdatedValues.length > 0 && !envPausedStatusMap?.[environment.id]) {
              acc[environment.id] = {
                ...environment,
                outdatedValues,
              };
            }
          });
        return acc;
      }, {});
    }
  }, [applicationEnvironments, orgId, appId, queryClient, appValues, envPausedStatusMap]);

  useEffect(() => {
    if (applicationEnvironments.length > 0) {
      // returns an array of the overriden values in all environments
      setOverridenValues(
        applicationEnvironments.reduce((acc: OverridenValue[], environment: Environment) => {
          queryClient
            .fetchQuery(getEnvValuesQueryOptions(orgId, appId, environment.id))
            .then((response) => {
              const envOverridenValues: OverridenValue[] = response.data
                .filter((value) => value.source === 'env')
                .map((value) => ({ ...value, envId: environment.id }));
              acc.push(...envOverridenValues);
            });
          return acc;
        }, [])
      );
    }
  }, [applicationEnvironments, orgId, appId, queryClient, envPausedStatusMap]);

  // Methods
  const createNewValue = (formData: KeyValue) => {
    createAppValue({ appValue: formData });
  };

  const deleteValue = (deletedKeyValue: KeyValue) => {
    deleteAppValue({ appValueKey: deletedKeyValue.key });
  };

  const updateValue = (updatedValue: KeyValue) => {
    updateAppValue({ appValue: updatedValue });
  };

  const handleCancelAddMode = () => {
    resetCreateAppValue();
    setShowAddModeFormContent(false);
    setIsAddingSecretReference(false);
  };

  // eslint-disable-next-line
  const placeholderReferenceExample = '${values.KEY}';

  return (
    <>
      {Object.values(outdatedEnvs || {}).length > 0 && (
        <OutdatedEnvValuesWarning
          environments={Object.values(outdatedEnvs || {})}
          orgId={orgId}
          appId={appId}
        />
      )}
      <Wrapper>
        {canEditApp && (
          <>
            <ReferenceText>
              {settingsTranslations.REFERENCE_USING}
              <ReferencePlaceholder>{placeholderReferenceExample}</ReferencePlaceholder>
              {settingsTranslations.WITHIN_CONTAINER_VARS}
            </ReferenceText>
            <FormProvider {...createFormMethods}>
              <AddValueWrapper>
                {!showAddModeFormContent ? (
                  <>
                    <Button
                      className={'mr-md'}
                      variant={'primary'}
                      iconLeft={'plus'}
                      onClick={() => setShowAddModeFormContent(true)}>
                      {settingsTranslations.ADD_VALUE_OR_SECRET}
                    </Button>
                    {secretReferenceDecision.enabled && (
                      <Button
                        onClick={() => {
                          setShowAddModeFormContent(true);
                          setIsAddingSecretReference(true);
                        }}
                        iconLeft={'star'}
                        variant={'secondary'}
                        infoPopup={
                          isHumanitecPrimarySecretStore
                            ? {
                                position: 'right',
                                text: settingsTranslations.ADD_SECRET_REFERENCE_DISABLED_INFO,
                                moreInformationLink:
                                  'https://developer.humanitec.com/platform-orchestrator/security/secret-references',
                              }
                            : undefined
                        }
                        disabled={isHumanitecPrimarySecretStore}>
                        {settingsTranslations.ADD_SECRET_REFERENCE}
                      </Button>
                    )}
                  </>
                ) : (
                  <SharedValueExpandableContent
                    mode={'add'}
                    onSave={createNewValue}
                    onCancel={handleCancelAddMode}
                    isSaving={appValuesCreationInProgress}
                    existingKeys={appValues.map((av) => av.key)}
                    isSecretReference={isAddingSecretReference}
                    secretStores={secretStores}
                    apiError={isCreateError ? createError.response?.data : undefined}
                  />
                )}
              </AddValueWrapper>
            </FormProvider>
          </>
        )}
        {appValuesFetched && appValues.length === 0 ? (
          <EmptyStateCard>{settingsTranslations.NO_VALUES_OR_SECRETS}</EmptyStateCard>
        ) : (
          <>
            <SharedValuesTable
              values={appValues}
              caption={settingsTranslations.APP_VALUES_SECRETS_HEADING}
              outdatedValues={Object.values(outdatedEnvs || {}).flatMap(
                (outdatedEnv) => outdatedEnv.outdatedValues
              )}
              overridenValues={overridenValues}
              outdatedEnvs={outdatedEnvs}
              onUpdate={(newValue) => updateValue(newValue)}
              onDelete={deleteValue}
            />
          </>
        )}
      </Wrapper>
    </>
  );
};

export default AppValues;
