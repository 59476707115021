import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router';

import { useResourcesStore } from '@src/hooks/zustand/useResourcesStore';
import { ApiErrorResponse } from '@src/models/ApiErrorResponse';
import { ResourceAccount, ResourceAccountType } from '@src/models/resource-account';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';
import { convertToNameAndId } from '@src/utilities/string-utility';

import { resourceAccountsQueryKeys } from '../resourceAccountsQueryKeys';

export const useResourceAccountsCreateMutation = (dryRun?: boolean) => {
  // Router hooks
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const queryClient = useQueryClient();

  // Zustand
  const { pushToLastCreatedResourceAccounts } = useResourcesStore();

  const { data, ...queryResult } = useMutation<
    AxiosResponse<ResourceAccount>,
    AxiosError<ApiErrorResponse>,
    {
      credential: any;
      name: string;
      resourceAccountType: ResourceAccountType;
    }
  >({
    mutationFn: ({ name, credential, resourceAccountType }) => {
      const newIdAndName = convertToNameAndId(name);
      const payload: Partial<ResourceAccount> & { credentials?: string } = {
        id: newIdAndName.id,
        credentials: credential,
        name: newIdAndName.name,
        type: resourceAccountType,
      };
      return makeRequest(
        'POST',
        `/orgs/${orgId}/resources/accounts${dryRun ? '?dry_run=true&check_credential=true' : ''}`,
        payload
      );
    },
    onSuccess: (response) => {
      if (!dryRun) {
        pushToLastCreatedResourceAccounts(response.data);
        queryClient.invalidateQueries({
          queryKey: resourceAccountsQueryKeys.listResourceAccounts(orgId),
        });
      }
    },
  });

  return { ...queryResult, data: data?.data };
};
