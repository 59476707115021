import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import type { QueryResponse } from '@src/hooks/react-query/types';
import { DeploymentDelta } from '@src/models/deployment-delta';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { deploymentDeltaQueryKeys } from '../deploymentDeltaQueryKeys';

const useDeploymentDeltaQuery = (
  // Allow passing of custom URL params. Can be removed if WAL-5572 is completed.
  customParams?: {
    orgId?: string;
    appId?: string;
    deltaId?: string;
  }
): QueryResponse<DeploymentDelta> => {
  const {
    orgId: routerOrgId,
    appId: routerAppId,
    deltaId: routerDeltaId,
  } = useParams<keyof MatchParams>() as MatchParams;

  const orgId = customParams?.orgId || routerOrgId;
  const appId = customParams?.appId || routerAppId;
  const deltaId = customParams?.deltaId || routerDeltaId;

  const { data, ...queryResult } = useQuery({
    queryKey: deploymentDeltaQueryKeys.detail(orgId, appId, deltaId),
    queryFn: () =>
      makeRequest<DeploymentDelta>('GET', `/orgs/${orgId}/apps/${appId}/deltas/${deltaId}`),
    // Don't make request of any of the params don't exist
    enabled: Boolean(orgId && appId && deltaId),
  });

  return { ...queryResult, data: data?.data };
};

export default useDeploymentDeltaQuery;
