import { Icon } from '@humanitec/ui-components';
import React from 'react';
import { Link } from 'react-router';

import { generateDeploymentURL, generateWorkloadURL } from '@src/utilities/navigation';

interface WorkloadOrResourceCardTitleProps {
  orgId: string;
  appId: string;
  envId: string;
  deploymentId: string;
  id: string;
  isWorkload?: boolean;
  subtitle?: string;
}

export const WorkloadOrResourceCardTitle = ({
  orgId,
  appId,
  envId,
  deploymentId,
  id,
  isWorkload,
  subtitle,
}: WorkloadOrResourceCardTitleProps) => (
  <div className={'flex-column'}>
    <Link
      className={'flex-centered mb-md'}
      target={'_blank'}
      to={
        isWorkload
          ? generateWorkloadURL(orgId, appId, envId, deploymentId, id)
          : generateDeploymentURL(orgId, appId, envId, deploymentId, 'shared-resources')
      }>
      <span className={'txt-md'}>{id}</span>
      <Icon name={'link'} overrideColor={'main-brighter'} />
    </Link>
    {subtitle && <span className={'txt-translucent'}>{subtitle}</span>}
  </div>
);

export default WorkloadOrResourceCardTitle;
