import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router';

import { ApiErrorResponse } from '@src/models/ApiErrorResponse';
import { ResourceDefinitionVersion } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { resourcesQueryKeys } from '../resourcesQueryKeys';

interface ResourceDefinitionVersionUpdateMutationVariables {
  versionId: string;
  archived?: boolean;
}
const useResourceDefinitionVersionActionMutation = (action: 'archive' | 'promote') => {
  const queryClient = useQueryClient();
  const { orgId, defId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useMutation<
    AxiosResponse<ResourceDefinitionVersion>,
    AxiosError<ApiErrorResponse>,
    ResourceDefinitionVersionUpdateMutationVariables
  >({
    mutationFn: ({ versionId, archived }) =>
      makeRequest(
        'POST',
        `/orgs/${orgId}/resources/defs/${defId}/versions/${versionId}/actions/${action}`,
        action === 'archive'
          ? {
              archived,
            }
          : undefined
      ),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: resourcesQueryKeys.definitionVersions(orgId, defId),
      });
    },
  });
  return { ...queryResult, data: data?.data };
};

export default useResourceDefinitionVersionActionMutation;
