import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { useParams } from 'react-router';

import {
  DeploymentSet,
  DeploymentSetFromAPI,
  DeploymentSetModuleResource,
} from '@src/models/deployment-set';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { useDeploymentSetStore } from '../../zustand/useDeploymentSetStore';
import { deploymentSetQueryKeys } from './deploymentSetQueryKeys';

const useDeploymentSetQuery = (
  // Allow passing of custom URL params. Can be removed if WAL-5572 is completed.
  customParams?: {
    orgId?: string;
    appId?: string;
    setId?: string;
  }
): UseQueryResult<DeploymentSet> => {
  const { orgId: routerOrgId, appId: routerAppId } = useParams<keyof MatchParams>() as MatchParams;

  const orgId = customParams?.orgId || routerOrgId;
  const appId = customParams?.appId || routerAppId;

  // State
  const { currentDeploymentSetId } = useDeploymentSetStore();

  // Use the id that was passed if it exists, otherwise use the one in the store.
  const setIdToUse = customParams?.setId || currentDeploymentSetId;

  return useQuery({
    queryKey: deploymentSetQueryKeys.detail(orgId, appId, setIdToUse),
    queryFn: () =>
      makeRequest<DeploymentSetFromAPI>('GET', `/orgs/${orgId}/apps/${appId}/sets/${setIdToUse}`),
    // Don't make request of any of the params don't exist
    enabled: Boolean(orgId && appId && setIdToUse),
    select: ({ data }): DeploymentSet => {
      const shared: Record<string, DeploymentSetModuleResource> = Object.entries(
        data.shared || {}
      ).reduce((acc, [key, value]) => {
        if (value) {
          return {
            ...acc,
            ...(value ? { [key]: value } : {}),
          };
        }
        return acc;
      }, {});

      return {
        ...data,
        shared,
      };
    },
  });
};

export default useDeploymentSetQuery;
