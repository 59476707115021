import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router';

import { ActiveResource } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { resourcesQueryKeys } from '../resourcesQueryKeys';

interface ResourceDefinitionCreateMutationVariables {
  resource: ActiveResource;
  target_def_version_id: string | null;
}

export const usePinActiveResourceResourceDefinitionVersionMutation = () => {
  const queryClient = useQueryClient();
  const { orgId, defId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useMutation<
    AxiosResponse,
    AxiosError,
    ResourceDefinitionCreateMutationVariables
  >({
    mutationFn: ({ resource, target_def_version_id }) =>
      makeRequest(
        'POST',
        `/orgs/${orgId}/apps/${resource.app_id}/envs/${resource.env_id}/resources/${resource.type}.${resource.class}/${resource.res_id}/actions/pin`,
        {
          target_def_version_id,
        }
      ),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: resourcesQueryKeys.activeResources(orgId, defId),
      });
    },
  });
  return { ...queryResult, data: data?.data };
};
