import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import type { QueryResponse } from '@src/hooks/react-query/types';
import { Environment } from '@src/models/environment';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { environmentQueryKeys } from '../environmentQueryKeys';

const useApplicationEnvironmentsQuery = (customParams?: {
  orgId?: string;
  appId?: string;
}): QueryResponse<Environment[]> => {
  const { orgId, appId: routerAppId } = useParams<keyof MatchParams>() as MatchParams;

  const appId = customParams?.appId || routerAppId;

  const { data, ...queryResult } = useQuery({
    queryKey: environmentQueryKeys.applicationEnvironments(orgId, appId),
    queryFn: () => makeRequest<Environment[]>('GET', `/orgs/${orgId}/apps/${appId}/envs`),
    enabled: Boolean(orgId && appId),
  });

  return { ...queryResult, data: data?.data };
};

export default useApplicationEnvironmentsQuery;
