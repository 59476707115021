import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { Pipeline, PipelineVersion } from '@src/models/pipeline';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { pipelinesQueryKeys } from '../pipelinesQueryKeys';

interface UsePipelineDetailsQuery {
  id?: string;
  versionId?: string;
  appId?: string;
}

const usePipelineDetailsQuery = ({
  id,
  versionId,
  appId,
}: UsePipelineDetailsQuery | undefined = {}) => {
  const { orgId, appId: routerAppId } = useParams<keyof MatchParams>() as MatchParams;

  const applicationId = appId || routerAppId;
  const { data, ...queryResult } = useQuery({
    queryKey: pipelinesQueryKeys.details(orgId, appId, id, versionId),
    queryFn: () => {
      return makeRequest<Pipeline | PipelineVersion>(
        'GET',
        `/orgs/${orgId}/apps/${appId}/pipelines/${id}${versionId ? `?version=${versionId}` : ''}`
      );
    },
    // Don't make request if any of the params don't exist
    enabled: Boolean(orgId && applicationId && id && id !== 'create'),
  });

  return { ...queryResult, data: data?.data };
};

export default usePipelineDetailsQuery;
