import { Button } from '@humanitec/ui-components';
import { useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate, useParams } from 'react-router';
import styled from 'styled-components';

import { EditResourceDefinitionForm } from '@src/containers/Orgs/Resources/components/ResourceDefinitionForm/EditResourceDefinitionForm';
import { useResourceDefinitionVersionQuery } from '@src/hooks/react-query/resources/queries/useResourceDefinitionVersionQuery';
import { useResourceDefinitionVersionsQuery } from '@src/hooks/react-query/resources/queries/useResourceDefinitionVersions';
import { useDecision } from '@src/hooks/useDecision';
import { useRBAC } from '@src/hooks/useRBAC';
import { MatchParams } from '@src/models/routing';
import { PageContainer } from '@src/styles/layout/PageContainer';
import { useWalhallForm } from '@src/utilities/form';
import { generateResourceDefinitionUrl } from '@src/utilities/navigation';

import { ResourceDefinitionBasicInfo } from '../../../ResourceDefinitionBasicInfo';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled(PageContainer)`
  display: flex;
  flex-direction: column;
`;

export const ResourceDefinitionVersion = () => {
  // State
  const [editMode, setEditMode] = useState<boolean>(false);

  // Router hooks
  const { orgId, defId } = useParams<keyof MatchParams>() as MatchParams;

  // Optimizely
  const [resourceDefinitionVersionDecision] = useDecision('resource-definition-version');

  // RBAC
  const canEditResources = useRBAC('manageResources');

  // React Query
  const { data: resourceDefinitionVersions, isSuccess: isResourceDefininitionVersionsLoaded } =
    useResourceDefinitionVersionsQuery();
  const { data: resourceDefinitionVersion } = useResourceDefinitionVersionQuery();
  const latestResourceDefinitionVersion = resourceDefinitionVersions?.[0];

  const isLatest =
    latestResourceDefinitionVersion &&
    resourceDefinitionVersion &&
    latestResourceDefinitionVersion?.id === resourceDefinitionVersion?.id;

  // Form
  const formMethods = useWalhallForm();

  // i18n
  const { t } = useTranslation();
  const versionsTranslations = t('ACCOUNT_SETTINGS').RESOURCES.RESOURCE_VERSIONS;

  if (!resourceDefinitionVersionDecision.enabled) {
    return <Navigate to={generateResourceDefinitionUrl(orgId, defId)} />;
  }

  return (
    <Container>
      <HeaderWrapper>
        <h2 className={'my-lg flex'}>
          {editMode && versionsTranslations.EDIT_CONFIGURATION_FOR}{' '}
          {resourceDefinitionVersion?.def_id}
        </h2>
        {isResourceDefininitionVersionsLoaded && isLatest && !editMode && canEditResources && (
          <Button size={'small'} iconLeft={'edit'} onClick={() => setEditMode(true)}>
            {versionsTranslations.EDIT_CONFIGURATION}
          </Button>
          // Actions to be implemented at a later stage.
          // : (
          //   !isLatest &&
          //   resourceDefinitionVersion && (
          //     <ResourceDefinitionVersionActions
          //       resourceDefinitionVersion={resourceDefinitionVersion}
          //       showDiffButton
          //       showArchiveButton
          //       showActivateButton
          //       isDefaultResourceDefinition={resourceDefinition?.is_default}
          //     />
          //   )
          // )
        )}
      </HeaderWrapper>
      <ResourceDefinitionBasicInfo />
      <FormProvider {...formMethods}>
        <EditResourceDefinitionForm editState={[editMode, setEditMode]} />
      </FormProvider>
    </Container>
  );
};
