import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router';

import { ArtefactVersion } from '@src/models/artefact';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { artefactQueryKeys } from '../artefactsQueryKeys';

const useArtefactVersionArchiveMutation = (artefactId: string) => {
  const queryClient = useQueryClient();
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useMutation<AxiosResponse, AxiosError, ArtefactVersion>({
    mutationFn: (version) =>
      makeRequest('PATCH', `/orgs/${orgId}/artefacts/${artefactId}/versions/${version?.id}`, {
        archived: !version.archived,
      }),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: artefactQueryKeys.listArtefactVersions(orgId, artefactId),
      });
    },
  });
  return { ...queryResult, data: data?.data };
};

export default useArtefactVersionArchiveMutation;
