import { Accordion } from '@humanitec/ui-components';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router';

import { ResourceDependency } from '@src/components/shared/AddResourceDependency/AddResourceDependency';
import ResourceCardDetails from '@src/components/shared/ResourceDependencyList/components/ResourceCardDetails/ResourceCardDetails';
import ResourceCardHeaderContent from '@src/components/shared/ResourceDependencyList/components/ResourceCardHeaderContent/ResourceCardHeaderContent';
import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import useEnvironmentQuery from '@src/hooks/react-query/environments/queries/useEnvironmentQuery';
import useResourceDefinitionsQuery from '@src/hooks/react-query/resources/queries/useResourceDefinitionsQuery';
import { ActiveResource } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import { generateDeploymentURL, generateWorkloadURL } from '@src/utilities/navigation';

export interface ResourceCardItem {
  resourceDependency?: ResourceDependency & { path: string };
  activeResource?: ActiveResource;
}

interface ResourceDependencyListProps {
  resourceCardItems: ResourceCardItem[];
  scope: 'private' | 'shared';
  onRunningDeployment: boolean;
}
const ResourceDependencyList = ({
  resourceCardItems,
  scope,
  onRunningDeployment,
}: ResourceDependencyListProps) => {
  // i18n
  const { t } = useTranslation();
  const sectionsTranslations = t('VIEW_MODULE').SECTIONS;

  const { draftModeActive } = useDeploymentOrDeltaContext();
  const { data: environment } = useEnvironmentQuery();
  const { data: resourceDefinitions } = useResourceDefinitionsQuery();

  const { orgId, appId, envId, moduleId } = useParams<keyof MatchParams>() as MatchParams;

  return (
    <div className={'flex-column mb-md'}>
      {resourceCardItems?.length === 0 ? (
        onRunningDeployment ? (
          <span className={'txt-md'}>{sectionsTranslations.NO_RESOURCE_DEPENDENCIES_DEFINED}</span>
        ) : (
          environment?.last_deploy?.id && (
            <div className={'flex-row'}>
              <Trans defaults={sectionsTranslations.NO_RESOURCE_DEPENDENCIES_PAST_DEPLOYMENTS}>
                <Link
                  className={'txt-base'}
                  to={
                    scope === 'private'
                      ? generateWorkloadURL(
                          orgId,
                          appId,
                          envId,
                          environment?.last_deploy?.id,
                          moduleId
                        )
                      : generateDeploymentURL(
                          orgId,
                          appId,
                          envId,
                          environment?.last_deploy.id,
                          'shared-resources'
                        )
                  }
                />
              </Trans>
            </div>
          )
        )
      ) : (
        <Accordion
          cardTestId={'resource-dependency-card'}
          items={resourceCardItems.map((res) => ({
            id: res.activeResource?.def_id || res.resourceDependency?.id || '',
            headerContent: (
              <ResourceCardHeaderContent
                scope={scope}
                resourceId={
                  scope === 'private'
                    ? res.resourceDependency?.id
                    : res.activeResource
                      ? res.activeResource?.res_id || res.activeResource?.def_id
                      : res.resourceDependency?.id
                }
                resourceType={
                  scope === 'private'
                    ? res.resourceDependency?.type
                    : res.activeResource?.type || res.resourceDependency?.type
                }
                resourceClass={
                  scope === 'private'
                    ? res.resourceDependency?.class
                    : res.activeResource?.class || res.resourceDependency?.class
                }
                draftModeActive={draftModeActive}
                resourceDependency={res.resourceDependency}
                iconSize={30}
              />
            ),
            content: res.activeResource && resourceDefinitions && (
              <ResourceCardDetails
                activeResource={res.activeResource}
                resourceDefinitions={resourceDefinitions}
                showResDefLink
              />
            ),
            cardStyle: scope === 'shared' ? 'default' : 'base',
            disableExpansion: draftModeActive || !res.activeResource,
          }))}
        />
      )}
    </div>
  );
};

export default ResourceDependencyList;
