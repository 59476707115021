import { useQueries, UseQueryResult } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { useParams } from 'react-router';

import { environmentQueryKeys } from '@src/hooks/react-query/environments/environmentQueryKeys';
import { DeploymentObject } from '@src/models/deployment-object';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

export const envDeploymentsQueryOptions = (
  orgId?: string,
  appId?: string,
  envId?: string,
  piplineRunFilter?: {
    pipelineId: string;
    pipelineRunId: string;
  }
) => ({
  queryKey: environmentQueryKeys.listEnvironmentDeployments(orgId, appId, envId, piplineRunFilter),
  queryFn: () =>
    makeRequest<DeploymentObject[]>(
      'GET',
      `/orgs/${orgId}/apps/${appId}/envs/${envId}/deploys?pipelineRunId=${piplineRunFilter?.pipelineId},${piplineRunFilter?.pipelineRunId}`
    ),
  enabled: Boolean(orgId && envId && appId && piplineRunFilter),
});

const transformQueryResponseData = (
  queries: UseQueryResult<AxiosResponse<DeploymentObject[], any>, unknown>[]
) => {
  return queries.reduce((acc: DeploymentObject[], query) => {
    return [...acc, ...(query.data?.data || [])];
  }, []);
};

const usePipelineRunDeploymentsQuery = (
  env_ids: string[],
  piplineRunFilter?: {
    pipelineId: string;
    pipelineRunId: string;
  }
) => {
  const { orgId, appId } = useParams<keyof MatchParams>() as MatchParams;

  const queryResults = useQueries({
    queries: env_ids.map((envId) =>
      envDeploymentsQueryOptions(orgId, appId, envId, piplineRunFilter)
    ),
  });
  return { queryResults, data: transformQueryResponseData(queryResults) };
};

export default usePipelineRunDeploymentsQuery;
