import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { buildQueryString } from '@src/hooks/react-query/query-utils';
import { QueryResponse } from '@src/hooks/react-query/types';
import { ResourceDefinition, ResourceTypes } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { resourcesQueryKeys } from '../resourcesQueryKeys';

export interface ResourceDefinitionsQueryFilters {
  app?: string;
  env?: string;
  env_type?: string;
  res_type?: ResourceTypes;
}

const useResourceDefinitionsQuery = (
  filters?: ResourceDefinitionsQueryFilters
): QueryResponse<ResourceDefinition[]> => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery({
    queryKey: resourcesQueryKeys.listDefinitions(orgId, filters),
    queryFn: () =>
      makeRequest<ResourceDefinition[]>(
        'GET',
        `/orgs/${orgId}/resources/defs${buildQueryString(filters)}`
      ),
    enabled: Boolean(orgId),
  });

  return { ...queryResult, data: data?.data };
};

export default useResourceDefinitionsQuery;
