import { Placement } from '@floating-ui/react';
import { rem } from 'polished';
import React, {
  KeyboardEvent,
  MouseEvent,
  MutableRefObject,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { LinkProps } from 'react-router';
import styled, { css } from 'styled-components';

import { NonStyledLink } from '@src/components/shared/NonStyledLink';
import { usePrevious } from '@src/hooks/usePrevious';
import { ellipsisStyle } from '@src/styles/mixins';
import { units } from '@src/styles/variables';
import { KeyBindings } from '@src/types/key-bindings';
import { PartialBy } from '@src/types/partialBy';

import { Button, ButtonSize } from '../../base/Button/Button';
import { Icon } from '../../base/Icon/Icon';
import { WalPanel, WalPanelProps } from '../../base/Panel/Panel';

export interface FixedMenuRow {
  component: ReactNode;
  link?: Pick<LinkProps, 'to' | 'state'>;
  onClick?: (e: MouseEvent | KeyboardEvent) => void;
}

const isFixedRowSingle = (v: FixedMenuRow | FixedMenuRow[]): v is FixedMenuRow =>
  Boolean((v as FixedMenuRow)?.component);

export type ObjectTypeProps =
  | 'application'
  | 'webhook'
  | 'environment'
  | 'deployment'
  | 'draft'
  | 'workload'
  | 'container'
  | 'resource'
  | 'ingress'
  | 'account'
  | 'environment type'
  | 'image'
  | 'user'
  | 'registry'
  | 'automation rule'
  | 'cronjob'
  | 'service port'
  | 'resource definition'
  | 'variables'
  | 'files'
  | 'namespace'
  | 'service-accounts'
  | 'volume-mounts'
  | 'app member';
export interface WalMenuProps<T>
  extends Omit<PartialBy<WalPanelProps, 'openState'>, 'placement' | 'floatingElementOffset'> {
  /** List of menu items */
  items: MenuItem<T>[];
  /** Optional highlighted item */
  selected?: MenuItem<T> | string;
  /** Click handler called when an item is selected */
  onItemClick?: (item: T, e: MouseEvent | KeyboardEvent) => void;
  /** On Menu click handler */
  onClick?: (event: MouseEvent) => void;
  /** onMouseEnter handler */
  onMouseEnter?: (event: MouseEvent) => void;
  /** onMouseLeave handler */
  onMouseLeave?: (event: MouseEvent) => void;
  /** max height for the menu if it overflows the scrolling will be activated */
  maxHeight?: number;
  /** react reference from parent component */
  menuRef?: MutableRefObject<HTMLElement | null>;
  /** callback function to be called when the keyboard index is outside the menu */
  keyboardIndexOutside?: () => void;
  /** component that is fixed to the top of the dropdown menu */
  fixedTopRowComponent?: ReactNode;
  /** component that is fixed to the bottom of the dropdown menu */
  fixedBottomRow?: FixedMenuRow | FixedMenuRow[];
  /** if the menu should be scrollable on overflow */
  overflowScrollable?: boolean;
  disableCloseOnClick?: boolean;
  maxWidth?: number;
  panel?: {
    // where the panel should be shown
    placement?: Placement;
    // the offset is the spacement between the toggle element and the panel in px
    offset?: number;
  };
  /** spacing between floating element and trigger in px **/
  id?: string;
  /** Displays text when there are no items */
  noItemsText?: string;
  mode?: 'dropdown' | 'combobox';
  /** Displays default text even if option is selected */
  alwaysShowDefaultText?: boolean;
  /** If content should remain when items === 0 */
  alwaysMaintainPanelContent?: boolean;
  /** ref of the parent menu item that toggles the submenu */
  submenuToggleItemRef?: MutableRefObject<HTMLElement | null>;
  /** a function that is triggered when the menu is closed */
  onMenuClosed?: () => void;
  standardToggle?: {
    type: 'dots';
    objectName: string;
    objectType: ObjectTypeProps;
    size?: ButtonSize;
    preventDefault?: boolean;
    noBorder?: 'left' | 'right';
  };
  /* whether it should invert the ellipsis of menu item labels */
  invertEllipsis?: boolean;
  /* initialFocus prop of FloatingFocusManager */
  floatingFocusManagerInitialFocus?: number;
}

export interface MenuItem<T> {
  /** The label to display */
  label: string;
  /** The value of the item */
  value: T;
  /** optional custom aria label for item. Default will come from label prop */
  ariaLabel?: string;
  /** Pass true to create a non clickable item */
  separatorItem?: boolean;
  overflowHidden?: boolean;
  description?: string;
  /** use react component instead of label */
  component?: ReactNode;
  /** link "to" property if the item should navigate */
  link?: Pick<LinkProps, 'to' | 'state'>;
  /** weather the item is disabled */
  disabled?: boolean;
  /** hides from the list of options, but displays label if it's the selected option */
  hideFromList?: boolean;
  /** Optional testing class */
  testClass?: string;
  /** Change the tab index of an item. Default 0 */
  tabIndex?: number;
  marginBottom?: 'xl';
  dataTestId?: string;
  /** react ref of the menu item */
  ref?: MutableRefObject<HTMLDivElement | null>;
}

const MenuWrapper = styled.div<{ fullWidth?: boolean }>`
  position: relative;
  display: flex;
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

export const Menu = styled.ul`
  list-style: none;
  z-index: 12;
  margin: 0;
  padding: 0;
  position: relative;
`;

const MenuInner = styled.div<{ overflowScrollable: boolean; maxHeight?: number }>`
  ${({ overflowScrollable }) =>
    overflowScrollable &&
    css`
      overflow: auto;
    `}
  ${({ maxHeight }) =>
    maxHeight &&
    css`
      max-height: ${rem(maxHeight)};
      overflow: auto;
    `}
`;

const menuItemComponentStyles = css`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
`;

export const MenuItemComponent = styled.li<{ $disabled?: boolean }>`
  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: default;
    `}
  ${menuItemComponentStyles}
`;

const MenuItemLinkComponent = styled(NonStyledLink)<{ $maxWidth?: number }>`
  font-size: ${units.fontSize.sm};
  ${menuItemComponentStyles}
`;

const MenuFixedRow = styled.div`
  padding: ${rem(0)} ${rem(15)};
`;

const menuFixedBottomRowStyles = css`
  display: block;
  padding: ${rem(11)} ${rem(15)};
  cursor: pointer;
  color: ${({ theme }) => theme.color.mainBrighter} !important;
  font-size: ${units.fontSize.sm};
  &:hover {
    background-color: ${({ theme }) => theme.color.mainTransparent};
  }
  &:link {
    color: ${({ theme }) => theme.color.mainBrighter};
  }
`;

const MenuFixedBottomRow = styled.div`
  color: ${({ theme }) => theme.color.mainBrighter};
  ${menuFixedBottomRowStyles}
`;

const MenuFixedBottomRowLink = styled(NonStyledLink)`
  ${menuFixedBottomRowStyles}
`;

const MenuItemSeparator = styled.div`
  display: flex;
  font-size: ${units.fontSize.sm};
  color: ${({ theme }) => theme.color.textTranslucent};
  padding: ${rem(0)} ${rem(15)};
  justify-content: space-between;
  line-height: ${rem(20)};
`;

const arrowWrapperStyles = css`
  display: flex;
  justify-content: center;
  position: absolute;
  width: 100%;
  background-color: ${({ theme }) => theme.color.baseBrightest};
  svg {
    width: ${rem(16)};
    height: ${rem(16)};
    path {
      fill: ${({ theme }) => theme.color.textTranslucent};
    }
  }
`;

const MenuArrowUpWrapper = styled.div`
  ${arrowWrapperStyles};
  top: 0;
`;

const MenuArrowDownWrapper = styled.div`
  ${arrowWrapperStyles};
  bottom: 0;
`;

const DescriptionText = styled.span`
  white-space: break-spaces;
  line-break: anywhere;
`;

const TopArrowIcon = styled(Icon)`
  transform: rotate(180deg);
`;

interface MenuItemLabelProps {
  selected?: boolean;
  $disabled?: boolean;
  $maxWidth?: number;
  $overflowHidden?: boolean;
  marginBottom?: 'xl';
  disableHover?: boolean;
  disableSelectedColor?: boolean;
  invertEllipsis?: boolean;
}

interface MenuItemWithIndex extends MenuItem<any> {
  itemIndex?: number;
}

export const MenuItemLabel = styled.div<MenuItemLabelProps>`
  flex: 1;
  display: block;
  flex-direction: column;
  padding: ${rem(11)} ${rem(15)};
  font-size: ${units.fontSize.sm};
  list-style: none;
  background-color: transparent;
  border: none;
  ${({ $maxWidth, invertEllipsis }) => $maxWidth && ellipsisStyle($maxWidth, invertEllipsis)}
  ${({ $overflowHidden }) =>
    $overflowHidden &&
    css`
      overflow: hidden;
    `}
  ${({ marginBottom }) =>
    marginBottom &&
    css`
      margin-bottom: ${units.margin[marginBottom]};
    `}
  ${({ $maxWidth }) =>
    $maxWidth &&
    css`
      max-width: ${rem($maxWidth)};
      overflow: hidden;
    `}
  color: ${({ theme, selected, disableSelectedColor }) =>
    selected && !disableSelectedColor ? theme.color.mainBrighter : theme.color.text};
  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: default;
      * {
        color: ${({ theme }) => theme.color.textTranslucent} !important;
      }
    `}

  ${({ disableHover, $disabled }) =>
    !disableHover &&
    !$disabled &&
    css`
      &:hover,
      &:focus-visible,
      &:focus-within,
      &:active {
        background-color: ${({ theme }) => theme.color.mainTransparent};
      }
      cursor: pointer;
    `}
`;

const MenuNoItems = styled(MenuItemLabel)`
  color: ${({ theme }) => theme.color.textTranslucent};
  cursor: default;
`;

// react-testing-library doesn't support layout attrs. So creating these functions to test https://github.com/testing-library/react-testing-library/issues/353.
export const shouldShowBottomArrow = (element: HTMLElement) =>
  element.scrollHeight - element.scrollTop > element.clientHeight;

export const shouldShowTopArrow = (element: HTMLElement) => element.scrollTop !== 0;

interface FixedBottomRowProps {
  row: FixedMenuRow;
}

/**
 * Returns a menu component.
 */
export const WalMenu = ({
  toggle,
  onMenuClosed,
  openState,
  items = [],
  selected,
  onItemClick,
  maxHeight,
  className,
  menuRef,
  panelSize,
  keyboardIndexOutside,
  fixedTopRowComponent,
  fixedBottomRow,
  disabled,
  onClick,
  onMouseEnter,
  onMouseLeave,
  fullWidth,
  toggleOnHover,
  relativeTo,
  overflowScrollable = false,
  disableCloseOnClick,
  maxWidth,
  id,
  noItemsText,
  disableToggleOnClick,
  popOutOfOverflow,
  toggleElementWrapperTabIndex,
  toggleElementTabIndex,
  disableToggleElementTabIndexAndDefaults,
  mode,
  alwaysShowDefaultText,
  alwaysMaintainPanelContent,
  standardToggle,
  panel,
  submenuToggleItemRef,
  disableFloatingUiResize,
  disableFloatingUiShift,
  invertEllipsis,
  ariaLabel,
  maxSize,
  disableNoWrap,
  floatingFocusManagerInitialFocus,
}: WalMenuProps<any>) => {
  // Component state
  const state = useState(false);
  const [open, setOpen] = openState || state;
  const [showBottomArrow, setShowBottomArrow] = useState(false);
  const [showTopArrow, setShowTopArrow] = useState(false);

  const [resizeObserverInitialized, setResizeObserverInitialized] = useState(false);

  const ref = useRef<HTMLDivElement | null>(null);
  const firstItem = useRef<HTMLLIElement | null>(null);
  const firstLinkItem = useRef<HTMLAnchorElement | null>(null);
  const menuBottomRowItemRef = useRef<HTMLDivElement | null>(null);
  const menuBottomRowLinkItemRef = useRef<HTMLAnchorElement | null>(null);
  const [shouldFocusOnFirstItem, setShouldFocusOnFirstItem] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState<HTMLDivElement | null>(null);
  const previousOpenState = usePrevious(open);

  // i18n
  const { t } = useTranslation();
  const menuAria = t('UI.MENU_ARIA', {
    replace: {
      objectName: standardToggle?.objectName ? `${standardToggle?.objectName} ` : '',
      objectType: standardToggle?.objectType ? `${standardToggle?.objectType} ` : '',
    },
  });

  const filteredItems = useMemo(() => {
    let index = 0;
    // add itemIndex only on items that are not seperator items
    return items
      .filter((item) => (item.hideFromList === undefined ? true : !item.hideFromList))
      .map((item) => {
        if (!item.separatorItem) {
          index++;
        }
        return {
          ...item,
          itemIndex: !item.separatorItem ? index - 1 : undefined,
        };
      });
  }, [items]);

  useEffect(() => {
    if (open && shouldFocusOnFirstItem) {
      firstItem.current?.focus();
      firstLinkItem.current?.focus();
      setShouldFocusOnFirstItem(false);
    }
  }, [open, shouldFocusOnFirstItem]);

  useEffect(() => {
    if (open) {
      toggleArrows();
    }
    if (!open && previousOpenState && onMenuClosed) {
      onMenuClosed();
    }
  }, [open, onMenuClosed, previousOpenState]);

  /**
   * Listen to element resizing, and show or hide arrows accordingly.
   */
  useEffect(() => {
    if (ref.current && !resizeObserverInitialized) {
      const resizeObserver = new ResizeObserver(() => {
        toggleArrows();
      });
      resizeObserver.observe(ref.current);
      setResizeObserverInitialized(true);
    }
  }, [items.length, resizeObserverInitialized]);

  const toggleArrows = () => {
    if (!ref.current) return;

    // if the user is not scrolled to the bottom
    setShowBottomArrow(shouldShowBottomArrow(ref.current));

    // if the user is not scrolled to the top
    setShowTopArrow(shouldShowTopArrow(ref.current));
  };

  /**
   * Updates the item click and updates the selected state.
   *
   * @param item the clicked item,
   */
  const handleItemClick = (item: MenuItem<any>, e: MouseEvent | KeyboardEvent) => {
    e.stopPropagation();
    if (onItemClick) {
      onItemClick(item, e);
    }
    if (!disableCloseOnClick) {
      setOpen(false);
    }
  };

  const handleItemMouseEnter = (e: MouseEvent) => {
    // if the menu item is submenu toggle
    setActiveMenuItem(e.currentTarget as HTMLDivElement);
  };

  const handleClickFixedBottomRow = (e: MouseEvent | KeyboardEvent, row: FixedMenuRow) => {
    setOpen(false);

    row?.onClick?.(e);
  };

  const onKeyDown = (event: KeyboardEvent, item: MenuItemWithIndex) => {
    event.stopPropagation();
    const menuItems = menuRef
      ? menuRef?.current?.querySelectorAll('.menu-item')
      : ref?.current?.querySelectorAll('.menu-item');
    const subMenuItemToggle = item.ref?.current?.querySelector('.submenu-toggle');
    if (menuItems && item.itemIndex !== undefined) {
      // TODO: Skip elements with negative or no tabindex.
      if (event.keyCode === KeyBindings.ARROWDOWN) {
        event.preventDefault();
        if (item.itemIndex + 1 === menuItems.length && fixedBottomRow) {
          menuBottomRowLinkItemRef.current?.focus();
          menuBottomRowItemRef.current?.focus();
        } else if (item.itemIndex + 1 < filteredItems.length) {
          (menuItems[item.itemIndex + 1] as HTMLElement)?.focus();
        }
      }
      if (event.keyCode === KeyBindings.ARROWUP && item.itemIndex > 0) {
        event.preventDefault();
        (menuItems[item.itemIndex - 1] as HTMLElement)?.focus();
      }
    }
    if (event.keyCode === KeyBindings.ARROWUP && item.itemIndex === 0) {
      // if the user clicks up arrow outside outside of the dropdown
      setOpen(false);
      if (keyboardIndexOutside) {
        keyboardIndexOutside();
      }
    }
    if (
      event.keyCode === KeyBindings.ESCAPE ||
      (event.keyCode === KeyBindings.ARROWLEFT && submenuToggleItemRef)
    ) {
      // escape
      setOpen(false);
      if (submenuToggleItemRef) {
        (submenuToggleItemRef.current?.firstChild as HTMLElement)?.focus();
      }
      // subMenuToggleId?.current?.focus();
      if (keyboardIndexOutside) {
        keyboardIndexOutside();
      }
    }
    if (
      (event.keyCode === KeyBindings.ENTER || event.keyCode === KeyBindings.SPACE) &&
      !subMenuItemToggle &&
      !item.link
    ) {
      event.preventDefault();
      // enter
      handleItemClick(item, event);
    }
    // if the item is a submenu toggle
    if (
      subMenuItemToggle &&
      (event.keyCode === KeyBindings.ENTER ||
        event.keyCode === KeyBindings.SPACE ||
        event.keyCode === KeyBindings.ARROWRIGHT)
    ) {
      (subMenuItemToggle as HTMLElement).click();
    }
  };

  const itemElements =
    noItemsText && filteredItems.length <= 0 ? (
      <MenuNoItems disableHover>{noItemsText}</MenuNoItems>
    ) : (
      filteredItems.map((item, index) => {
        if (item.separatorItem) {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <MenuItemComponent data-testid={item.dataTestId || 'menu-item'} key={index}>
              <MenuItemSeparator>{item.component ? item.component : item.label}</MenuItemSeparator>
            </MenuItemComponent>
          );
        } else {
          const menuItemLabel = (
            <MenuItemLabel
              $disabled={item.disabled}
              selected={item === selected}
              title={item.label}
              $maxWidth={maxWidth}
              $overflowHidden={item.overflowHidden}
              marginBottom={item.marginBottom}
              disableSelectedColor={alwaysShowDefaultText}
              invertEllipsis={invertEllipsis}>
              {item.component ? (
                item.component
              ) : item.description ? (
                <div className={'flex-column'}>
                  {item.label}
                  {item.description && (
                    <DescriptionText className={'txt-translucent txt-sm'}>
                      {item.description}
                    </DescriptionText>
                  )}
                </div>
              ) : (
                item.label
              )}
            </MenuItemLabel>
          );

          const menuItemComponent = item.link ? (
            <MenuItemLinkComponent
              aria-label={item.ariaLabel || item.label}
              role={mode === 'dropdown' ? 'option' : undefined}
              onKeyDown={(event: KeyboardEvent) => onKeyDown(event, item)}
              tabIndex={item.tabIndex || 0}
              ref={item.itemIndex === 0 ? firstLinkItem : undefined}
              data-testid={item.dataTestId || 'menu-item'}
              className={'menu-item'}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onClick={(e) => (!item.disabled ? handleItemClick(item, e) : undefined)}
              {...item.link}>
              {menuItemLabel}
            </MenuItemLinkComponent>
          ) : (
            <MenuItemComponent
              aria-label={item.ariaLabel || item.label}
              $disabled={item.disabled}
              role={mode === 'dropdown' ? 'option' : undefined}
              onKeyDown={(event: KeyboardEvent) => onKeyDown(event, item)}
              tabIndex={item.tabIndex || 0}
              ref={item.itemIndex === 0 ? firstItem : undefined}
              data-testid={item.dataTestId || 'menu-item'}
              className={'menu-item'}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onClick={(e) => (!item.disabled ? handleItemClick(item, e) : undefined)}>
              {menuItemLabel}
            </MenuItemComponent>
          );

          return (
            <div
              ref={item.ref}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onMouseEnter={handleItemMouseEnter}
              className={activeMenuItem === item.ref?.current ? 'active-menu-item' : undefined}>
              {menuItemComponent}
            </div>
          );
        }
      })
    );

  const onToggleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      (event.keyCode === KeyBindings.ENTER ||
        event.keyCode === KeyBindings.SPACE ||
        event.keyCode === KeyBindings.ARROWDOWN) &&
      !open
    ) {
      setShouldFocusOnFirstItem(true);
      setOpen(true);
    }
  };

  const onToggleClick = () => {
    setShouldFocusOnFirstItem(true);
    setOpen(true);
  };

  const handleBottomRowKeyDown = (event: KeyboardEvent, row: FixedMenuRow) => {
    event.stopPropagation();
    const menuItems = menuRef
      ? menuRef?.current?.querySelectorAll('.menu-item')
      : ref?.current?.querySelectorAll('.menu-item');
    if (event.keyCode === KeyBindings.ENTER && !row?.link) {
      handleClickFixedBottomRow(event, row);
    }
    if (event.keyCode === KeyBindings.ARROWUP && menuItems) {
      event.preventDefault();
      (menuItems[menuItems.length - 1] as HTMLElement)?.focus();
    }
    if (
      event.keyCode === KeyBindings.ESCAPE ||
      (event.keyCode === KeyBindings.ARROWLEFT && submenuToggleItemRef)
    ) {
      // escape
      setOpen(true);
      if (submenuToggleItemRef) {
        (submenuToggleItemRef.current?.firstChild as HTMLElement)?.focus();
      }
    }
  };

  const FixedBottomRow = ({ row }: FixedBottomRowProps) => {
    return row.link ? (
      <MenuFixedBottomRowLink
        ref={menuBottomRowLinkItemRef}
        onClick={(e) => handleClickFixedBottomRow(e, row)}
        onKeyDown={(e) => handleBottomRowKeyDown(e, row)}
        {...row.link}>
        {row.component}
      </MenuFixedBottomRowLink>
    ) : (
      <MenuFixedBottomRow
        tabIndex={0}
        onClick={(e) => handleClickFixedBottomRow(e, row)}
        onKeyDown={(e) => handleBottomRowKeyDown(e, row)}
        ref={menuBottomRowItemRef}>
        {row.component}
      </MenuFixedBottomRow>
    );
  };

  return (
    <MenuWrapper
      className={className}
      onClick={onClick}
      fullWidth={fullWidth}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      <WalPanel
        fullWidth={fullWidth}
        disableToggleElementTabIndexAndDefaults={disableToggleElementTabIndexAndDefaults}
        toggleElementWrapperTabIndex={toggleElementWrapperTabIndex}
        toggleElementTabIndex={toggleElementTabIndex}
        openState={[open, setOpen]}
        className={className}
        disableFloatingUiResize={disableFloatingUiResize}
        disableFloatingUiShift={disableFloatingUiShift}
        floatingElementOffset={panel?.offset}
        floatingFocusManagerInitialFocus={floatingFocusManagerInitialFocus}
        placement={panel?.placement}
        maxSize={maxSize}
        disableNoWrap={disableNoWrap}
        role={mode === 'combobox' ? 'combobox' : 'listbox'}
        toggle={
          standardToggle && standardToggle.type === 'dots' ? (
            <Button
              variant={'secondary'}
              size={standardToggle.size || 'small'}
              noBorder={standardToggle.noBorder}
              ariaLabel={menuAria}
              iconLeft={'three-dots'}
              onClick={(e) => standardToggle.preventDefault && e.preventDefault()}
            />
          ) : (
            toggle
          )
        }
        toggleOnHover={toggleOnHover}
        panelSize={panelSize ? panelSize : 'fitContent'}
        disabled={disabled}
        relativeTo={relativeTo ? relativeTo : 'element'}
        ariaControls={id}
        ariaLabel={ariaLabel}
        onToggleKeyDown={onToggleKeyDown}
        onToggleClick={onToggleClick}
        disableToggleOnClick={disableToggleOnClick}
        popOutOfOverflow={popOutOfOverflow}
        hideContent={!noItemsText && filteredItems.length <= 0 && !fixedBottomRow}
        alwaysMaintainPanelContent={alwaysMaintainPanelContent}>
        {fixedTopRowComponent && <MenuFixedRow>{fixedTopRowComponent}</MenuFixedRow>}
        <Menu
          id={id}
          tabIndex={-1}
          data-testid={'menu-wrapper'}
          aria-label={ariaLabel}
          role={mode === 'dropdown' ? 'listbox' : undefined}>
          <MenuArrowUpWrapper>
            {showTopArrow && (
              <TopArrowIcon dataTestId={'arrow-top'} disableInvert name={'arrow-down'} />
            )}
          </MenuArrowUpWrapper>
          <MenuInner
            onScroll={toggleArrows}
            ref={
              menuRef
                ? (e) => {
                    // Sharing ref usage: https://react-hook-form.com/faqs#Howtosharerefusage
                    menuRef.current = e;
                  }
                : ref
            }
            overflowScrollable={overflowScrollable}
            maxHeight={maxHeight}>
            {itemElements}
          </MenuInner>
          <MenuArrowDownWrapper>
            {showBottomArrow && (
              <Icon disableInvert name={'arrow-down'} dataTestId={'arrow-down'} />
            )}
          </MenuArrowDownWrapper>
        </Menu>
        {fixedBottomRow && isFixedRowSingle(fixedBottomRow) ? (
          <FixedBottomRow row={fixedBottomRow} />
        ) : (
          // eslint-disable-next-line react/no-array-index-key
          fixedBottomRow?.map((row, index) => <FixedBottomRow row={row} key={index} />)
        )}
      </WalPanel>
    </MenuWrapper>
  );
};
