import { UseMutateFunction, useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useMatch, useNavigate, useParams } from 'react-router';

import { DeploymentDelta } from '@src/models/deployment-delta';
import { DeploymentSet } from '@src/models/deployment-set';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';
import { generateAppURL, generateDraftURL } from '@src/utilities/navigation';

import { deploymentDeltaQueryKeys } from '../deploymentDeltaQueryKeys';

interface CreateDeploymentDeltaVariables {
  envId: string;
  fromDelta?: Partial<DeploymentDelta>; // delta to copy from
  name?: string;
  navigateAfterCreation?: 'base-path' | 'existing-path';
}

export type DeploymentDeltaCreateMutationType = UseMutateFunction<
  AxiosResponse<DeploymentSet>,
  AxiosError,
  CreateDeploymentDeltaVariables
>;

const useDeploymentDeltaCreateMutation = () => {
  // Router hooks
  const { orgId, appId } = useParams<keyof MatchParams>() as MatchParams;

  const pathAfterDeployId = useMatch('/orgs/:orgId/apps/:appId/envs/:envId/deploys/:deployId/*')
    ?.params['*'];

  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const { data, ...queryResult } = useMutation<
    AxiosResponse<DeploymentSet>,
    AxiosError,
    CreateDeploymentDeltaVariables
  >({
    mutationFn: ({ fromDelta, envId, name }) => {
      const newDelta: Partial<DeploymentDelta> = fromDelta
        ? {
            modules: fromDelta.modules,
            shared: fromDelta.shared,
            metadata: {
              env_id: envId,
              name,
            },
          }
        : {
            metadata: {
              env_id: envId,
              name,
            },
            shared: [],
            modules: {},
          };

      return makeRequest('POST', `/orgs/${orgId}/apps/${appId}/deltas`, newDelta);
    },
    onSuccess: (response, { envId, navigateAfterCreation }) => {
      const deltaId = response.data.id;

      queryClient.invalidateQueries({
        queryKey: deploymentDeltaQueryKeys.list(orgId, appId, { env: envId }),
      });

      if (navigateAfterCreation === 'base-path') {
        navigate(generateDraftURL(orgId, appId, envId, deltaId, 'workloads'));
      } else if (navigateAfterCreation === 'existing-path') {
        navigate(
          `${generateAppURL(orgId, appId, envId)}/draft/${deltaId}/${pathAfterDeployId || 'workloads'}`
        );
      }
    },
  });
  return { ...queryResult, data: data?.data };
};

export default useDeploymentDeltaCreateMutation;
