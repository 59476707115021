import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useParams } from 'react-router';

import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import envValuesQueryKeys from '../envValuesQueryKeys';
import appValuesQueryKeys from '../queries/appValuesQueryKeys';
import { sharedValuesQueryKeys } from '../sharedValuesQueryKeys';

export const getEnvValuesHistoryRevertURL = (
  orgId: string | undefined,
  appId: string | undefined,
  envId: string | undefined,
  revertChangeId: string,
  keyValue: string
) =>
  `/orgs/${orgId}/apps/${appId}/envs/${envId}/value-set-versions/${revertChangeId}/restore/${keyValue}`;
export const getSharedAppValuesHistoryRevertURL = (
  orgId: string | undefined,
  appId: string | undefined,
  revertChangeId: string,
  keyValue: string
) => `/orgs/${orgId}/apps/${appId}/value-set-versions/${revertChangeId}/restore/${keyValue}`;

/**
 *
 */
export const useRevertVersionHistoryMutation = (
  keyValue: string,
  onSuccess?: () => void,
  onError?: (err: AxiosError<{ message: string }>) => void
) => {
  // Router hooks
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      comment,
      revertChangeId,
      isEnvironmentOverride,
    }: {
      comment: string;
      revertChangeId: string;
      isEnvironmentOverride: boolean;
    }) =>
      makeRequest(
        'POST',
        isEnvironmentOverride
          ? getEnvValuesHistoryRevertURL(orgId, appId, envId, revertChangeId, keyValue)
          : getSharedAppValuesHistoryRevertURL(orgId, appId, revertChangeId, keyValue),
        { comment }
      ),
    onSuccess: (_, { isEnvironmentOverride }) => {
      if (orgId && appId) {
        // invalidate redux cache
        queryClient.invalidateQueries({
          queryKey: isEnvironmentOverride
            ? sharedValuesQueryKeys.envValuesList(orgId, appId, envId)
            : sharedValuesQueryKeys.appValuesList(orgId, appId),
        });
        if (isEnvironmentOverride) {
          // trigger to refetch environment values
          queryClient.invalidateQueries({ queryKey: envValuesQueryKeys.list(orgId, appId, envId) });
        } else {
          // trigger to refetch app values
          queryClient.invalidateQueries({ queryKey: appValuesQueryKeys.list(orgId, appId) });
        }
      }
      if (onSuccess) onSuccess();
    },
    onError,
  });
};
export default useRevertVersionHistoryMutation;
