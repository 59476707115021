import { EmptyStateCard, WalTable, WalTableRow } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router';

import { useGetUserGroupsQuery } from '@src/hooks/react-query/user/queries/useGetUserGroupsQuery';
import { MatchParams } from '@src/models/routing';
import { Group } from '@src/models/user';
import { generateOrgMembersURL } from '@src/utilities/navigation';

export const OrgMemberGroups = () => {
  // Router hooks
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const { data: groups, isSuccess: isUserGroupsLoaded } = useGetUserGroupsQuery();

  // i18n
  const { t } = useTranslation('orgMembers');

  if ((!groups || !groups.length) && isUserGroupsLoaded)
    return <EmptyStateCard>{t('NOT_PART_OF_GROUP')}</EmptyStateCard>;

  return (
    <WalTable
      caption={t('GROUPS_TITLE')}
      columns={[
        {
          prop: 'name',
          label: t('GROUP'),
          justifyContent: 'flex-start',
          template: ({ data }: WalTableRow<Group>) => (
            <Link to={generateOrgMembersURL(orgId, 'groups', data.id, 'users')}>
              {data.group_id}
            </Link>
          ),
        },
      ]}
      rows={groups?.map((group) => ({ data: group })) ?? []}
    />
  );
};
