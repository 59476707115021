import { useParams } from 'react-router';
import styled from 'styled-components';

import ViewWorkloadProfile from '@src/components/shared/ViewWorkloadProfile/ViewWorkloadProfile';
import DeploymentOrDeltaContextWrapper from '@src/context/DeploymentOrDeltaContextWrapper';
import useWorkloadProfileLatestQuery from '@src/hooks/react-query/workload-profiles/queries/useWorkloadProfileLatestQuery';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { MatchParams } from '@src/models/routing';
import { containerStyle } from '@src/styles/layout/PageContainer';

import PageHeader from '../PageHeader/PageHeader';
import { WorkloadStatus } from './components/WorkloadsStatus/WorkloadStatus';

const Container = styled.div`
  ${containerStyle(false)};
  flex-direction: column;
  flex: 1;
`;

const WorkloadWrapper = styled.div`
  flex: 1;
  background-color: ${({ theme }) => theme.color.base};
`;

const RenderViewWorkload = () => {
  // Router hooks
  const { moduleId } = useParams<keyof MatchParams>() as MatchParams;

  const { workloadProfileId } = useDeltaUtils();

  // React Query
  const { data: workloadProfile } = useWorkloadProfileLatestQuery(workloadProfileId);

  return (
    <Container>
      <WorkloadWrapper>
        <DeploymentOrDeltaContextWrapper>
          {workloadProfile?.spec_definition ? (
            <>
              <PageHeader
                statusMessage={<WorkloadStatus workloadId={moduleId} />}
                showPageContext
              />
              <ViewWorkloadProfile schema={workloadProfile.spec_definition} />
            </>
          ) : (
            <></>
          )}
        </DeploymentOrDeltaContextWrapper>
      </WorkloadWrapper>
    </Container>
  );
};

export default RenderViewWorkload;
