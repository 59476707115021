import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router';
import styled from 'styled-components';

import LoadingBar from './components/shared/LoadingBar';
import useGetOrgsQuery from './hooks/react-query/organisations/queries/useGetOrgsQuery';
import useGetCurrentUserQuery from './hooks/react-query/user/queries/useGetCurrentUserQuery';
import { getSelectedOrganization } from './utilities/local-storage';
import { generateAppURL } from './utilities/navigation';

const Center = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const RedirectRoot = () => {
  const currentOrgId = getSelectedOrganization();
  const { data: user, isLoading: userLoading } = useGetCurrentUserQuery();
  const { data: orgs = [] } = useGetOrgsQuery();
  const { t } = useTranslation();
  const UITranslations = t('UI');

  if (userLoading) {
    return (
      <>
        <LoadingBar />
        <Center>
          <span>{UITranslations.LOADING}</span>
        </Center>
      </>
    );
  }

  if (!user) {
    return <Navigate to={'/auth/login'} />;
  }
  return currentOrgId ? (
    <Navigate to={generateAppURL(currentOrgId)} />
  ) : orgs.length > 0 ? (
    <Navigate to={`/orgs/${orgs[0]!.id}`} />
  ) : (
    <Navigate to={'/auth/login'} />
  );
};

export default RedirectRoot;
