import {
  ExpandableSection,
  WalTable,
  WalTableColumn,
  WarningSection,
} from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';
import styled from 'styled-components';

const ErrorLink = styled(Link)`
  text-decoration: none;
`;

export interface ErrorTableData {
  summary: {
    text?: string;
    url: string;
  };
  source: string;
}

interface DeploymentCardErrorTableProps {
  isActiveDeployment: boolean | undefined;
  errors: {
    runtime: ErrorTableData[];
    deployment: ErrorTableData[];
  };
}

const DeploymentCardErrorTable = ({
  errors,
  isActiveDeployment,
}: DeploymentCardErrorTableProps) => {
  // i18n
  const { t } = useTranslation('viewEnvironment');
  const translations = t('DEPLOYS').DEPLOYMENT_CARD;

  const getColumns = (errorType: 'runtime' | 'deployment'): WalTableColumn<ErrorTableData>[] => [
    {
      prop: 'errors',
      label:
        errorType === 'deployment' ? translations.DEPLOYMENT_ERRORS : translations.RUNTIME_ERRORS,
      ellipsisTooltip: {
        maxWidth: 600,
        text: () => '',
      },
      template: ({ data: { summary } }) => (
        <ErrorLink to={summary.url}>
          <span>{summary.text}</span>
        </ErrorLink>
      ),
    },
    {
      prop: 'source',
      label: translations.SOURCE,
      justifyContent: 'flex-end',
      template: ({ data: { source } }) => <span>{source}</span>,
    },
  ];

  const renderWarningSection = () =>
    ((isActiveDeployment && errors.runtime.length) || errors.deployment.length) && (
      <WarningSection
        className={isActiveDeployment ? 'mt-lg' : 'mt-md'}
        mode={'alert'}
        disableDefaultMargin>
        {isActiveDeployment && (
          <WalTable
            caption={translations.RUNTIME_ERRORS}
            columns={getColumns('runtime')}
            rows={
              errors.runtime.map((data) => ({
                data,
              })) ?? []
            }
            tableRowStyle={'default'}
            tablePadding={'medium'}
          />
        )}
        <WalTable
          caption={translations.DEPLOYMENT_ERRORS}
          columns={getColumns('deployment')}
          rows={
            errors.deployment.map((data) => ({
              data,
            })) ?? []
          }
          tableRowStyle={'default'}
          tablePadding={'medium'}
        />
      </WarningSection>
    );

  return isActiveDeployment ? (
    renderWarningSection()
  ) : (
    <ExpandableSection className={'mt-lg'} title={'Errors'}>
      {renderWarningSection()}
    </ExpandableSection>
  );
};

export default DeploymentCardErrorTable;
