import { Link } from 'react-router';

import { Dot, DotColor } from '@src/components/shared/Dot';

interface StatusProps {
  /* color of the status dot */
  color: DotColor;
  /* status text shown next to the dot */
  text: string;
  /* link for the status text */
  link?: string;
}

const Status = ({ color, text, link }: StatusProps) => (
  <span className={'flex align-center gap-sm'}>
    <Dot color={color} />
    <span className={'txt-sm txt-translucent'}>{link ? <Link to={link}>{text}</Link> : text}</span>
  </span>
);

export default Status;
