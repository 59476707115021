import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import type { QueryResponse } from '@src/hooks/react-query/types';
import { MatchParams } from '@src/models/routing';
import { WorkloadProfile } from '@src/models/workload-profile';
import makeRequest from '@src/utilities/make-request';

import { workloadProfileQueryKeys } from '../workloadProfilesQueryKeys';

const useWorkloadProfilesListQuery = (): QueryResponse<WorkloadProfile[]> => {
  // Router hooks
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery({
    queryKey: workloadProfileQueryKeys.list(orgId),
    queryFn: () => makeRequest<WorkloadProfile[]>('GET', `/orgs/${orgId}/workload-profiles`),
    // Don't make request if any of the params don't exist
    enabled: Boolean(orgId),
  });

  return {
    ...queryResult,
    data: data?.data,
  };
};

export default useWorkloadProfilesListQuery;
