import { Navigate, useParams } from 'react-router';

import useApplicationQuery from '@src/hooks/react-query/applications/queries/useApplicationQuery';
import useApplicationsQuery from '@src/hooks/react-query/applications/queries/useApplicationsListQuery';
import { MatchParams } from '@src/models/routing';
import { getLastVisitedApp } from '@src/utilities/local-storage';
import { generateAppsListURL, generateAppURL } from '@src/utilities/navigation';

const OrgsRoot = () => {
  // Router hooks
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  // Local storage
  const lastVisitedAppId = getLastVisitedApp() || '';

  // Selectors
  const { isSuccess: applicationsLoaded } = useApplicationsQuery();
  const { data: lastVisitedApp } = useApplicationQuery({ appId: lastVisitedAppId });

  return applicationsLoaded ? (
    <Navigate
      to={{
        pathname: lastVisitedApp?.id
          ? generateAppURL(orgId, lastVisitedApp.id)
          : generateAppsListURL(orgId),
      }}
    />
  ) : (
    <></>
  );
};

export default OrgsRoot;
