import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { WalModal } from '@humanitec/ui-components';
import yamlWorker from '@humanitec/ui-components/src/monaco/monaco-yaml-worker?worker'; //  https://github.com/remcohaszing/monaco-yaml#why-doesnt-it-work-with-vite
// Make monaco editor add to build, so it won't load from CDN everytime
import { loader } from '@monaco-editor/react';
import {
  createInstance,
  OptimizelyProvider,
  ReactSDKClient,
  setLogLevel,
} from '@optimizely/react-sdk';
import { MutationCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import axios, { AxiosError } from 'axios';
import * as monaco from 'monaco-editor';
import editorWorker from 'monaco-editor/esm/vs/editor/editor.worker?worker';
import jsonWorker from 'monaco-editor/esm/vs/language/json/json.worker?worker';
import tsWorker from 'monaco-editor/esm/vs/language/typescript/ts.worker?worker';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { toast, ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';

import AdBlockerWarningModal from '@src/components/AdBlockerWarningModal';
import { ToastContent } from '@src/components/ToastContent/ToastContent';
import { useDecision } from '@src/hooks/useDecision';
import { useUserPreferencesStore } from '@src/hooks/zustand/useUserPreferencesStore';
import { GlobalStyle } from '@src/styles/global-styles';
import {
  getInviteToken,
  getUserPreferencesFromLS,
  removeInviteToken,
  setLastVisitedURL,
} from '@src/utilities/local-storage';

import LoadingBar from './components/shared/LoadingBar';
import AuthWrapper from './context/AuthWrapper';
import { useWalhallContext, WalhallContext } from './context/walhallContext';
import { windowEnv } from './environment';
import useAcceptInviteMutation from './hooks/react-query/user/mutations/useAcceptInviteMutation';
import useGetCurrentUserQuery from './hooks/react-query/user/queries/useGetCurrentUserQuery';
import useLoadInviteQuery from './hooks/react-query/user/queries/useLoadInviteQuery';
import { useDatadogReplay } from './hooks/useDatadogReplay';
import { useCurrentUserStore } from './hooks/zustand/useCurrentUserStore';
import { darkTheme, lightTheme } from './theme/theme';
import { hubspotLogin } from './utilities/tracking/hubspot/hubspot';

loader.config({ monaco });

// Add monaco worker info to window object. At this point, this is the only ugly way we know how to do this
// Monaco uses web workers for syntax validation. By adding webpack plugin in craco.config.js, we are already loading default web workers.
// But since we are using custom Yaml validation library , it needs to be loaded separately. But there is no way to load it using the webpack plugin,
// so we need to manually load the workers again here. We should see if there is a better way to do these
window.MonacoEnvironment = {
  getWorker: (_: any, label: string) => {
    if (label === 'json') {
      return new jsonWorker();
    }
    if (label === 'typescript' || label === 'javascript') {
      return new tsWorker();
    }
    if (label === 'yaml') {
      return new yamlWorker();
    }
    return new editorWorker();
  },
};

if (windowEnv.ENVIRONMENT_NAME !== 'local') {
  datadogRum.init({
    applicationId: 'eee00462-9d63-4565-946b-168f95001c0a',
    clientToken: windowEnv.DATADOG_KEY,
    site: 'datadoghq.eu',
    env: windowEnv.ENVIRONMENT_NAME,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    version: windowEnv.VERSION,
    defaultPrivacyLevel: 'mask',
    startSessionReplayRecordingManually: true,
  });

  datadogLogs.init({
    clientToken: windowEnv.DATADOG_KEY,
    site: 'datadoghq.eu',
    env: windowEnv.ENVIRONMENT_NAME,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });
}

// Set optimizely log level to null
setLogLevel('null');

const AppMain = () => {
  const inviteToken = getInviteToken();

  // Component state
  const [hubspotSent, setHubspotSent] = useState(false);

  const { pendingRequests } = useWalhallContext();
  const { data: user } = useGetCurrentUserQuery();
  const { data: invite, isError: loadInviteError } = useLoadInviteQuery(inviteToken);
  const { authType } = useCurrentUserStore();
  const { mutate: acceptInvite } = useAcceptInviteMutation();

  const location = useLocation();

  // optimizely
  const [toastErrorDecision] = useDecision('toast-error');

  useEffect(() => {
    if (!location.pathname.includes('auth')) {
      setLastVisitedURL(`${location.pathname}${location.search}${location.hash}`);
    }
  }, [location.pathname, location.search, location.hash]);

  useDatadogReplay();
  /**
   * If the user is already logged in and the invitation exists, automatically accept it.
   */
  useEffect(() => {
    if (user && invite && invite.status !== 'done' && inviteToken) {
      acceptInvite({ invite });
    }
  }, [acceptInvite, invite, inviteToken, user]);

  useEffect(() => {
    if (loadInviteError) {
      removeInviteToken();
    }
  }, [loadInviteError]);

  const showLoadingBar = useMemo(() => pendingRequests !== 0, [pendingRequests]);

  useEffect(() => {
    if (user && authType === 'register' && !hubspotSent) {
      try {
        setHubspotSent(true);
        hubspotLogin(user, windowEnv.ENVIRONMENT_NAME);
      } catch (err) {
        datadogRum.addError(err);
      }
    }
  }, [authType, hubspotSent, user]);

  return (
    <div className={'flex'}>
      {toastErrorDecision.enabled && <ToastContainer />}
      {showLoadingBar && <LoadingBar />}
      <Outlet />
    </div>
  );
};

let toastContainerHeightLimitReached = false;
let stackedZIndex = 0;
let toastContainerResizeObserverInitialised = false;

const toastContainerResizeObserver = new ResizeObserver((entries) => {
  const toastContainerBottomMargin = 400;
  const viewHeight = window.innerHeight;
  if (entries[0]) {
    toastContainerHeightLimitReached =
      Number(entries[0].contentRect.height) + toastContainerBottomMargin > viewHeight;
    const stackedToasts = document.getElementsByClassName(
      `Toastify__toast-stacked`
    ) as HTMLCollectionOf<HTMLElement>;
    if (stackedToasts.length > 0) {
      const lastStackedToast = stackedToasts[stackedToasts.length - 1];
      const beforeLastStackedToast = stackedToasts[stackedToasts.length - 2];
      // show full  height of the last stacked toast
      if (lastStackedToast) {
        lastStackedToast.style.height = 'auto';
      }
      // limit height for the toast before last
      if (beforeLastStackedToast) {
        beforeLastStackedToast.style.height = '50px';
      }
    }
  }
});

const onToastOpen = () => {
  const toastContainerEl = document.querySelectorAll('.Toastify__toast-container')?.[0];
  if (toastContainerEl && !toastContainerResizeObserverInitialised) {
    toastContainerResizeObserver.observe(toastContainerEl);
    toastContainerResizeObserverInitialised = true;
  }
  stackedZIndex = stackedZIndex + 1;
};

const onToastClose = () => {
  if (!toastContainerHeightLimitReached) {
    stackedZIndex = 0;
  }
  const stackedToasts = document.getElementsByClassName(
    `Toastify__toast-stacked`
  ) as HTMLCollectionOf<HTMLElement>;
  if (stackedToasts.length > 0) {
    const firstStackedToast = stackedToasts[0];
    // remove the first stacked toast from the stacked pile
    if (firstStackedToast) {
      firstStackedToast.style.zIndex = '0';
      firstStackedToast.style.height = 'auto';
      firstStackedToast.className = 'Toastify__toast';
    }
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 60 * 1000,
    },
  },
  mutationCache: new MutationCache({
    onError: (error: unknown) => {
      if (error instanceof AxiosError && error.response?.data.message) {
        toast(<ToastContent />, {
          hideProgressBar: true,
          autoClose: false,
          onOpen: onToastOpen,
          onClose: onToastClose,
          className: toastContainerHeightLimitReached ? 'Toastify__toast-stacked' : undefined,
          style: {
            zIndex: toastContainerHeightLimitReached ? stackedZIndex : undefined,
          },
          data: {
            title: error.response?.data.error,
            message: error.response?.data.message,
            mode: 'alert',
          },
        });
      }
    },
  }),
});

const App = () => {
  // State
  const [adBlockerWarningModalOpen, setAdBlockerWarningOpen] = useState(false);
  const [optimizelyInstance, setOptimizelyInstance] = useState<ReactSDKClient>();

  // Routing State
  const [shouldConfirmOnNavigate, setShouldConfirmOnNavigate] = useState(false);
  const [showNavigateDialog, setShowNavigateDialog] = useState(false);
  const [nextRoute, setNextRoute] = useState<null | string>(null);

  const navigate = useNavigate();
  const { optimizelyUser } = useCurrentUserStore();
  const [pendingRequests, setPendingRequests] = useState<number>(0);
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  // Zustand
  const { setTheme, theme, syncLocalStorageToUserPreferences } = useUserPreferencesStore();

  const resetRoutingState = (isCancel?: boolean) => {
    setShowNavigateDialog(false);
    setNextRoute(null);
    if (!isCancel) {
      setShouldConfirmOnNavigate(false);
    }
  };

  useEffect(() => {
    // request interceptor
    axios.interceptors.request.use(
      (request) => {
        setPendingRequests((prev) => prev + 1);
        return request;
      },
      (error) => {
        setPendingRequests((prev) => prev - 1);
        return Promise.reject(error);
      }
    );
    // response interceptor
    axios.interceptors.response.use(
      (response) => {
        setPendingRequests((prev) => prev - 1);
        return response;
      },
      (error) => {
        if (error?.response?.status === 401 && !window.location.pathname.includes('/auth')) {
          window.location.href = `${window.location.origin}/auth/login`;
        }
        setPendingRequests((prev) => prev - 1);
        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    const showWarningBeforeLeavePage = (event: BeforeUnloadEvent) => {
      // some browser do not allow custom messages so the browser default message will be displayed in these cases
      event.returnValue = uiTranslations.CONFIRM_LEAVE_PAGE; // for firefox;
      return uiTranslations.CONFIRM_LEAVE_PAGE;
    };
    if (shouldConfirmOnNavigate) {
      window.addEventListener('beforeunload', showWarningBeforeLeavePage);
    }

    return () => {
      window.removeEventListener('beforeunload', showWarningBeforeLeavePage);
    };
  }, [shouldConfirmOnNavigate, uiTranslations.CONFIRM_LEAVE_PAGE]);

  useEffect(() => {
    setOptimizelyInstance(
      createInstance({
        sdkKey: windowEnv.OPTIMIZELY_SDK_KEY,
        eventBatchSize: 10,
        errorHandler: {
          handleError: () => {
            setAdBlockerWarningOpen(true);
          },
        },
        datafileOptions: {
          // Use the env var if it's defined(this is usually only for on-premise installations), otherwise use the default URL.
          urlTemplate:
            windowEnv.OPTIMIZELY_URL_TEMPLATE || 'https://oply.humanitec.io/datafiles/%s.json',
        },
      })
    );
  }, []);

  useEffect(() => {
    const userPrefParsed = getUserPreferencesFromLS();
    if (userPrefParsed) {
      const prefs = { ...userPrefParsed };
      if (prefs) {
        datadogRum.addAction('migrate user preferences localstorage', {
          old: userPrefParsed,
          new: prefs,
        });
        syncLocalStorageToUserPreferences(prefs);
      }
    }
  }, [syncLocalStorageToUserPreferences]);

  useEffect(() => {
    const toggleMediaTheme = () => {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        setTheme('dark');
      } else {
        setTheme('light');
      }
    };
    const themePreference = getUserPreferencesFromLS()?.theme;

    if (!themePreference) {
      toggleMediaTheme();
    } else if (themePreference && (themePreference === 'dark' || themePreference === 'light')) {
      setTheme(themePreference);
    }
    window.matchMedia('(prefers-color-scheme: dark)').addListener(() => {
      toggleMediaTheme();
    });
  }, [setTheme]);

  /**
   * React query client object. We are using the default in-memory caching for now.
   */

  return (
    <ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
      <GlobalStyle />
      {theme && (
        <WalhallContext.Provider
          value={{
            shouldConfirmOnNavigateState: [shouldConfirmOnNavigate, setShouldConfirmOnNavigate],
            navigateDialogState: [showNavigateDialog, setShowNavigateDialog],
            nextRouteState: [nextRoute, setNextRoute],
            theme,
            pendingRequests,
          }}>
          <WalModal
            disableClickOutside
            openState={[showNavigateDialog, setShowNavigateDialog]}
            content={uiTranslations.CONFIRM_LEAVE_PAGE}
            actions={{
              cancel: {
                text: uiTranslations.CANCEL,
                props: {
                  onClick: () => {
                    resetRoutingState(true);
                  },
                },
              },
              main: {
                text: uiTranslations.LEAVE,
                props: {
                  onClick: () => {
                    if (nextRoute) {
                      navigate(nextRoute);
                      resetRoutingState();
                    }
                  },
                },
              },
            }}
          />
          {optimizelyInstance && (
            <OptimizelyProvider optimizely={optimizelyInstance} user={optimizelyUser}>
              <QueryClientProvider client={queryClient}>
                <AuthWrapper setAdBlockerWarningOpen={setAdBlockerWarningOpen}>
                  <AppMain />
                </AuthWrapper>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </OptimizelyProvider>
          )}
        </WalhallContext.Provider>
      )}
      <AdBlockerWarningModal openState={[adBlockerWarningModalOpen, setAdBlockerWarningOpen]} />
    </ThemeProvider>
  );
};

export default App;
