import { DropdownItem, Input, WalDropdownMenu } from '@humanitec/ui-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';

import useEnvironmentTypesQuery from '@src/hooks/react-query/environment-types/queries/useEnvironmentTypesQuery';
import useApplicationEnvironmentsQuery from '@src/hooks/react-query/environments/queries/useApplicationEnvironmentsQuery';
import useGetCurrentUserQuery from '@src/hooks/react-query/user/queries/useGetCurrentUserQuery';
import { useGetUserRoles } from '@src/hooks/useGetUserRoles';
import { EnvironmentType } from '@src/models/environment-type';
import { MatchParams } from '@src/models/routing';
import { generateSettingsURL } from '@src/utilities/navigation';

interface CreateEnvironmentFormProps {
  defaultEnvTypeId?: string;
}

const CreateEnvironmentForm = ({ defaultEnvTypeId }: CreateEnvironmentFormProps) => {
  // Router hooks
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;
  const location = useLocation();

  // React Query
  const { data: applicationEnvironments = [] } = useApplicationEnvironmentsQuery();
  const { data: envTypes = [] } = useEnvironmentTypesQuery();
  const { data: currentUser } = useGetCurrentUserQuery();

  // RBAC
  const { appRole, orgRole } = useGetUserRoles();

  // i18n
  const { t } = useTranslation();
  const viewApplicationTranslations = t('VIEW_APPLICATION');

  const envTypeOptions: DropdownItem<EnvironmentType>[] = useMemo(
    () =>
      envTypes
        .filter((envType) => {
          const hasDeployerRole =
            currentUser?.roles[`/orgs/${orgId}/env-types/${envType.id}`] === 'deployer';
          return (
            ((appRole === 'owner' || appRole === 'developer') && hasDeployerRole) ||
            orgRole === 'administrator'
          );
        })
        .map((envType) => ({
          label: envType.id,
          value: envType,
          id: envType.id,
        })),
    [appRole, currentUser?.roles, envTypes, orgId, orgRole]
  );

  return (
    <>
      <Input
        name={'envId'}
        label={viewApplicationTranslations.ID}
        required
        maxLength={20}
        standardValidation={[
          { type: 'id' },
          { type: 'existingId', ids: applicationEnvironments.map((e) => e.id) },
        ]}
      />
      <WalDropdownMenu
        items={envTypeOptions}
        buttonVariant={'input'}
        name={'envTypeId'}
        fullWidth
        maxHeight={250}
        label={viewApplicationTranslations.ENVIRONMENT_TYPE}
        defaultValue={defaultEnvTypeId || envTypeOptions?.[0]?.id}
        menuSize={'parent'}
        fixedBottomRow={{
          component: <span>{viewApplicationTranslations.MANAGE_TYPES}</span>,
          link: {
            to: generateSettingsURL(orgId, 'environment-types'),
            state: {
              previousPage: location.pathname,
            },
          },
        }}
      />
    </>
  );
};
export default CreateEnvironmentForm;
