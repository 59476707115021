import { Accordion, Text, WalLabel } from '@humanitec/ui-components';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router';
import styled from 'styled-components';

import { SkeletonItem } from '@src/components/shared/Skeleton/SkeletonItem';
import {
  getDuration,
  getIsWaitingForApproval,
  getStatus,
  getStatusIcons,
} from '@src/containers/Orgs/PipelineRuns/utils';
import usePipelineRunDeploymentsQuery from '@src/hooks/react-query/pipeline-runs/queries/usePipelineRunDeploymentsQuery';
import usePipelineRunDetailsQuery from '@src/hooks/react-query/pipeline-runs/queries/usePipelineRunDetailsQuery';
import usePipelineRunJobDetailsQuery from '@src/hooks/react-query/pipeline-runs/queries/usePipelineRunJobDetailsQuery';
import { MatchParams } from '@src/models/routing';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';
import { generateAppURL } from '@src/utilities/navigation';

import { StepDetails } from '../StepDetails/StepDetails';

interface JobDetailsProps {
  jobId: string;
}

const Title = styled(Text)`
  text-transform: capitalize;
`;

const Wrapper = styled.div`
  justify-content: space-between;
`;

const CustomText = styled(Text)`
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const JobDetails = ({ jobId }: JobDetailsProps) => {
  const { t } = useTranslation();
  const errorTranslations = t('ERROR');
  const translations = i18next.t('PIPELINE_RUNS');

  const { orgId, appId, pipelineId, pipelineRunId } = useParams<keyof MatchParams>() as MatchParams;
  const { data: job, isLoading: isJobLoading } = usePipelineRunJobDetailsQuery({ jobId });
  const { data: run } = usePipelineRunDetailsQuery({ id: pipelineRunId });
  const { data: runDeployments } = usePipelineRunDeploymentsQuery(run?.env_ids || [], {
    pipelineId,
    pipelineRunId,
  });

  const jobDeployments = runDeployments?.filter(
    (deployment) => deployment.pipeline?.job_id === jobId
  );

  if (isJobLoading) {
    return <SkeletonItem height={390} />;
  }

  if (!job) {
    return <CustomText color={'textTranslucent'}>{errorTranslations.JOB_NOT_FOUND}</CustomText>;
  }

  const steps = job?.steps || [];

  return (
    <div className={'flex-column full-height'}>
      <TitleWrapper>
        <Title size={'lg'}>{job.id}</Title>
      </TitleWrapper>

      <div className={'flex-centered mt-xl'}>
        <div className={'flex-column mr-xl'}>
          <WalLabel>{translations.TABLE.STATUS}</WalLabel>
          <Text size={'base'} className={'mt-sm flex-centered'}>
            {getStatus(getIsWaitingForApproval(job) ? 'waiting' : job?.status)}
          </Text>
        </div>
        <div className={'flex-column mr-xl'}>
          <WalLabel>{translations.TABLE.STARTED}</WalLabel>
          <Text size={'base'} className={'mt-sm flex-centered'}>
            {job.created_at &&
              formatDate(job.created_at, DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE)}
          </Text>
        </div>
        <div className={'flex-column mr-xl'}>
          <WalLabel>{translations.STATUS.COMPLETED}</WalLabel>
          <Text size={'base'} className={'mt-sm flex-centered'}>
            {job.completed_at
              ? formatDate(job.completed_at, DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE)
              : '-'}
          </Text>
        </div>
        <div className={'flex-column'}>
          <WalLabel>{translations.JOBS.DURATION}</WalLabel>
          <Text size={'base'} className={'mt-sm flex-centered'}>
            {getDuration(job.completed_at, job.created_at)}
          </Text>
        </div>
      </div>

      <Text size={'sm'} color={'textTranslucent'} className={'mt-xl mb-sm'}>
        {translations.STEPS}
      </Text>
      <Accordion
        items={steps.map(({ index, status, completed_at, created_at, name }) => {
          const stepDeployment = jobDeployments?.find(
            (deployment) => deployment.pipeline?.step_index === index
          );
          return {
            cardStyle: 'default',
            id: `job-step-${index}`,
            headerContent: (
              <Wrapper className={'flex-centered'}>
                <span className={'flex-centered'}>
                  <Text color={'text'} size={'base'} className={'flex-centered'}>
                    {getStatusIcons(16)[status]} {name || `${translations.STEP} ${index + 1}`}
                  </Text>
                  {stepDeployment && (
                    <>
                      <span className={'mx-sm'}>-</span>
                      <Link
                        className={'txt-base'}
                        to={generateAppURL(orgId, appId, stepDeployment?.env_id)}>
                        {stepDeployment?.env_id}
                      </Link>
                    </>
                  )}
                </span>
                <Text size={'sm'} color={'textTranslucent'}>
                  {getDuration(completed_at, created_at)}
                </Text>
              </Wrapper>
            ),
            content: <StepDetails jobId={job.id} stepIndex={index} deployment={stepDeployment} />,
          };
        })}
      />
    </div>
  );
};
