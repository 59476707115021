import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router';

import { Role, RoleType } from '@src/models/role';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { userQueryKeys } from '../../user/userQueryKeys';
import { generateRoleQueryKeyBasedOnRoleType, generateRolesURL } from '../rolesQueryKeys';

const useRoleCreateMutation = (roleType?: RoleType, successCallback?: (res: Role) => void) => {
  const queryClient = useQueryClient();
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useMutation<
    AxiosResponse<Role>,
    AxiosError,
    Pick<Role, 'email' | 'role'> | Pick<Role, 'id' | 'role'>
  >({
    mutationFn: (newRole) => makeRequest('POST', generateRolesURL(orgId, roleType), newRole),
    onSuccess: (res) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: generateRoleQueryKeyBasedOnRoleType(orgId, roleType),
      });

      // Invalidate org user
      queryClient.invalidateQueries({
        queryKey: userQueryKeys.user(orgId, res.data.id),
      });

      if (successCallback) {
        successCallback(res.data);
      }
    },
  });
  return { ...queryResult, data: data?.data };
};

export default useRoleCreateMutation;
