import { Button, WalDropdownMenu, WalModal } from '@humanitec/ui-components';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router';

import TestClusterSteps from '@src/containers/Orgs/Resources/components/ResourcesTable/components/TestClusterSteps';
import useApplicationsQuery from '@src/hooks/react-query/applications/queries/useApplicationsListQuery';
import useEnvironmentTypesQuery from '@src/hooks/react-query/environment-types/queries/useEnvironmentTypesQuery';
import useApplicationEnvironmentsQuery from '@src/hooks/react-query/environments/queries/useApplicationEnvironmentsQuery';
import useCheckConnectivityMutation from '@src/hooks/react-query/runtime-actions/useCheckConnectivityQuery';
import { useWalhallForm } from '@src/utilities/form';

const ResourceDefinitionClusterTest = () => {
  // i18n
  const { t } = useTranslation();
  const translations = t('ACCOUNT_SETTINGS').RESOURCES.TEST_CLUSTER;
  const uiTranslations = t('UI');
  // form
  const methods = useWalhallForm();

  // component state
  const [selectedApp, setSelectedApp] = useState<string>();
  const [selectedEnv, setSelectedEnv] = useState<string>();
  const [selectedEnvType, setSelectedEnvType] = useState<string>();
  const [connectivitytestModalOpen, setConnectivitytestModalOpen] = useState(false);

  // react-query
  const { data: applications } = useApplicationsQuery();
  const { data: environments } = useApplicationEnvironmentsQuery({ appId: selectedApp });
  const { data: environmentTypes } = useEnvironmentTypesQuery();
  const {
    mutate: checkConnectivity,
    isSuccess: connectivityCheckIsSuccess,
    isError: connectivityCheckIsError,
    data: checkConnectivityData,
    isPending: connectivityCheckPending,
    error: connectivityCheckError,
    reset,
  } = useCheckConnectivityMutation();

  useEffect(() => {
    if (!selectedApp) {
      setSelectedApp(applications?.[0]?.id);
    }
    setSelectedEnv(environments?.[0]?.id);
    setSelectedEnvType(environmentTypes?.[0]?.id);
  }, [applications, environments, environmentTypes, selectedApp]);

  const applicationDropdownItems = useMemo(
    () =>
      applications?.map((app) => ({
        id: app.id,
        label: app.name,
        value: app.id,
      })),
    [applications]
  );

  const environmentDropdownItems = useMemo(
    () =>
      environments?.map((env) => ({
        id: env.id,
        label: env.name,
        value: env.id,
      })),
    [environments]
  );

  const environmentTypesDropdownItems = useMemo(
    () =>
      environmentTypes?.map((envType) => ({
        id: envType.id,
        label: envType.id,
        value: envType.id,
      })),
    [environmentTypes]
  );

  const handleTestButtonClick = () => {
    if (!connectivitytestModalOpen) {
      setConnectivitytestModalOpen(true);
    }
    checkConnectivity({
      app_id: selectedApp || '',
      env_id: selectedEnv || '',
      env_type: selectedEnvType || '',
    });
  };

  return (
    <>
      <p className={'txt-base mb-md'}>{translations.TEST_CLUSTER_TEXT1}</p>
      <p className={'txt-base mb-md'}>{translations.TEST_CLUSTER_TEXT2}</p>
      <p className={'txt-base mb-md'}>
        <Trans defaults={translations.TEST_CLUSTER_TEXT3}>
          <Link
            className={'txt-base'}
            to={
              'http://developer.humanitec.com/platform-orchestrator/resources/resource-definitions/#matching-criteria'
            }
            target={'_blank'}
          />
        </Trans>
      </p>
      <FormProvider {...methods}>
        {applicationDropdownItems && (
          <WalDropdownMenu
            className={'mb-md'}
            label={translations.APPLICATION_ID}
            onItemClick={(id) => setSelectedApp(id)}
            defaultValue={selectedApp}
            items={applicationDropdownItems}
          />
        )}
        {environmentDropdownItems && (
          <WalDropdownMenu
            className={'mb-md'}
            label={translations.ENVIRONMENT_ID}
            name={'environment'}
            onItemClick={(id) => setSelectedEnv(id)}
            defaultValue={selectedEnv}
            items={environmentDropdownItems}
          />
        )}
        {environmentTypesDropdownItems && (
          <WalDropdownMenu
            className={'mb-md'}
            label={translations.ENVIRONMENT_TYPE}
            onItemClick={(id) => setSelectedEnvType(id)}
            defaultValue={selectedEnvType}
            items={environmentTypesDropdownItems}
          />
        )}
        {connectivitytestModalOpen && (
          <WalModal
            openState={[connectivitytestModalOpen, setConnectivitytestModalOpen]}
            size={'large'}
            backgroundColor={'base'}
            title={translations.TEST_CLUSTER_CONNECTION}
            content={
              <TestClusterSteps
                checkConnectivityMutationData={checkConnectivityData?.data}
                checkConnectivityMutationError={connectivityCheckError}
                checkConnectivityMutationIsError={connectivityCheckIsError}
                checkConnectivityMutationIsPending={connectivityCheckPending}
                checkConnectivityMutationIsSuccess={connectivityCheckIsSuccess}
              />
            }
            actions={{
              cancel: {
                text: uiTranslations.CLOSE,
                props: {
                  onClick: () => {
                    setConnectivitytestModalOpen(false);
                    reset();
                  },
                },
              },
              main: {
                text: translations.TEST_AGAIN,
                props: {
                  loading: connectivityCheckPending,
                  type: 'submit',
                  onClick: handleTestButtonClick,
                },
              },
            }}
          />
        )}
        <div className={'mt-md'}>
          <Button
            variant={'primary'}
            loading={connectivityCheckPending}
            onClick={handleTestButtonClick}>
            {translations.TEST}
          </Button>
        </div>
      </FormProvider>
    </>
  );
};

export default ResourceDefinitionClusterTest;
