import { Button } from '@humanitec/ui-components';
import { useQueryClient } from '@tanstack/react-query';
import { rem } from 'polished';
import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import styled from 'styled-components';

import useOrgCreateMutation from '@src/hooks/react-query/organisations/mutations/useOrgCreateMutation';
import { useUserPreferencesStore } from '@src/hooks/zustand/useUserPreferencesStore';
import { units } from '@src/styles/variables';
import { useWalhallForm } from '@src/utilities/form';
import { generateAppURL } from '@src/utilities/navigation';

import { FormWrapper } from '../../components/FormWrapper';
import OrganizationNameInput from '../../components/OrganizationNameInput';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: ${rem(380)};
`;

const Heading = styled.h3`
  margin-bottom: ${units.margin.md};
`;

const SubTitle = styled.span`
  font-size: ${units.fontSize.sm};
  margin-bottom: ${units.margin.md};
`;

const SubmitButton = styled(Button)`
  margin-top: ${units.margin.md};
  .inner-button-text {
    justify-content: center;
  }
`;

const Form = styled(FormWrapper)`
  align-items: flex-start;
  padding-bottom: ${units.padding.xxxl};
  margin-bottom: ${units.margin.xl};
  border-bottom: 1px solid ${({ theme }) => theme.color.baseOutline};
`;

const ExpectingSomethingElse = styled.span`
  font-size: ${units.fontSize.sm};
  padding-bottom: ${units.padding.sm};
`;

const ExpectingPartOfOrg = styled.span`
  color: ${({ theme }) => theme.color.textTranslucent};
  font-size: ${units.fontSize.sm};
`;

interface FormProps {
  organization_name: string;
}

const CreateOrganization = () => {
  const queryClient = useQueryClient();
  // i18n
  const { t } = useTranslation();
  const authTranslations = t('AUTHENTICATE');

  // Form
  const methods = useWalhallForm<FormProps>({
    defaultValues: { organization_name: '' },
  });

  const { formState, handleSubmit, setError } = methods;

  const {
    data: createdOrg,
    isPending: creatingOrg,
    mutate: createOrganization,
    error,
  } = useOrgCreateMutation({
    onError: (mutationError) => {
      if (mutationError.response?.status === 409) {
        setError('organization_name', {
          type: 'validate',
          message: authTranslations.ORGANIZATION_NAME_IS_TAKEN,
        });
      }
    },
  });

  // Router hooks
  const navigate = useNavigate();

  // Zustand
  const { setOpenContactForm } = useUserPreferencesStore();

  useEffect(() => {
    if (createdOrg) {
      navigate(generateAppURL(createdOrg.id));
    }
  }, [createdOrg, navigate, queryClient]);

  const createOrgClick = ({ organization_name }: FormProps) => {
    createOrganization(organization_name);
  };

  const contactSupport = () => {
    setOpenContactForm(true);
  };

  return (
    <Wrapper>
      <FormProvider {...methods}>
        <Heading>{authTranslations.CREATE_ORGANIZATION}</Heading>
        <SubTitle>{authTranslations.ADD_PEOPLE_LATER}</SubTitle>
        <Form onSubmit={handleSubmit(createOrgClick)}>
          <OrganizationNameInput error={error} />
          <SubmitButton
            loading={creatingOrg}
            type={'submit'}
            variant={'primary'}
            disabled={formState.isSubmitting || creatingOrg}>
            {authTranslations.CREATE_ORGANIZATION}
          </SubmitButton>
        </Form>
        <ExpectingSomethingElse>{authTranslations.EXPECTING_SOMETHING_ELSE}</ExpectingSomethingElse>
        <ExpectingPartOfOrg>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          {authTranslations.EXPECTING_PART_OF_ORG} <a onClick={contactSupport}>contact support</a>.
        </ExpectingPartOfOrg>
      </FormProvider>
    </Wrapper>
  );
};

export default CreateOrganization;
