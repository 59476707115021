import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import type { QueryResponse } from '@src/hooks/react-query/types';
import { EnvTypeRoles, Role } from '@src/models/role';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import useEnvironmentQuery from '../../environments/queries/useEnvironmentQuery';
import { generateRolesURL, rolesQueryKeys } from '../rolesQueryKeys';

export const envTypeRolesQueryOptions = (orgId: string | undefined, envTypeId?: string) => ({
  queryKey: rolesQueryKeys.envTypeRoles(orgId, envTypeId),
  queryFn: () =>
    makeRequest<Role<EnvTypeRoles>[]>(
      'GET',
      generateRolesURL(orgId || '', {
        type: 'env-types',
        id: envTypeId as string,
      })
    ),
  enabled: Boolean(orgId && envTypeId),
});

const useEnvTypeRolesQuery = (): QueryResponse<Role<EnvTypeRoles>[]> => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data: environment } = useEnvironmentQuery();

  const { data, ...queryResult } = useQuery(envTypeRolesQueryOptions(orgId, environment?.type));

  return { ...queryResult, data: data?.data };
};

export default useEnvTypeRolesQuery;
