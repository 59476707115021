import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { MatchParams } from '@src/models/routing';
import { WorkloadProfileVersion } from '@src/models/workload-profile';
import makeRequest from '@src/utilities/make-request';

import { workloadProfileQueryKeys } from '../workloadProfilesQueryKeys';

export const getWorkloadProfileIdAndOrgId = (profileName: string | undefined) => {
  if (profileName && profileName.indexOf('/') >= 0) {
    const [profileOrgId, profileId] = profileName?.split('/') || [];
    return {
      profileOrgId,
      profileId,
    };
  }

  return {
    profileId: profileName,
  };
};

const getProfileRequestId = (profileName: string | undefined) => {
  const { profileId, profileOrgId } = getWorkloadProfileIdAndOrgId(profileName);

  if (profileOrgId) {
    return `${profileOrgId}.${profileId}`;
  }

  return profileId;
};

const useWorkloadProfileLatestQuery = (profileName: string | undefined) => {
  // Router hooks
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const profileRequestId = getProfileRequestId(profileName);

  const { data, ...queryResult } = useQuery({
    queryKey: workloadProfileQueryKeys.latestVersion(orgId, profileRequestId),
    queryFn: () =>
      makeRequest<WorkloadProfileVersion>(
        'GET',
        `/orgs/${orgId}/workload-profiles/${profileRequestId}/versions/latest`
      ),
    // Don't make request of any of the params don't exist
    enabled: Boolean(orgId && profileRequestId),
  });

  return {
    ...queryResult,
    data: data?.data,
  };
};

export default useWorkloadProfileLatestQuery;
