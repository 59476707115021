import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { MatchParams } from '@src/models/routing';
import { EventTrigger } from '@src/models/webhook';
import makeRequest from '@src/utilities/make-request';

import { QueryResponse } from '../types';
import { eventsQueryKeys } from './eventsQueryKeys';

const useEventsQuery = (): QueryResponse<EventTrigger[]> => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery({
    queryKey: eventsQueryKeys.list(orgId),
    queryFn: () => makeRequest<EventTrigger[]>('GET', `/orgs/${orgId}/events`),
    enabled: Boolean(orgId),
  });

  return { ...queryResult, data: data?.data };
};

export default useEventsQuery;
