import {
  Button,
  ErrorWarning,
  Spinner,
  Text,
  WalModal,
  WalTable,
  WalTableColumn,
} from '@humanitec/ui-components';
import { AxiosError } from 'axios';
import { findLast } from 'lodash';
import { rem } from 'polished';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router';
import styled from 'styled-components';

import useApproveRequestMutation from '@src/hooks/react-query/pipeline-runs/mutation/useApproveRequestMutation';
import useDenyRequestMutation from '@src/hooks/react-query/pipeline-runs/mutation/useDenyRequestMutation';
import usePipelineRunDetailsQuery from '@src/hooks/react-query/pipeline-runs/queries/usePipelineRunDetailsQuery';
import usePipelineRunJobDetailsQuery from '@src/hooks/react-query/pipeline-runs/queries/usePipelineRunJobDetailsQuery';
import usePipelineRunsApprovalRequestsQuery from '@src/hooks/react-query/pipeline-runs/queries/usePipelineRunsApprovalRequestsQuery';
import { PipelineRunJobStep } from '@src/models/pipeline';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';
import { generatePipelinesURL } from '@src/utilities/navigation';

const CustomLink = styled(Link)`
  font-size: ${units.fontSize.base};
  text-decoration: none;
  hyphens: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
`;

const CustomText = styled(Text)`
  hyphens: auto;
  overflow-wrap: anywhere;
`;
interface ReviewsApprovalProps {
  runId: string;
  appId: string;
  pipelineId: string;
  openState: [boolean, Dispatch<SetStateAction<boolean>>];
}

export const ReviewsApproval = ({ runId, appId, pipelineId, openState }: ReviewsApprovalProps) => {
  const { orgId, pipelineRunId } = useParams();
  const filters = { run: runId };

  // i18n
  const { t } = useTranslation();
  const pipelineTranslations = t('PIPELINE_RUNS');
  const uiTranslations = t('UI');

  // state
  const [error, setError] = useState('');
  const [showReviewModal, setShowReviewModal] = openState;

  const onError = (type: 'APPROVED' | 'DENIED') => (_err: AxiosError) => {
    setError(type);
  };

  // react-query
  const { data: pipelineRun } = usePipelineRunDetailsQuery({
    id: runId,
  });
  const { data: items = [] } = usePipelineRunsApprovalRequestsQuery(appId, {
    run: runId,
    status: 'waiting',
  });

  const results = usePipelineRunJobDetailsQuery({
    jobIds: items.map((item) => item.job_id),
    runId,
  });

  const { mutateAsync: approve, isPending: isApproving } = useApproveRequestMutation({
    appId,
    pipelineId,
    runId,
    onError: onError('APPROVED'),
    filters,
  });

  const { mutateAsync: deny, isPending: isDenying } = useDenyRequestMutation({
    appId,
    pipelineId,
    runId,
    onError: onError('DENIED'),
    filters,
  });

  useEffect(() => {
    if (!items.length && showReviewModal) {
      setShowReviewModal(false);
    }
  }, [items, setShowReviewModal, showReviewModal]);

  const columns: WalTableColumn[] = [
    {
      customStyle: { minWidth: rem(80) },
      label: pipelineTranslations.JOB,
      prop: 'name',
      alignIconAndText: true,
      ellipsisTooltip: {
        maxWidth: 110,
        maxCharacters: 60,
        text: (row) => row.data.job_id,
      },
      template: (row) =>
        pipelineRunId ? (
          <Text size={'base'}>{row.data.job_id}</Text>
        ) : (
          <CustomLink
            onClick={() => {
              setShowReviewModal(false);
            }}
            to={`${generatePipelinesURL(
              orgId,
              row.data.app_id,
              row.data.pipeline_id,
              row.data.run_id
            )}#${row.data.job_id}`}
            className={'py-md'}>
            {row.data.job_id}
          </CustomLink>
        ),
    },
    {
      customStyle: { minWidth: rem(110) },
      label: pipelineTranslations.TABLE.STEP,
      prop: 'step',
      alignIconAndText: true,
      ellipsisTooltip: {
        maxWidth: 110,
        maxCharacters: 60,
        text: (row) => row.data.step,
      },
      template: (row) => {
        return pipelineRunId && row.data.step ? (
          <Text size={'base'}>{row.data.step}</Text>
        ) : row.data.step ? (
          <CustomLink
            onClick={() => {
              setShowReviewModal(false);
            }}
            to={`${generatePipelinesURL(
              orgId,
              row.data.app_id,
              row.data.pipeline_id,
              row.data.run_id
            )}#${row.data.job_id}`}
            className={'py-md'}>
            {row.data.step}
          </CustomLink>
        ) : (
          <Spinner size={'small'} />
        );
      },
    },
    {
      customStyle: { minWidth: rem(60) },
      label: pipelineTranslations.TABLE.MESSAGE,
      prop: 'message',
      alignIconAndText: true,
      template: (row) => (
        <CustomText size={'base'} className={'flex-centered txt-translucent'}>
          {row.data.message}
        </CustomText>
      ),
    },
    {
      customStyle: { verticalAlign: 'middle', minWidth: rem(60) },
      label: '',
      prop: 'status',
      template: (row) => (
        <>
          <Button
            onClick={() => approve({ approvalId: row.data.id, jobId: row.data.job_id })}
            variant={'secondary'}
            className={'mr-md'}
            size={'small'}>
            {pipelineTranslations.TABLE.APPROVE}
          </Button>
          <Button
            onClick={() => deny({ approvalId: row.data.id, jobId: row.data.job_id })}
            variant={'secondary'}
            size={'small'}>
            {pipelineTranslations.TABLE.DECLINE}
          </Button>
        </>
      ),
    },
  ];

  const isLoading = isApproving || isDenying;

  const runName = formatDate(
    pipelineRun?.created_at,
    DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE
  );

  return (
    <WalModal
      title={`${pipelineTranslations.REVIEW_AND_APPROVE} ${runName ? `"${runName}"` : ''} ${
        pipelineTranslations.RUN
      }`}
      disableOverflow={items.length < 5}
      showClose
      disableClickOutside
      openState={[showReviewModal, setShowReviewModal]}
      size={'large'}
      content={
        <>
          <div>
            <WalTable
              tableRowStyle={'base'}
              caption={pipelineTranslations.APPROVAL_REQUESTS}
              columns={columns}
              disableScrolling
              rows={items.map((request) => {
                const lastJobSteps = results.find((res) => res.data?.id === request.job_id)?.data
                  ?.steps;
                const lastStepWithApprove = findLast(lastJobSteps, ({ uses }: PipelineRunJobStep) =>
                  uses?.includes('approve')
                ) as PipelineRunJobStep;

                return {
                  verticalAlignTop: true,
                  data: { ...request, step: lastStepWithApprove?.name },
                };
              })}
            />
          </div>
          {error && (
            <ErrorWarning
              mode={'alert'}
              message={
                error === 'APPROVED'
                  ? pipelineTranslations.APPROVAL_STATUS_NOT_UPDATED_ERR_MESSAGE_APPROVED
                  : pipelineTranslations.APPROVAL_STATUS_NOT_UPDATED_ERR_MESSAGE_DENIED
              }
            />
          )}
        </>
      }
      actions={{
        cancel: {
          text: uiTranslations.CLOSE,
          props: {
            disabled: isLoading,
            onClick: () => {
              setError('');
              setShowReviewModal(false);
            },
          },
        },
      }}
    />
  );
};
