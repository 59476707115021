import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import type { QueryResponse } from '@src/hooks/react-query/types';
import { PipelineRunJobStepLog } from '@src/models/pipeline';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { pipelineRunsQueryKeys } from './pipelineRunsQueryKeys';

interface usePipelineRunDetailsQueryProps {
  index?: number;
  jobId: string;
}

const usePipelineRunJobStepDetailsQuery = ({
  jobId,
  index,
}: usePipelineRunDetailsQueryProps): QueryResponse<PipelineRunJobStepLog[]> => {
  const { orgId, pipelineRunId, appId, pipelineId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery({
    queryKey: pipelineRunsQueryKeys.pipelineRunJobStepDetails(
      orgId,
      appId,
      pipelineId,
      pipelineRunId,
      jobId,
      index
    ),
    queryFn: () => {
      return makeRequest<PipelineRunJobStepLog[]>(
        'GET',
        `/orgs/${orgId}/apps/${appId}/pipelines/${pipelineId}/runs/${pipelineRunId}/jobs/${jobId}/steps/${index}/logs`
      );
    },

    // Don't make request if index is not a number
    enabled: Boolean(typeof index === 'number' && jobId && orgId && appId && pipelineId),
  });

  return { ...queryResult, data: data?.data };
};

export default usePipelineRunJobStepDetailsQuery;
