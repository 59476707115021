import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router';

import { useWebhooksStore } from '@src/hooks/zustand/useWebhooksStore';
import { MatchParams } from '@src/models/routing';
import { Webhook } from '@src/models/webhook';
import makeRequest from '@src/utilities/make-request';

import { webhooksQueryKeys } from '../webhooksQueryKeys';

const useCreateWebhookMutation = (onSuccess?: () => void) => {
  // Router hooks
  const { orgId, appId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const queryClient = useQueryClient();

  // Zustand
  const { pushIdToLastCreatedWebhookIds } = useWebhooksStore();

  const { data, ...queryResult } = useMutation<
    AxiosResponse<Webhook>,
    AxiosError,
    Partial<Webhook>
  >({
    mutationFn: (webhookPayload) =>
      makeRequest('POST', `/orgs/${orgId}/apps/${appId}/webhooks`, webhookPayload),
    onSuccess: (response) => {
      onSuccess?.();
      // Save the last created id to the store
      pushIdToLastCreatedWebhookIds(response.data.id);
      queryClient.invalidateQueries({ queryKey: webhooksQueryKeys.list(orgId, appId) });
    },
  });

  return { ...queryResult, data: data?.data };
};

export default useCreateWebhookMutation;
