import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router';

import { MatchParams } from '@src/models/routing';
import { Webhook } from '@src/models/webhook';
import makeRequest from '@src/utilities/make-request';

import { webhooksQueryKeys } from '../webhooksQueryKeys';

const useDeleteWebhookMutation = () => {
  // Router hooks
  const { orgId, appId } = useParams<keyof MatchParams>() as MatchParams;

  const queryClient = useQueryClient();

  const { data, ...queryResult } = useMutation<AxiosResponse<Webhook>, AxiosError, string>({
    mutationFn: (webhookId) =>
      makeRequest('DELETE', `/orgs/${orgId}/apps/${appId}/webhooks/${webhookId}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: webhooksQueryKeys.list(orgId, appId) });
    },
  });

  return { ...queryResult, data: data?.data };
};

export default useDeleteWebhookMutation;
