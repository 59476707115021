import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router';

import useCurrentPausingEnvironments from '@src/hooks/zustand/useCurrentPausingEnvironments';
import { ApiErrorResponse } from '@src/models/ApiErrorResponse';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { environmentQueryKeys } from '../environmentQueryKeys';

const useEnvironmentPauseMutation = () => {
  // Router hooks
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  const queryClient = useQueryClient();
  const { addCurrentPausingEnvId, removeCurrentPausingEnvId } = useCurrentPausingEnvironments();
  return useMutation<AxiosResponse, AxiosError<ApiErrorResponse>, { paused: boolean }>({
    mutationFn: ({ paused }) => {
      if (paused && envId) {
        addCurrentPausingEnvId(envId);
      }
      return makeRequest(
        'PUT',
        `/orgs/${orgId}/apps/${appId}/envs/${envId}/runtime/paused`,
        paused
      );
    },
    onSuccess: () => {
      if (envId) {
        removeCurrentPausingEnvId(envId);
      }
      queryClient.invalidateQueries({
        queryKey: environmentQueryKeys.environmentPauseStatus(orgId, appId),
      });
    },
  });
};

export default useEnvironmentPauseMutation;
