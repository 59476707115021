import { useTranslation } from 'react-i18next';
import { Link } from 'react-router';

const Freetrial = () => {
  const { t } = useTranslation();
  const authTranslations = t('AUTHENTICATE');

  return (
    <Link className={'txt-sm'} to={'/auth/signup'}>
      {authTranslations.SIGN_UP}
    </Link>
  );
};

export default Freetrial;
