import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { buildQueryString } from '@src/hooks/react-query/query-utils';
import { PipelineApprovalRequest } from '@src/models/pipeline';
import makeRequest from '@src/utilities/make-request';

import { pipelineRunsQueryKeys } from './pipelineRunsQueryKeys';

export const getPipelineRunsApprovalRequestsQuery = (
  orgId: string | undefined,
  appId: string | undefined,
  filters: { run?: string; status?: string } | undefined
) => ({
  queryKey: pipelineRunsQueryKeys.pipelineRunApprovals(orgId, appId, filters),
  queryFn: () => {
    return makeRequest<PipelineApprovalRequest[]>(
      'GET',
      `/orgs/${orgId}/apps/${appId}/approvals${buildQueryString(filters)}`
    );
  },
  // Don't make request if any of the params don't exist
  enabled: Boolean(orgId && appId),
});

const usePipelineRunsApprovalRequestsQuery = (
  appId: string,
  filters?: { run?: string; status?: string }
) => {
  const { orgId } = useParams();

  const { data, ...queryResult } = useQuery(
    getPipelineRunsApprovalRequestsQuery(orgId, appId, filters)
  );

  return {
    ...queryResult,
    data: data?.data,
  };
};

export default usePipelineRunsApprovalRequestsQuery;
