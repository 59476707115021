import { Button, SearchInput } from '@humanitec/ui-components';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useDeploymentOrDeltaContext } from '@src/context/deploymentOrDeltaContext';
import { units } from '@src/styles/variables';

import { AddWorkloadModal } from './AddWorkloadModal/AddWorkloadModal';

export const TopBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  z-index: 3;
  padding-bottom: ${units.padding.xxl};
  background-color: ${({ theme }) => theme.color.base};
  flex-wrap: wrap;
`;

const Search = styled(SearchInput)`
  margin: 0;
`;

interface TopBarProps {
  setFilterInputValue: Dispatch<SetStateAction<string | undefined>>;
  readonly?: boolean;
}

const TopBar = ({ setFilterInputValue, readonly }: TopBarProps) => {
  // Component state
  const [modalOpen, setModalOpen] = useState(false);

  // i18n
  const { t } = useTranslation();
  const workloadTranslations = t('VIEW_WORKLOADS');

  // Context
  const { draftModeActive } = useDeploymentOrDeltaContext();

  const filterWorkloads = (value: string) => {
    setFilterInputValue(value);
  };

  return (
    <TopBarWrapper>
      {draftModeActive && (
        <>
          <Button iconLeft={'plus'} variant={'secondary'} onClick={() => setModalOpen(true)}>
            {workloadTranslations.CREATE_NEW_WORKLOAD}
          </Button>
          {modalOpen && <AddWorkloadModal openState={[modalOpen, setModalOpen]} />}
        </>
      )}
      <Search
        readonly={readonly}
        onChange={filterWorkloads}
        name={'filterWorkloads'}
        label={workloadTranslations.FILTER_WORKLOADS}
        width={200}
      />
    </TopBarWrapper>
  );
};

export default TopBar;
