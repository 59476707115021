import { Button, WalModal } from '@humanitec/ui-components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router';
import styled from 'styled-components';

import TestClusterSteps, {
  ClusterTestSection,
} from '@src/containers/Orgs/Resources/components/ResourcesTable/components/TestClusterSteps';
import useEnvironmentQuery from '@src/hooks/react-query/environments/queries/useEnvironmentQuery';
import useCheckConnectivityMutation from '@src/hooks/react-query/runtime-actions/useCheckConnectivityQuery';
import { useRBAC } from '@src/hooks/useRBAC';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';

const Grid = styled.div`
  display: grid;
  width: 50%;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${units.padding.sm};
  margin-bottom: ${units.margin.md};
`;

export const TestClusterModal = () => {
  // Component state
  const [open, setOpen] = useState(false);

  // i18n
  const { t } = useTranslation();
  const modalTranslations = t('COMPONENTS').TEST_CLUSTER_MODAL;

  // Router hooks
  const { appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  const canTestCluster = useRBAC('testUpdateDeleteCloudAccount');

  // React Query
  const {
    mutate: checkConnectivity,
    isSuccess: connectivityCheckIsSuccess,
    isError: connectivityCheckIsError,
    data: checkConnectivityData,
    isPending: connectivityCheckPending,
    error: connectivityCheckError,
    reset,
  } = useCheckConnectivityMutation();
  const { data: environment } = useEnvironmentQuery();
  const hasResponse = connectivityCheckIsError || connectivityCheckIsSuccess;

  if (!canTestCluster) {
    return <></>;
  }

  return (
    <>
      <Button
        iconLeft={'cloud-accounts'}
        variant={'secondary'}
        size={'medium'}
        className={'ml-sm'}
        onClick={() => setOpen(true)}>
        {modalTranslations.TEST_CLUSTER_CONNECTION}
      </Button>

      <WalModal
        openState={[open, setOpen]}
        size={'large'}
        backgroundColor={'base'}
        title={modalTranslations.TEST_CLUSTER_CONNECTION}
        content={
          <div>
            <Grid>
              <ClusterTestSection header={modalTranslations.APPLICATION_ID} value={appId} />
              <ClusterTestSection header={modalTranslations.ENVIRONMENT_ID} value={envId} />
              <ClusterTestSection
                header={modalTranslations.ENVIRONMENT_TYPE}
                value={environment?.type ?? ''}
              />
            </Grid>
            {!hasResponse && !connectivityCheckPending && (
              <>
                <span className={'mb-md'}>
                  {modalTranslations.DESCRIPTION_1_PART_1}
                  <Link
                    target={'_blank'}
                    to={
                      'https://developer.humanitec.com/platform-orchestrator/resources/resource-definitions/#matching-criteria'
                    }>
                    {modalTranslations.MATCHING_CRITERIA}
                  </Link>
                  {modalTranslations.DESCRIPTION_1_PART_2}
                </span>
                <div className={'my-md'}>{modalTranslations.DESCRIPTION_2}</div>
              </>
            )}
            <TestClusterSteps
              checkConnectivityMutationData={checkConnectivityData?.data}
              checkConnectivityMutationError={connectivityCheckError}
              checkConnectivityMutationIsError={connectivityCheckIsError}
              checkConnectivityMutationIsPending={connectivityCheckPending}
              checkConnectivityMutationIsSuccess={connectivityCheckIsSuccess}
            />
          </div>
        }
        actions={{
          cancel: {
            text: modalTranslations.CLOSE,
            props: {
              onClick: () => {
                setOpen(false);
                reset();
              },
            },
          },
          main: {
            text: hasResponse ? modalTranslations.TEST_AGAIN : modalTranslations.TEST,
            props: {
              loading: connectivityCheckPending,
              type: 'submit',
              onClick: () => {
                if (environment?.type && !hasResponse) {
                  checkConnectivity({ app_id: appId, env_id: envId, env_type: environment?.type });
                } else if (hasResponse) {
                  reset();
                }
              },
            },
          },
        }}
      />
    </>
  );
};
