import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import type { QueryResponse } from '@src/hooks/react-query/types';
import { MatchParams } from '@src/models/routing';
import { Invitation } from '@src/models/user';
import makeRequest from '@src/utilities/make-request';

import { rolesQueryKeys } from '../rolesQueryKeys';

const useGetInvitations = (): QueryResponse<Invitation[]> => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery({
    queryKey: rolesQueryKeys.listInvitations(orgId),
    queryFn: () => makeRequest<Invitation[]>('GET', `/orgs/${orgId}/invitations`),
  });

  return { ...queryResult, data: data?.data };
};

export default useGetInvitations;
