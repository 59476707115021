import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { QueryResponse } from '@src/hooks/react-query/types';
import { ResourceClass } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { resourcesQueryKeys } from '../resourcesQueryKeys';

const useResourceClassesQuery = (): QueryResponse<ResourceClass[]> => {
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery({
    queryKey: resourcesQueryKeys.listClasses(orgId),
    queryFn: () => makeRequest<ResourceClass[]>('GET', `/orgs/${orgId}/resources/classes`),
  });

  return { ...queryResult, data: data?.data.sort((a, b) => a.id.localeCompare(b.id)) };
};

export default useResourceClassesQuery;
