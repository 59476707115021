import { useMutation, useQueryClient } from '@tanstack/react-query';
import { omit } from 'lodash';
import { useParams } from 'react-router';

import { KeyValue } from '@src/models/general';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import envValuesQueryKeys from '../envValuesQueryKeys';
import { sharedValuesQueryKeys } from '../sharedValuesQueryKeys';

const useEnvValuesUpdateMutation = () => {
  // Router hooks
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ envValue }: { envValue: KeyValue }) =>
      makeRequest(
        'PATCH',
        `/orgs/${orgId}/apps/${appId}/envs/${envId}/values/${envValue.key}`,
        omit(envValue, 'key')
      ),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: envValuesQueryKeys.list(orgId, appId, envId),
      });
      queryClient.invalidateQueries({
        queryKey: sharedValuesQueryKeys.envValuesList(orgId, appId, envId),
      });
    },
  });
};

export default useEnvValuesUpdateMutation;
