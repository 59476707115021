import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router';

import { ApiErrorResponse } from '@src/models/ApiErrorResponse';
import { ResourceClass } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { resourcesQueryKeys } from '../resourcesQueryKeys';

interface ResourceDefinitionCreateMutationVariables {
  resourceTypeId: string;
  payload: {
    id: string;
    description: string;
  };
}

const useResourceClassCreateMutation = () => {
  const queryClient = useQueryClient();
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useMutation<
    AxiosResponse<ResourceClass>,
    AxiosError<ApiErrorResponse>,
    ResourceDefinitionCreateMutationVariables
  >({
    mutationFn: ({ resourceTypeId, payload }) =>
      makeRequest('POST', `/orgs/${orgId}/resources/types/${resourceTypeId}/classes`, payload),
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries({ queryKey: resourcesQueryKeys.listClasses(orgId) });
    },
  });

  return { ...queryResult, data: data?.data };
};

export default useResourceClassCreateMutation;
