import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router';

import { Role } from '@src/models/role';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { rolesQueryKeys } from '../rolesQueryKeys';

const useSendInvitations = (successCallback?: (res?: Role) => void) => {
  const queryClient = useQueryClient();
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useMutation<
    AxiosResponse<Role>,
    AxiosError,
    Pick<Role, 'email' | 'role'>
  >({
    mutationFn: (payload) => makeRequest('POST', `/orgs/${orgId}/invitations`, payload),
    onSuccess: (res) => {
      // Invalidate and refetch
      queryClient.invalidateQueries({
        queryKey: rolesQueryKeys.listInvitations(orgId),
      });

      queryClient.invalidateQueries({
        queryKey: rolesQueryKeys.orgRoles(orgId),
      });
      if (successCallback) {
        successCallback(res.data);
      }
    },
  });
  return { ...queryResult, data: data?.data };
};

export default useSendInvitations;
