import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { useDecision } from '@src/hooks/useDecision';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import useEnvironmentQuery from '../../environments/queries/useEnvironmentQuery';
import { userQueryKeys } from '../userQueryKeys';
import useGetCurrentUserQuery from './useGetCurrentUserQuery';

export type OrgPermissionKeys =
  | 'createApplication'
  | 'readArtefactVersions'
  | 'contributeImages'
  | 'deleteImages'
  | 'manageEnvironmentTypes'
  | 'manageUsers'
  | 'manageApplication'
  | 'read'
  | 'manageResources'
  | 'readImages'
  | 'manageEverything';

export type AppPermissionKeys = 'manageApplication' | 'updateApplication' | 'read';
export type EnvTypePermissionKeys = 'deployer';

export type PermissionKeys = OrgPermissionKeys | AppPermissionKeys | EnvTypePermissionKeys;

export const useUserPermissionsQuery = (userIdParam?: string, orgIdParam?: string) => {
  const { orgId: routerOrgId } = useParams<keyof MatchParams>() as MatchParams;
  const orgId = orgIdParam || routerOrgId;

  const { data: currentUser } = useGetCurrentUserQuery();
  const userId = userIdParam || currentUser?.id;

  return useQuery({
    queryKey: userQueryKeys.userPermissions(orgId, userId),
    queryFn: () =>
      makeRequest<{ objects: Record<string, { permissions: PermissionKeys[] }> }>(
        'GET',
        `/orgs/${orgId}/users/${userId}/perms`
      ),
    select: (data) => data.data,
    enabled: Boolean(orgId && userId),
  });
};

export const useUserOrgPermissionsQuery = (orgIdParam?: string) => {
  const { orgId: routerOrgId } = useParams<keyof MatchParams>() as MatchParams;

  const orgId = orgIdParam || routerOrgId;

  const { data: userPermissions, isSuccess: isUserPermissionsLoaded } = useUserPermissionsQuery(
    undefined,
    orgId
  );

  return {
    isUserPermissionsLoaded,
    userOrgPermissions: userPermissions?.objects?.[`/orgs/${orgId}`]
      ?.permissions as OrgPermissionKeys[],
  };
};

export const useUserAppPermissionsQuery = () => {
  const { orgId, appId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const { data: userPermissions } = useUserPermissionsQuery();

  return userPermissions?.objects[`/orgs/${orgId}/apps/${appId}`]
    ?.permissions as AppPermissionKeys[];
};

export const useCheckIsDeployerQuery = (customParams?: {
  orgId?: string;
  appId?: string;
  envId?: string;
}) => {
  // Router hooks
  const { orgId: routerOrgId, appId: routerAppId } = useParams<keyof MatchParams>() as MatchParams;
  const appId = customParams?.appId || routerAppId;
  const orgId = customParams?.orgId || routerOrgId;

  // Optimizely
  const [userPermissionsDecision] = useDecision('user-permissions');

  const { data: environment } = useEnvironmentQuery({ orgId, appId, envId: customParams?.envId });

  // React Query
  const { data: userPermissions } = useUserPermissionsQuery();
  const { data: user } = useGetCurrentUserQuery();

  const envTypeRolesPermissions = environment
    ? userPermissions?.objects[`/orgs/${orgId}/env-types/${environment.type}`]?.permissions
    : [];

  const envTypeRoleCurrentUser = environment
    ? user?.roles?.[`/orgs/${orgId}/env-types/${environment.type}`]
    : undefined;

  return Boolean(
    userPermissionsDecision.enabled
      ? envTypeRolesPermissions?.includes('deployer')
      : envTypeRoleCurrentUser === 'deployer'
  );
};
