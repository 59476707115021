import { EditorProps } from '@monaco-editor/react';
import { JSONSchema7 } from 'json-schema';
import { editor } from 'monaco-editor';
import { rem } from 'polished';
import React, { KeyboardEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { units } from '@src/styles/variables';
import { isNumeric } from '@src/utilities/string-utility';

import BasicEditor from '../Editor/BasicEditor';
import { CustomLanguages } from '../Editor/EditorProps';
import { InputWrapper, renderDeleteIcon, renderSecretIcon } from '../Input/InputWrapper';
import { TextareaProps } from '../Textarea/Textarea';

export interface AutoCompleteOptions {
  /** object for auto complete lookup */
  lookupObject: any;
  customLanguage?: CustomLanguages;
  /** delimits the area where the auto complete happens */
  delimiterString?: {
    start: string;
    end: string;
  };
  /** max height value for the dropdown menu */
  dropdownMenuMaxHeight?: number;
}

const HiddenInput = styled.input`
  display: none;
`;

export interface MonacoInputProps extends TextareaProps {
  /** extension for monaco editor that will be used for syntax highlighting */
  monacoEditorFileExtension?: string;
  /** optional schema for yaml validation in monaco editor */
  yamlSchema?: { uri: string; schema: JSONSchema7 };
  /** optional props to display the monaco editor specific context menu */
  monacoOptions?: EditorProps['options'];
  /** pass this if we need to show placeholders autocomplete. */
  autoCompleteOptions?: AutoCompleteOptions;
  monacoEditorOnValidate?: (errs: editor.IMarker[]) => void;
}
export const MonacoInput = (props: MonacoInputProps) => {
  const {
    monacoOptions,
    autoCompleteOptions,
    monacoEditorFileExtension,
    tabIndex,
    monacoEditorOnValidate,
    rows,
    onFocus,
    onChange,
    onKeyDown,
    size,
    inputRef,
    onBlur,
    name,
    type,
    onKeyUp,
    readonly,
    secret,
    yamlSchema,
    focusOnInputState,
    deleteAction,
  } = props;
  // form
  const { watch, setValue } = useFormContext();

  const minHeight = rows ? rows * 30 : 30;
  const isMultiLineInput = Boolean(rows);
  const [focusOnInput] = focusOnInputState || [];
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  return (
    <InputWrapper {...props}>
      {(formRegister) => (
        <>
          <BasicEditor
            monacoOptions={monacoOptions}
            customLanguage={
              autoCompleteOptions ? autoCompleteOptions.customLanguage || 'variables' : undefined
            }
            placeholders={
              autoCompleteOptions ? JSON.stringify([autoCompleteOptions.lookupObject]) : undefined
            }
            fileExtension={monacoEditorFileExtension}
            tabIndex={tabIndex}
            height={rem(minHeight)}
            inputSize={size}
            inputRef={inputRef}
            value={watch(name) ? watch(name).toString() : ''}
            onChange={(val) => {
              setValue(name, val && type === 'number' && isNumeric(val) ? Number(val) : val, {
                shouldDirty: true,
              });
              if (onChange && val) {
                onChange(val);
              }
            }}
            data-testid={`${name}-input-element`}
            onFocus={(value: string | null) => (onFocus ? onFocus(value) : null)}
            onBlur={(value: string | null) => onBlur && onBlur(value ?? '')}
            onKeyDown={(event: KeyboardEvent) => {
              if (onKeyDown) {
                onKeyDown(event);
              }
            }}
            isMultiLineInput={isMultiLineInput}
            onKeyUp={(event: KeyboardEvent) => onKeyUp && onKeyUp(event)}
            fontSize={size === 'large' ? units.fontSize.base : units.fontSize.sm}
            readOnly={readonly}
            autofocus={focusOnInput}
            onValidate={monacoEditorOnValidate}
            yamlSchema={yamlSchema}
          />
          {secret && renderSecretIcon(uiTranslations.SECRETS_INFO_TEXT)}
          {deleteAction && renderDeleteIcon(deleteAction)}
          <HiddenInput
            {...formRegister}
            defaultValue={watch(name)}
            readOnly={readonly}
            id={name}
            data-testid={`${name}-input-element`}
          />
        </>
      )}
    </InputWrapper>
  );
};

export default MonacoInput;
