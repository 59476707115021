import {
  Button,
  ButtonProps,
  EmptyStateCard,
  Icon,
  Text,
  WalLabel,
  WalTable,
  WalTableColumn,
  WalTableRow,
} from '@humanitec/ui-components';
import { useQueryClient } from '@tanstack/react-query';
import { MouseEvent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router';
import styled from 'styled-components';

import ConfirmDeleteMatchingCriteriaModal from '@src/components/shared/MatchingCriteria/ConfirmDeleteMatchingCriteriaModal/ConfirmDeleteMatchingCriteriaModal';
import useResourceDefinitionDeleteMutation from '@src/hooks/react-query/resources/mutations/useResourceDefinitionDeleteMutation';
import { activeResourcesQueryOptions } from '@src/hooks/react-query/resources/queries/useActiveResourcesQuery';
import { ResourceConflictChange, ResourceDefinition } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import { generateAppURL, generateResourcesUrl } from '@src/utilities/navigation';

const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${units.margin.md};
`;

const AppNameWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

interface DeleteResourceDefinitionButtonProps extends ButtonProps {
  resourceDefinition: ResourceDefinition;
  buttonText?: string;
}
const DeleteResourceDefinitionModalWithButton = (props: DeleteResourceDefinitionButtonProps) => {
  const { resourceDefinition, buttonText } = props;
  // i18n
  const { t } = useTranslation();
  const conflictModalTranslations =
    t('ACCOUNT_SETTINGS').RESOURCES.CONFIRM_DELETE_RES_DEF_MATCHING_CRITERIA;
  const translations = t('RESOURCE_MATCHING');

  // React query
  const {
    mutate: deleteResourceDefinition,
    error: deleteError,
    isSuccess: isDeletedSuccessfully,
    reset: resetDeleteMutation,
  } = useResourceDefinitionDeleteMutation();

  const queryClient = useQueryClient();

  // Component state
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  // Router hooks
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;
  const navigate = useNavigate();

  useEffect(() => {
    if (isDeletedSuccessfully) {
      setConfirmDeleteModalOpen(false);
      resetDeleteMutation();
      navigate(generateResourcesUrl(orgId));
    }
  }, [isDeletedSuccessfully, resetDeleteMutation, navigate, orgId]);

  const deleteConflicts = deleteError?.response?.status === 409 ? deleteError?.response.data : [];

  const tableCols: WalTableColumn<ResourceConflictChange>[] = [
    {
      label: conflictModalTranslations.ID,
      prop: 'id',
      ellipsisTooltip: {
        maxWidth: 200,
        maxCharacters: 20,
        text: (row) => row.data.res_id,
      },
      template: (row) => <span>{row.data.res_id || '-'}</span>,
    },
    {
      label: conflictModalTranslations.REPLACEMENT_DEFINITION,
      justifyContent: 'flex-start',
      prop: 'replacement_definition',
      ellipsisTooltip: {
        maxWidth: 200,
        maxCharacters: 20,
        text: (row) => row.data.to_def,
      },
      template: (row) => <span>{row.data.to_def || '-'}</span>,
    },
  ];

  const handleClickOnDeleteButton = async (e: MouseEvent) => {
    e.stopPropagation();
    // check if the resource definition has active resources
    const activeResources = (
      await queryClient.fetchQuery(activeResourcesQueryOptions(orgId, resourceDefinition.id))
    ).data;

    if (activeResources && activeResources?.length > 0) {
      // delete the resource definition which will open the conflict modal with the list of the associated resources
      deleteResourceDefinition({ defId: resourceDefinition.id });
    }
    setConfirmDeleteModalOpen(true);
  };

  const handleDeleteResourceDef = (force?: boolean) => {
    deleteResourceDefinition({ defId: resourceDefinition.id, force });
  };

  const objectName = 'resource definition';

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <div className={'flex-row flex-centered'}>
        {!resourceDefinition?.is_default && (
          <Button
            {...props}
            onClick={handleClickOnDeleteButton}
            ariaLabel={`${translations.DELETE_RESOURCE_DEFINITION} ${resourceDefinition.id}`}>
            {buttonText}
          </Button>
        )}
      </div>
      {confirmDeleteModalOpen && (
        <ConfirmDeleteMatchingCriteriaModal
          openState={[confirmDeleteModalOpen, setConfirmDeleteModalOpen]}
          onDeleteConfirmed={() => handleDeleteResourceDef(true)}
          title={t('ACCOUNT_SETTINGS.RESOURCES.CONFIRM_DELETE_RES_DEF_MATCHING_CRITERIA_TITLE', {
            returnObjects: true,
            replace: {
              name: objectName,
              resId: ` ${resourceDefinition.id}`,
            },
          })}
          inputConfirmation={
            deleteConflicts?.length > 0
              ? { correctValue: resourceDefinition.id, objectName, propertyToType: 'ID' }
              : undefined
          }
          content={
            <>
              <Text className={'mb-md'}>
                <Trans
                  defaults={
                    deleteConflicts && deleteConflicts?.length > 0
                      ? conflictModalTranslations.DELETE_RESOURCE_DEFINITION_OR_MATCHING_CRITERIA_TEXT
                      : conflictModalTranslations.DELETE_RESOURCE_DEFINITION_OR_MATCHING_CRITERIA_TEXT_NO_CONFLICTS
                  }
                  values={{ objectName }}
                />
              </Text>

              {deleteConflicts && deleteConflicts?.length > 0 ? (
                <>
                  <Text color={'textTranslucent'} className={'mb-md'}>
                    {conflictModalTranslations.RESOURCES_AFFECTED}
                  </Text>
                  <WalTable
                    caption={conflictModalTranslations.RESOURCES_AFFECTED}
                    columns={tableCols}
                    tableStyle={'expandable'}
                    tableRowStyle={'base'}
                    rows={(deleteConflicts || [])?.map(
                      (deleteChange): WalTableRow => ({
                        data: deleteChange,
                        expandableContent: (
                          <>
                            <Field>
                              <WalLabel>{conflictModalTranslations.RESOURCE_ID}</WalLabel>
                              <span>{deleteChange.res_id || '_'}</span>
                            </Field>
                            <Field>
                              <WalLabel>{conflictModalTranslations.APPLICATION_ID}</WalLabel>
                              <AppNameWrapper>
                                {deleteChange.app_id || '_'}
                                {deleteChange.app_id && (
                                  <Link
                                    to={generateAppURL(orgId, deleteChange.app_id)}
                                    target={'_blank'}
                                    rel={'noopener noreferrer'}>
                                    <Icon
                                      name={'link'}
                                      overrideColor={'main-brighter'}
                                      marginLeft={'sm'}
                                      pointer
                                    />{' '}
                                  </Link>
                                )}
                              </AppNameWrapper>
                            </Field>
                            <Field>
                              <WalLabel>{conflictModalTranslations.ENVIRONMENT_ID}</WalLabel>
                              <span>{deleteChange.env_id || '_'}</span>
                            </Field>
                            <Field>
                              <WalLabel>
                                {conflictModalTranslations.REPLACEMENT_DEFINITION}
                              </WalLabel>
                              <span>{deleteChange.to_def || '_'}</span>
                            </Field>
                          </>
                        ),
                      })
                    )}
                    disableScrolling
                  />
                </>
              ) : (
                <EmptyStateCard>{conflictModalTranslations.NO_RESOURCES_AFFECTED}</EmptyStateCard>
              )}
            </>
          }
        />
      )}
    </div>
  );
};

export default DeleteResourceDefinitionModalWithButton;
