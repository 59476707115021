import { TabGroup } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router';
import styled from 'styled-components';

import PageHeader from '@src/components/shared/PageHeader/PageHeader';
import { PageContainer } from '@src/styles/layout/PageContainer';

const Container = styled(PageContainer)`
  flex-direction: column;
`;

export const Resources = () => {
  // i18n
  const { t } = useTranslation();
  const translations = t('NAVIGATION');

  return (
    <Container>
      <PageHeader />
      <TabGroup
        options={[
          {
            label: translations.DEFINITIONS,
            link: { to: 'definitions' },
            value: 'definitions',
          },
          {
            label: translations.CLASSES,
            link: { to: 'classes' },
            value: 'classes',
          },
        ]}
      />
      <Outlet />
    </Container>
  );
};
