import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useParams } from 'react-router';

import { AllRoles } from '@src/models/role';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { groupsQueryKeys } from '../groupsQueryKeys';

export const useCreateGroupMutation = () => {
  // Router hooks
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const queryClient = useQueryClient();

  const { data, ...queryResult } = useMutation<
    AxiosResponse,
    AxiosError,
    { group_id: string; idp_id: string; role: AllRoles }
  >({
    mutationFn: (variables) => makeRequest('POST', `/orgs/${orgId}/groups`, variables),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: groupsQueryKeys.all(orgId),
      });
    },
  });

  return { ...queryResult, data: data?.data };
};
