import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import type { QueryResponse } from '@src/hooks/react-query/types';
import { Environment } from '@src/models/environment';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { environmentQueryKeys } from '../environmentQueryKeys';

const useEnvironmentQuery = (
  // Allow passing of custom URL params. Can be removed if WAL-5572 is completed.
  customParams?: {
    orgId?: string;
    appId?: string;
    envId?: string;
  }
): QueryResponse<Environment> => {
  const {
    orgId: routerOrgId,
    appId: routerAppId,
    envId: routerEnvId,
  } = useParams<keyof MatchParams>() as MatchParams;

  const orgId = customParams?.orgId || routerOrgId;
  const appId = customParams?.appId || routerAppId;
  const envId = customParams?.envId || routerEnvId;

  const { data, ...queryResult } = useQuery({
    queryKey: environmentQueryKeys.environmentDetail(orgId, appId, envId),
    queryFn: () => makeRequest<Environment>('GET', `/orgs/${orgId}/apps/${appId}/envs/${envId}`),
    enabled: Boolean(orgId && appId && envId),
  });

  return { ...queryResult, data: data?.data };
};

export default useEnvironmentQuery;
