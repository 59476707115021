import { Link } from 'react-router';

import { useRBAC } from '@src/hooks/useRBAC';
import { ActiveResource } from '@src/models/resources';
import { generateResourceDefinitionUrl } from '@src/utilities/navigation';

interface ResourceDefinitionLinkProps {
  activeResource: ActiveResource;
  isResourceDefinitionDeleted: boolean;
}
const ResourceDefinitionLink = ({
  activeResource,
  isResourceDefinitionDeleted,
}: ResourceDefinitionLinkProps) => {
  const canViewResources = useRBAC('viewResources');

  return (
    <>
      {canViewResources && !isResourceDefinitionDeleted && (
        <Link
          to={generateResourceDefinitionUrl(activeResource.org_id, activeResource.def_id)}
          target={'_blank'}
          rel={'noopener noreferrer'}>
          {activeResource.def_id}
        </Link>
      )}
    </>
  );
};

export default ResourceDefinitionLink;
