import { Navigate, useParams } from 'react-router';

import useUserDetailQuery from '@src/hooks/react-query/user/queries/useUserDetailQuery';
import { MatchParams } from '@src/models/routing';
import { generateOrgMembersURL } from '@src/utilities/navigation';

import OrgMembersList from '../../OrgMembersList/OrgMembersList';

export const GroupUsers = () => {
  // Router hooks
  const { orgId, userId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const { data: userDetail, isSuccess: isUserDetailLoaded } = useUserDetailQuery();

  if (isUserDetailLoaded && userDetail?.type !== 'group')
    return <Navigate to={generateOrgMembersURL(orgId, 'users', userId, 'applications')} />;

  return <OrgMembersList mode={'groups'} />;
};
