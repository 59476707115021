import { useMutation, useQueryClient } from '@tanstack/react-query';
import { omit } from 'lodash';
import { useParams } from 'react-router';

import { MatchParams } from '@src/models/routing';
import { Rule } from '@src/models/rule';
import makeRequest from '@src/utilities/make-request';

import { rulesQueryKeys } from '../rulesQueryKeys';

const useEnvironmentRuleCreateMutation = () => {
  // Router hooks
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ newRule }: { newRule: Partial<Rule> }) => {
      return makeRequest<Rule[]>(
        'POST',
        `/orgs/${orgId}/apps/${appId}/envs/${envId}/rules`,
        omit(newRule, ['id', 'created_at', 'updated_at'])
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: rulesQueryKeys.list(orgId, appId, envId),
      });
    },
  });
};

export default useEnvironmentRuleCreateMutation;
