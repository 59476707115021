import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { ActiveResource } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { QueryResponse } from '../../types';
import { environmentQueryKeys } from '../environmentQueryKeys';

const useEnvironmentResourcesQuery = (): QueryResponse<ActiveResource[]> => {
  const { orgId, appId, envId } = useParams<keyof MatchParams>() as MatchParams;

  const { data, ...queryResult } = useQuery({
    queryKey: environmentQueryKeys.listEnvironmentResources(orgId, appId, envId),
    queryFn: () =>
      makeRequest<ActiveResource[]>('GET', `/orgs/${orgId}/apps/${appId}/envs/${envId}/resources`),
    enabled: Boolean(orgId && appId && envId),
  });

  return { ...queryResult, data: data?.data };
};

export default useEnvironmentResourcesQuery;
