import { useMatch } from 'react-router';

export interface MatchParams {
  orgId: string;
  appId: string;
  envId: string;
  deployId: string;
  pipelineId: string;
  pipelineRunId: string;
  pipelineVersionId: string;
  runId: string;
  deltaId: string;
  moduleId: string;
  containerId: string;
  confirmationEmailHash: string;
  defId: string;
  defVersionId: string;
  leftDeploymentId: string;
  rightDeploymentId: string;
  userId: string;
  graphId?: string;
}

export interface LocationState {
  previousPage?: string;
}

export const useDeployOrDeltaSegment = () => {
  const match = useMatch(
    '/orgs/:orgId/apps/:appId/envs/:envId/:deployOrDeltaSegment/:deployOrDeltaId/*'
  );
  return {
    deployOrDeltaSegment: match?.params.deployOrDeltaSegment,
  };
};
