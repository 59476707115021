import { Button, Icon } from '@humanitec/ui-components';
import React, { ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useParams } from 'react-router';
import styled from 'styled-components';

import DiscardDraftModal from '@src/components/shared/DiscardDraftModal';
import { NonStyledLink } from '@src/components/shared/NonStyledLink';
import DeploymentOrDeltaContextWrapper from '@src/context/DeploymentOrDeltaContextWrapper';
import SocketWrapper from '@src/context/SocketWrapper';
import useDeploymentDeltaQuery from '@src/hooks/react-query/deployment-delta/queries/useDeploymentDeltaQuery';
import { useDecision } from '@src/hooks/useDecision';
import { MatchParams } from '@src/models/routing';
import { PageContainer } from '@src/styles/layout/PageContainer';
import { NEW_APP_HEADER_HEIGHT, units } from '@src/styles/variables';
import { getChangeCountMessage } from '@src/utilities/deployment-delta-utils';
import { generateDraftURL } from '@src/utilities/navigation';

import DeployButton from './DeployButton';

const BannerWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.mainTransparentSolid};
  padding: ${units.padding.xl} 0;
  position: sticky;
  top: ${NEW_APP_HEADER_HEIGHT};
  z-index: 9;
`;

const BannerContainer = styled(PageContainer)<{ displayDraftOverviewLink: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`;

const ChangesSection = styled.div`
  display: flex;
  align-items: center;
  column-gap: ${units.padding.xl};
  font-size: ${units.fontSize.base};
`;

const DraftOverviewLink = styled(NonStyledLink)`
  display: flex;
  align-items: center;
`;

const DraftWrapper = ({ children }: { children: ReactNode }) => {
  // Router hooks
  const { orgId, appId, envId, deltaId } = useParams<keyof MatchParams>() as MatchParams;
  const workloadMatch = useMatch(
    '/orgs/:orgId/apps/:appId/envs/:envId/:deployOrDeltaSegment/:deployOrDeltaId/workloads/:moduleId/*'
  );
  // TODO: Check for workloadId route param once this is implemented - WAL-5572
  const isOnWorkloadPageOrDeeper = Boolean(workloadMatch);

  // i18n
  const { t } = useTranslation();
  const { t: tViewEnvironment } = useTranslation('viewEnvironment');

  const uiTranslations = t('UI');
  const draftTranslations = tViewEnvironment('DRAFTS');

  // state
  const [discardChangesModalOpen, setDiscardChangesModalOpen] = useState(false);

  // React Query
  const { data: delta } = useDeploymentDeltaQuery();

  // Optimizely
  const [discardDraftDecision] = useDecision('discard_draft');

  const changeCountMessage = useMemo(() => getChangeCountMessage(delta), [delta]);

  return (
    <SocketWrapper>
      <DeploymentOrDeltaContextWrapper>
        <BannerWrapper>
          <BannerContainer displayDraftOverviewLink={isOnWorkloadPageOrDeeper}>
            {isOnWorkloadPageOrDeeper ? (
              <DraftOverviewLink
                className={'txt-md'}
                to={generateDraftURL(orgId, appId, envId, deltaId, 'workloads')}>
                <Icon name={'arrow-left'} marginRight={'md'} size={12} />
                {uiTranslations.DRAFT_OVERVIEW}
              </DraftOverviewLink>
            ) : (
              <span className={'txt-translucent'}>{uiTranslations.DRAFT_DELTA}</span>
            )}
            <ChangesSection>
              <span>{changeCountMessage}</span>
              <DeployButton />
              {discardDraftDecision.enabled && (
                <Button variant={'secondary'} onClick={() => setDiscardChangesModalOpen(true)}>
                  {draftTranslations.DISCARD_DRAFT}
                </Button>
              )}
            </ChangesSection>
          </BannerContainer>
        </BannerWrapper>
        {children}
      </DeploymentOrDeltaContextWrapper>
      {discardChangesModalOpen && delta && (
        <DiscardDraftModal
          openState={[discardChangesModalOpen, setDiscardChangesModalOpen]}
          delta={delta}
          navigateToEnvOverviewOnDelete
        />
      )}
    </SocketWrapper>
  );
};

export default DraftWrapper;
