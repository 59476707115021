import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { PipelineCriteria } from '@src/models/pipeline';
import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { pipelinesQueryKeys } from '../pipelinesQueryKeys';

interface PipelineCriteriaFilters {
  env_id?: string;
  env_type?: string;
  deployment_type?: 'deploy' | 'redeploy';
  pipeline_id?: string;
}

export const buildQueryString = (filters: PipelineCriteriaFilters) => {
  let queryString = '';
  const { pipeline_id, ...otherFilters } = filters;
  if (pipeline_id) {
    queryString += `pipeline=${pipeline_id}`;
    if (Object.keys(otherFilters).length > 0) {
      queryString += `&`;
    }
  }
  if (Object.keys(otherFilters).length > 0) {
    queryString += `match=${JSON.stringify(otherFilters)}`;
  }
  return queryString;
};

const usePipelineCriteriaQuery = (filters: PipelineCriteriaFilters) => {
  const { orgId, appId } = useParams<keyof MatchParams>() as MatchParams;
  const { data, ...queryResult } = useQuery({
    queryKey: pipelinesQueryKeys.criteria(orgId, appId, filters),
    queryFn: () => {
      return makeRequest<PipelineCriteria[]>(
        'GET',
        `/orgs/${orgId}/apps/${appId}/pipeline-criteria?${buildQueryString(filters)}`
      );
    },
    // Don't make request if any of the params don't exist
    enabled: Boolean(orgId && appId),
  });

  return { ...queryResult, data: data?.data };
};

export default usePipelineCriteriaQuery;
